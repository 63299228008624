.configInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pageUrlInputs {
  display: flex;
  align-items: center;
  button {
    margin-left: 1rem;
  }
}
.warningText {
  border-radius: 4px;
  border: 1px solid #ffe6b7;
  background: #fff5e2;
  color: #bd7d02;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
  padding: 8px;
  display: flex;
  align-items: center;

  .warningIcon {
    padding-right: 8px;
  }
}

.switchInputTitle {
  font-style: normal;
  font-weight: 400 !important;
  padding-bottom: 0;
}
.topSpacing {
  margin-top: 1.5em;
}
