@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.edge-breadcrumbs {
  display: flex;
  align-items: center;
  font-weight: $ae-font-weight-bold;
  font-size: $ae-font-size-medium;
  margin-right: $box-sizing-xl;
}
