@import "@aptedge/lib-ui/src/styles/_mixins";

.timings {
  display: flex;
  align-items: center;
  font-style: normal;
  font-size: toRem(13px);
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #88979b;
  font-family: "Inter";
  margin-top: 0.5rem;
  .updated {
    margin-left: toRem(8px);
    padding-left: toRem(8px);
    border-left: 1px solid #88979b;
  }
}
