@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_boxes";

.auto-breadcrumbs {
  margin-bottom: $box-sizing-xl;
  font-size: $ae-font-size-sm;

  .chevron-left-icon {
    color: var(--primary);

    &:hover {
      color: var(--primary-hover);
    }
  }

  .chevron-right-icon {
    color: var(--gray-300);
  }

  .breadcrumb-list {
    & > * {
      margin-right: $box-sizing-xl;
    }
  }

  .breadcrumb-item {
    display: inline-block;
    color: var(--gray-300);
    font-weight: $ae-font-weight-semi-bold;

    &.active {
      color: var(--gray-900);
      font-weight: $ae-font-weight-bold;
    }
  }

  .description {
    margin-top: $box-sizing-medium;
    margin-left: $box-sizing-xxxl;
  }
}
