@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  width: 100%;
  overflow: scroll;
  padding-bottom: 2rem;
}

.headerContainer {
  display: flex;
  align-items: center;
}

.headerLabel {
  color: $teal;
  font-family: $ae-font-family-inter;
  font-weight: $ae-font-weight-semi-bold;
  font-size: 1rem;
  line-height: toRem(24px);
}

.inputContainer {
  margin-top: 1rem;
}

.inputLabel {
  color: $gray-rd-700;
  font-family: $ae-font-family-inter;
  font-size: 1rem;
  font-weight: $ae-font-family-inter;
  line-height: toRem(20px);
}

.nameInput {
  display: flex;
  padding: toRem(10px) toRem(14px);
  align-items: center;
  gap: toRem(8px);
  align-self: stretch;
  border-radius: toRem(4px);
  border: 1px solid $gray-rd-300;
  background: $white;
  width: 100%;
  color: $black-1;
  font-family: $ae-font-family-inter;
  font-size: 1rem;
  font-weight: $ae-font-weight-regular;
  line-height: toRem(24px);
}

.nameInput::placeholder {
  color: $gray-3;
  font-family: $ae-font-family-inter;
  font-size: toRem(14px);
  font-weight: $ae-font-weight-regular;
  line-height: toRem(16px);
}

.filtersContainer {
  margin-top: 1rem;
}

.filterDescription {
  color: $gray-rd-700;
  font-family: $ae-font-family-inter;
  font-size: 1rem;
  font-weight: $ae-font-family-inter;
  line-height: toRem(20px);
  margin-bottom: 0.5rem;
}

.actionsContainer {
  margin-top: 1rem;
}

.actionsDescription {
  color: $gray-rd-700;
  font-family: $ae-font-family-inter;
  font-size: 1rem;
  font-weight: $ae-font-weight-regular;
  line-height: toRem(20px);
  margin-bottom: 0.5rem;
}

.actionButtons {
  display: flex;
  gap: 1rem;
}

.saveButton {
  display: flex;
  padding: toRem(8px) toRem(18px);
  justify-content: center;
  align-items: flex-end;
  gap: toRem(8px);
  border-radius: toRem(4px);
  border: 1px solid $teal-rd-700;
  background: $teal;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: $white;
  font-family: $ae-font-family-inter;
  font-size: toRem(16px);
  font-weight: $ae-font-weight-semi-bold;
  line-height: toRem(24px);
}

.saveButton:disabled {
  background: $teal-rd-200;
  border: 1px solid $teal-rd-300;
}

.saveButton:hover {
  filter: brightness(80%);
}

.cancelButton {
  display: flex;
  padding: toRem(8px) toRem(18px);
  justify-content: center;
  align-items: flex-end;
  gap: toRem(8px);
  border-radius: toRem(4px);
  border: 1px solid $gray-rd-200;
  background: $white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: $gray-rd-600;
  font-family: $ae-font-family-inter;
  font-size: toRem(16px);
  font-weight: $ae-font-weight-regular;
  line-height: toRem(24px);
}

.cancelButton:hover {
  filter: brightness(80%);
}
