@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-support-ui/src/styles/mixins.scss";

.auto-complete-suggestion-wrapper {
  width: auto;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1000;
  background-color: white;
  .auto-complete-search {
    box-sizing: border-box;
    background: var(--gray-100);
    border-radius: 0.5rem 0.5rem 0px 0px;
    margin: 0;
    padding: toRem(10px) 0.5rem;
    align-items: center;
    display: flex;

    padding: 0.25rem 0.5rem;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid #eaecf1;
    background: #fff;

    textarea {
      font-family: "Inter";
      font-size: 0.9rem;
    }

    .search-cancel-icon {
      cursor: pointer;
      padding: 0px 4px;
    }

    .search-icon {
      color: var(--gray-400);
    }

    svg {
      height: 1rem;
      fill: $slate-50;
      &:hover {
        fill: $slate-70;
      }
    }

    .MuiInputAdornment-root {
      margin-left: 0;
    }

    .close-icon {
      position: relative;
      bottom: 2px;
      padding-left: 0.5rem;
      padding-right: 0;

      path {
        stroke: $slate-50;
        stroke-width: 1.2;
        transition: stroke 200ms;
      }
      &:hover path {
        stroke: $slate-70;
      }
    }
    .openInWeb-icon {
      cursor: pointer;
      padding: 0px toRem(10px);
      padding-right: 0.5rem;

      path {
        stroke-width: 0;
        fill: $slate-50;
        transition: fill 200ms;
      }
      &:hover {
        path {
          fill: $slate-70;
        }
      }
    }
  }

  .auto-complete-search:hover,
  .auto-complete-search:focus {
    .search-icon {
      color: var(--teal);
    }
  }

  .auto-complete-search.Mui-focused {
    .search-icon {
      color: var(--teal);
    }
  }

  .suggest-search-results {
    z-index: 9;
    position: absolute;
    background: white;
    border: 1px solid teal;
    border-radius: 0px 0px 0.5rem 0.5rem;
    width: calc(100%);
    :last-child {
      border-radius: 0px 0px 0.5rem 0.5rem;
    }
  }

  .search-bottom-border {
    border-radius: 0.5rem;
  }

  .auto-complete-border {
    outline: 1px solid var(--teal);
  }
  .without-suggestion {
    border-radius: 0.25rem;
  }
}
