@import "@aptedge/lib-ui/src/styles/_colors";

.container {
  width: 50rem;
  word-wrap: break-word;
  .title {
    margin-bottom: 1rem;
    color: $viking-90;
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
  }
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .contents {
    padding: 1rem;
    border-radius: 1rem;
    background: $viking-00;
    color: $slate-80;
    position: relative;
    font-size: 1rem;
  }
}
