@import "@aptedge/lib-ui/src/styles/_boxes";

.debounced-text-input {
  .text-search {
    .input-container {
      width: 200px;
      margin-top: 0;
      height: 36px;

      input {
        margin: 0;
        line-height: $box-sizing-input;
      }
    }
  }
}
