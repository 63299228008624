@import "@aptedge/lib-ui/src/styles/_typography";

.ae-modal.bulk-edges {
  .content {
    margin-top: 140px;

    .select.dirty {
      .text-input .input-container {
        border-color: var(--primary);
      }
    }

    .multi-select {
      &.dirty {
        .input-container {
          border-color: var(--primary);
        }
      }

      .input-container {
        background-color: initial;
        border-color: var(--gray-300);

        &:hover {
          border-color: var(--primary-hover);
        }
      }

      input {
        &::placeholder {
          color: inherit;
        }
      }
    }

    .subtitle {
      font-size: $ae-font-size-mini;
      color: var(--gray-400);
    }
  }
}
