@import "../../styles/boxes";
@import "../../styles/typography";
@import "@aptedge/lib-ui/src/styles/_mixins";

.icon {
  padding: $box-sizing-regular;
  font-size: $ae-font-size-medium;
  color: inherit;

  &.link {
    cursor: pointer;
  }
}

.issues-icon {
  width: toRem(18px);
  height: toRem(20px);
}
