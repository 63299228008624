@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_colors";

.search-result-title {
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 18.2px */
  word-wrap: break-word;
  padding-bottom: 0.5rem;
  cursor: pointer;

  .search-result-preview-text {
    color: var(--gray-800);
  }
}

.search-result-preview-text {
  overflow: visible;
  word-wrap: break-word;
  text-overflow: unset;
  line-height: 125%;

  font-family: $ae-font-family-inter;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: var(--gray-700);
}

.preview-highlight {
  font-style: normal;
  background-color: $highlight-color;
  padding: 0rem 0.25rem;
}

.offset-highlight {
  font-style: normal;
  background-color: $highlight-color;
}
