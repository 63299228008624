@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.inputContainer {
  padding-top: 8px;

  .inputWrapper {
    display: flex;
    align-items: center;
    border-radius: $primary-border-radius;
    border: 1px solid var(--gradient-100, #5aedfc);
    background: $slate-00;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);

    .input {
      outline: none;
      border-radius: $primary-border-radius;
      border: none;
      resize: none;
      overflow-y: auto;
      min-height: 3rem;
      max-height: 7.5rem;
      width: 100%;
      padding: 0.75rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;
      font-size: 1rem;
      &::placeholder {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
      }
    }
  }
  .brand {
    float: right;
    color: $slate-50;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    margin-top: 0.25rem;
  }
}
