@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";

.filterMenuContainer {
  max-height: 32rem !important;
}

.toggleContainer {
  display: flex;
  padding: toRem(12px);
  justify-content: center;
  align-items: center;
  gap: toRem(8px);
  border-radius: toRem(4px);
  border: 1px solid #bdbdbd;
}

.toggleContainer:hover {
  background: $gray-rd-100;
  cursor: pointer;
}

.menuContainer {
  min-width: toRem(200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: toRem(8px);
}

.label {
  color: $gray-2;
  text-align: right;
  font-family: $ae-font-family-inter;
  font-size: toRem(16px);
  font-weight: 400;
  line-height: toRem(24px);
  flex-shrink: 0;
}

.count {
  display: flex;
  min-width: 2rem;
  flex-shrink: 0;
  padding: toRem(0px) toRem(8px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: toRem(8px);
  border-radius: toRem(40px);
  background: #f44444;
  color: $white;
  text-align: center;
  font-family: $ae-font-family-inter;
  font-size: toRem(16px);
  font-weight: 400;
  line-height: toRem(24px);
}

.countPlaceholder {
  min-width: 2rem;
  flex-shrink: 0;
}

.menuIcon {
  color: $gray-2;
}

.chevronIcon {
  color: #667085;
}
