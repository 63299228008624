@import "@aptedge/lib-ui/src/styles/_boxes";

.global-result {
  margin: $box-sizing-medium 0;
  cursor: pointer;
  transition: background-color 200ms;

  &.active {
    background-color: var(--gray-100);
  }

  &:hover {
    background-color: var(--gray-100);
  }

  &:focus {
    outline: none;
  }

  .img-icon {
    position: relative;
    bottom: 2px;
    height: 14px;
    margin: 4px;
  }

  .search-result-preview-text {
    color: var(--gray-800);
  }
}

[role="search-result"] {
  // prevent blue outline on first search result when selecting using the keyboard
  outline: none;
}
