@import "@aptedge/lib-ui/src/styles/_colors";

.previewContianer {
  height: 100%;
  display: grid;
  background-color: $viking-10;
  .content {
    overflow-y: scroll;
    position: relative;
  }
  .padding {
    padding: 1rem;
  }
}
.container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: relative;
  background-color: $viking-10;
  overflow-y: hidden;

  .content {
    background-color: $viking-10;
    overflow-y: scroll;
  }
  .padding {
    padding: 1rem;
  }
  .scrollToBottomButton {
    border: none;
    outline: none;
    background-color: #123236;
    color: white;
    border-radius: 50%;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 4px;
    padding: 8px;
    width: 40px;
    height: 40px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}
