.inputContainer {
  .titleContainer {
    display: flex;
    justify-content: space-between;
    .label {
      color: var(--Gray-900, #101828);
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 0;
    }
    .subTitle {
      color: var(--Slate-Slate-70, #5c6e89);
      text-align: right;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .desc {
    color: #475467;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0.3rem;
    padding: 0.5rem 0;
  }
}
