$tenantIdColor: #718286;
.social-section {
  display: grid;
  grid-template-columns: 43% 57%;
  .right {
    .divider {
      margin-top: 2rem;
      visibility: hidden;
    }
  }
  .tenantId {
    color: $tenantIdColor;
  }
}
