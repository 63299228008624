@import "@aptedge/lib-ui/src/styles/_boxes";

.auto-linker {
  height: inherit;
  position: relative;

  .dot {
    position: absolute;
    border: 2px solid var(--gray-000);
    right: -5px;
    top: -5px;
    cursor: pointer;
  }

  .ae-button {
    height: inherit;
    border-radius: $border-radius-rounded;
  }
}
