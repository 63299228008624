@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.previewContainer {
  border-radius: $primary-border-radius;
  background: #123236;
  padding: 0.25rem;
}

.previewContent {
  border-radius: 0.75rem;
  background: var(--Slate-Slate-00, #fff);
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  height: 70%;
}

.homeContainer {
  padding: 1rem;

  .content {
    display: grid;
    gap: 0.5rem 0;
    background-color: $viking-10;
  }
}
.previewBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  width: 100%;
  background-color: white;
  border: none;
  outline: none;
  :global(.ae-icon) {
    background: linear-gradient(180deg, #6ddeeb 0%, #076069 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .left {
    display: flex;
    align-items: center;
    .text {
      margin-left: 0.25rem;
    }
  }
  &:focus {
    outline: none;
  }
}
