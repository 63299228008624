@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.edge-bulk-reply-modal {
  .tabs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tab {
      margin-bottom: 12px;

      &first-of-type {
        margin-right: $box-sizing-large;
      }
    }
  }

  .confirmation-summary {
    color: var(--gray-500);
    padding: 12px 10px 4px;
    margin-bottom: $box-sizing-xxl;

    .icon {
      padding: $box-sizing-medium;
      margin-left: -$box-sizing-medium;
      margin-right: $box-sizing-medium;

      &:hover {
        color: var(--gray-700);
        background-color: var(--gray-100);
      }
    }

    .bold {
      color: var(--gray-900);
      font-weight: $ae-font-weight-bold;
    }
  }

  .reply-summary {
    margin-bottom: 32px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    > .ae-button {
      margin-left: $box-sizing-large;
    }
  }

  .recent {
    margin-top: $box-sizing-large;

    h5 {
      color: var(--gray-500);
      margin-bottom: $box-sizing-large;
      font-weight: $ae-font-weight-bold;
      font-size: $ae-font-size-reg;
    }

    .edge-bulk-reply {
      margin-bottom: $box-sizing-xl;
    }
  }
}
