@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_mixins";

.login-content {
  margin: 0 auto;
  width: toRem(500px);
  max-width: 100%;
  padding: $box-sizing-xxxl;
  margin-top: 5rem;
  .header {
    display: grid;
    align-items: center;
    justify-items: center;
    margin-bottom: $box-sizing-large;

    h2 {
      margin: toRem(12px) toRem(10px);
      font-size: toRem(28px);
      font-weight: 600;
      line-height: toRem(32px);
      font-family: "Inter";
    }

    .logo {
      width: auto;
      height: toRem(28px);
      margin: toRem(12px) toRem(10px);
      margin-left: 0;
    }
  }

  button {
    width: 100%;
    padding: toRem(8px) toRem(10px);
    span {
      padding: 0.1rem 0;
      font-size: toRem(14px);
    }
  }

  .input-container {
    transition: border 200ms;
  }
}
