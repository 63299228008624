@import "@aptedge/lib-ui/src/styles/_mixins";

.emptyStateContainer {
  display: relative;
}

.container {
  position: relative;
}

.formContainerOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.formContainer {
  display: flex;
  width: 50rem;
  padding: toRem(24px) toRem(16px) toRem(0px) toRem(16px);
  flex-direction: column;
  align-items: flex-start;
  gap: toRem(16px);
  flex-shrink: 0;
  background: #fff;
  box-shadow: -8px 0px 28px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 1000;
}
