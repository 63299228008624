@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.edge-search-result {
  display: flex;
  color: var(--gray-600);
  user-select: none;
  padding: $box-sizing-large;
  margin: $box-sizing-xl 0;
  border-radius: $border-radius-rounded;
  border: 1px solid $border-color;

  .actions {
    display: none;
  }
  .action-icons {
    margin-left: auto;
    margin-right: 2rem;
  }
  &.selectable {
    cursor: pointer;

    .actions {
      display: block;
      color: var(--gray-500);
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      display: flex;
      font-family: $ae-font-family-inter;
      font-style: normal;
      font-weight: 700;
      font-size: toRem(14px);
      line-height: toRem(17px);
      color: $title-color;
      @include textEllipsis(1);
    }
    .action-icons {
      margin-left: toRem(10px);
      margin-right: 0;
    }
    .ticket-details {
      margin-left: auto;
      white-space: nowrap;
    }
    .archived-label {
      display: inline;
      color: var(--danger);
      background-color: var(--danger-faded);
      font-size: $ae-font-size-sm;
      font-weight: $ae-font-weight-semi-bold;
      margin-left: $box-sizing-large;
      padding: $box-sizing-regular $box-sizing-medium;
      border-radius: $border-radius-rounded;
    }

    .preview-highlight {
      font-style: normal;
      font-weight: $ae-font-weight-bold;
      background-color: $highlight-color;
      padding: 0rem 0.25rem;
    }

    .offset-highlight {
      font-style: normal;
      background-color: $highlight-color;
    }
  }

  .toggle {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $box-sizing-medium;
    padding-right: $box-sizing-large;
    padding-left: 0;
  }

  .description {
    margin-top: $box-sizing-medium;
    color: var(--gray-600);
    font-size: $ae-font-size-sm;

    overflow-y: hidden;
    max-height: toRem(80px);
    line-height: toRem(16px);
    word-break: break-word;

    .markdown-content {
      padding: 0;
      overflow: hidden;
      line-height: toRem(16px);
      word-break: break-word;
      color: var(--dark, #353d3f);
      text-overflow: ellipsis;
      white-space: inherit;
      font-family: $ae-font-family-inter;
      font-size: $ae-font-size-sm;
      font-style: normal;
      font-weight: $ae-font-weight-regular;
      line-height: 140%; /* 19.6px */
    }
    img {
      display: none;
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: toRem(13px);
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #88979b;
    font-family: $ae-font-family-inter;
    margin-top: toRem(8px);

    .status {
      .dot {
        width: toRem(8px);
        height: toRem(8px);
        background-color: #ffd5bb;
      }
      .status-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: toRem(12px);
        line-height: 130%;
        letter-spacing: 0.01em;
        color: #c97441;
        padding-left: toRem(8px);
      }
    }
  }

  &:hover {
    color: var(--gray-600);
    background-color: var(--gray-100);
  }

  &:focus {
    outline: none;
  }
}
