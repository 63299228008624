@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";
@import "@aptedge/lib-support-ui/src/styles/mixins.scss";

.articleTabsContainer {
  display: flex;
  flex-direction: row;
  color: $slate-60;
  font-size: $ae-font-size-medium;
  font-family: $ae-font-family-inter;
  font-weight: $ae-font-weight-regular;
  background-color: $slate-10;
  padding: 0.375rem;
  height: fit-content;
  border-radius: $border-radius-standard;

  .articleTab {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem 1rem;
    cursor: pointer;

    &:hover {
      background-color: $slate-30;
      color: $slate-90;
    }
  }

  .articleTabSelected {
    color: $slate-90;
    background-color: $slate-30;
    border-radius: toRem(2px);

    &:hover {
      background-color: $slate-30;
    }
  }

  .countBubbleContainer {
    height: 1.1rem;
    width: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: $border-radius-circle;
    background-color: $red;
    margin-left: 0.5rem;
  }

  .countBubbleLabel {
    color: $white;
    text-align: center;
    font-size: 10px;
    font-style: $ae-font-style-normal;
    font-weight: $ae-font-weight-semi-bold;
    line-height: 1rem;
  }
}
