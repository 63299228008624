@import "@aptedge/lib-support-ui/src/styles/mixins.scss";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_colors";

.user-list-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px toRem(10px);

  .user-search {
    box-sizing: border-box;
    background: var(--gray-100);
    border-radius: 0.5rem;
    margin: 0rem 0px toRem(16px) toRem(0px);
    padding: 0.625rem;
    align-items: center;
    align-content: center;
    display: flex;
    height: 3rem;
    width: 40% !important;
    .search-cancel-icon {
      cursor: pointer;
      padding: 0px 4px;
    }
  }

  .user-search:hover,
  .user-search:focus {
    outline: 1px solid var(--teal);
    .search-icon {
      color: var(--teal);
    }
  }

  .per-page-container {
    margin: 0rem 0px toRem(16px) toRem(0px);
    border-radius: 0.5rem;
    background: var(--gray-100);
    padding: 0.625rem;
    height: 2.5rem;
    color: var(--gray-500);
  }
  .per-page-container:hover,
  .per-page-container:focus {
    outline: 1px solid var(--teal);
  }
}

.user-list-header {
  font-size: x-small;
  font-weight: bolder;
  color: var(--teal);
}

.user-list {
  display: grid;
  grid-template-columns: 1fr 3fr 2fr 1fr;
  gap: 0 toRem(12px);
  align-items: center;
  justify-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: toRem(8px) toRem(12px);
  margin-bottom: toRem(8px);
  border-radius: toRem(4px);
  .user-header-txt {
    font-weight: bolder;
    text-transform: uppercase;
  }
  .delete {
    font-weight: bold;
    width: 100%;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;

    svg {
      path {
        fill: $gray-700;
      }
    }
  }
  .delete:active,
  .delete:focus,
  .delete[active],
  .delete[focus] {
    outline: none;
  }
  .delete:disabled,
  .delete[disabled] {
    cursor: not-allowed;

    svg {
      path {
        fill: $gray-400;
      }
    }
  }

  .user-status {
    padding: 0px 1rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-100);
    border-radius: toRem(4px);
    cursor: pointer;
    min-width: 25%;
  }
  .disabled {
    cursor: not-allowed;
  }
}
