@import "@aptedge/lib-ui/src/styles/_typography";

.settingsPageContainer {
  display: flex;
  font-family: $ae-font-family-inter;

  .welcomeContainer {
    flex: 1;
    background: #f6f7fb;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 100%;
    }
  }
  .settingsContainer {
    display: flex;
    margin: 2rem;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    img {
      width: 80px;
      height: 60px;
      top: 0;
    }
    .update-instance {
      background: #4ab7c3;
      color: white;
      display: block;
      padding: 1em 4em 1em 4em;
      border: none;
      border-radius: 0.75em;
      margin: 1rem auto;
    }
  }
  .policy {
    display: grid;
    justify-items: center;
    margin-bottom: 5rem;
  }
  .inputs {
    display: flex;
  }
  .input-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #4ab7c3;
    width: 95%;
    padding-left: 2em;
    text-align: center;
    border-radius: 0.75em;
    font-size: 1em;
    font-family: $ae-font-family-inter;
    padding: 1em;

    .instance {
      width: 50%;
      text-align: right;
    }
    span {
      margin-right: auto;
    }
  }
  input {
    border: none;
    outline: none;
  }

  select {
    margin: 0;
    padding: 1.5em 1em 1.5em 1em;
  }
}
