@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_colors";

.page-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$box-sizing-nav-bar});
  background-color: var(--gray-000);
  width: 100%;
  overflow-y: auto;

  > .content {
    flex: 1 0 auto;
    padding: $box-sizing-view;
    width: 100%;

    & > :not(:last-child) {
      margin-bottom: $box-sizing-xxl;
    }
  }

  .footer {
    flex: 0 0 auto;
    justify-self: flex-end;
    margin-top: 60px;
  }
}
