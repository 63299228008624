@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-support-ui/src/styles/mixins.scss";

.searchContainer {
  box-sizing: border-box;
  background: var(--gray-100);
  border-radius: toRem(8px);
  margin: toRem(18px) 0px;
  padding: toRem(10px);
  align-items: center;
  align-content: center;
  display: flex;
  .searchContainer:hover,
  .searchContainer:focus-within {
    outline: 1px solid var(--teal);
  }
}

.default {
  &:hover,
  &:focus-within {
    .searchIcon {
      color: var(--teal);
    }
  }

  .searchIcon {
    color: var(--gray-400);
    &:hover {
      color: var(--teal);
    }
  }
  .openInWebIcon {
    cursor: pointer;
    padding: 0px toRem(10px);
    path {
      stroke-width: 0;
      fill: $icon-default;
      transition: fill 200ms;
    }
    &:hover {
      path {
        fill: $icon-hover;
      }
    }
  }
  .searchCancelIcon {
    position: relative;
    bottom: 1px;
    cursor: pointer;
    padding: 0px toRem(10px);

    path {
      stroke: $gray-700;
      stroke-width: 1.5;
      transition: stroke 200ms;
    }
    &:hover path {
      stroke: $teal;
    }
  }
}

.adornmentTop {
  align-self: flex-start;
  padding-top: 1rem;
}
.backBtn {
  cursor: pointer;
}

.textfield {
  resize: none;
  overflow: hidden;
}
