@import "../TextInput/input";
@import "../../styles/boxes";
@import "../../styles/typography";

.multi-select {
  @include input-mixin();

  .input-container {
    padding: $box-sizing-regular $box-sizing-medium;
    border-radius: $border-radius-rounded;

    input {
      min-width: 10px;
    }
  }

  .popper {
    width: 100%;
    z-index: $z-index-popper;

    .multi-select-content {
      width: 100%;
      font-size: $ae-font-size-reg;
      background-color: var(--gray-000);
      border: 1px solid var(--gray-300);
      border-radius: $border-radius-standard;
      box-shadow: $box-shadow-hover;
      padding: $box-sizing-small $box-sizing-large;
      max-height: 250px;
      height: fit-content;
      min-width: fit-content;
      min-height: 40px;
      overflow-y: auto;

      .option {
        cursor: pointer;
        background-color: var(--gray-000);
        padding: $box-sizing-medium $box-sizing-large;
        margin-right: -$box-sizing-large;
        margin-left: -$box-sizing-large;

        &.highlighted {
          background-color: var(--gray-100);
        }
      }

      .no-match {
        padding: $box-sizing-medium
          calc(#{$box-sizing-large} - #{$box-sizing-medium});
      }
    }
  }
}
