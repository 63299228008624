@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  display: flex;
  padding: toRem(8px) toRem(16px) toRem(12px) toRem(16px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: solid 1px #e5e5e5;
}

.infoContainer {
  display: flex;
  align-items: center;
  gap: toRem(10px);
}

.icon {
  color: $gray-500;
}

.label {
  color: $gray-rd-800;
  text-align: right;
  font-family: $ae-font-family-inter;
  font-size: toRem(14px);
  font-weight: 400;
  line-height: toRem(20px);
}

.container input[type="checkbox"] {
  accent-color: $teal;
  width: 1rem;
  height: 1rem;
  border-radius: 0.125rem;
  border: 1px solid $gray-blue;
  background: $white;
  cursor: pointer;
}
