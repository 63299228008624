@import "@aptedge/lib-ui/src/styles/_mixins";

.switchContainer {
  background: #f3f5f5;
  border-radius: toRem(10px);
  margin-top: 1rem;
  margin-left: 1rem;
  padding: 0;
  width: max-content;
  button {
    border: none;
    border-radius: toRem(8px);
    padding: toRem(8px);
    background: #f3f5f5;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: toRem(12px);
    line-height: 140%;
    .icon {
      padding-top: 0;
    }
    &:focus {
      outline: none;
    }
    &:first-child {
      margin-right: toRem(4px);
    }
  }
  .active {
    color: #06758c;
    background: #ffffff;
    box-shadow: 0px 0px toRem(20px) rgba(0, 0, 0, 0.05);
    svg {
      filter: invert(23%) sepia(73%) saturate(2835%) hue-rotate(171deg)
        brightness(95%) contrast(40%);
    }
  }
}
