.labels-list {
  &.add-feature {
    > .list-group-item.can-add {
      .add-bar {
        display: block;
      }
      border-bottom-color: var(--primary);
    }
  }

  > .list-group-item.can-add {
    position: relative;

    .add-bar {
      display: none;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 0.2s;

      .btn {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        transform: translate(-50%, -50%);
        z-index: 1000;
        border-radius: 4px;
        background-color: var(--primary);
        font-size: 10px;
        color: white;
      }
    }

    &.can-add:hover:not(.is-adding) {
      border-bottom-color: var(--primary);

      .add-bar {
        display: block;
      }
    }
  }
}
