@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  padding: 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 1.5rem;
}

.emptyStateContainer {
  margin: 1rem 0rem;
}

.emptyStateDescription {
  color: $gray-1;
  font-family: $ae-font-family-inter;
  font-size: toRem(16px);
  font-weight: 400;
  line-height: toRem(24px);
}

.createFilterButton {
  display: flex;
  padding: toRem(10px) toRem(18px);
  justify-content: center;
  align-items: center;
  gap: toRem(8px);
  border-radius: toRem(8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);
  font-size: toRem(16px);
  font-weight: $ae-font-weight-semi-bold;
  line-height: toRem(24px);
}
