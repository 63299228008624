@import "../../styles/boxes";
@import "../../styles/typography";

.tabs-bar {
  display: flex;
  width: 100%;

  .tab {
    position: relative;
    width: fit-content;
    text-align: center;
    min-width: 40px;
    padding: 12px 10px 4px;
    margin-bottom: $box-sizing-xl;
    cursor: pointer;
    font-weight: $ae-font-weight-bold;
    color: var(--gray-500);

    &.active {
      color: var(--primary);
    }

    &.active::after {
      position: absolute;
      width: 70%;
      left: 15%;
      bottom: 0;
      content: " ";
      border-bottom: 2px solid var(--primary);
    }
  }
}
