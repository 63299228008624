@import "@aptedge/lib-ui/src/styles/_colors";

.configInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inputContainer {
  margin-top: 1rem;
  table {
    width: 100%;
    border-radius: 8px;
    td:first-child,
    th:first-child {
      border-right: none;
    }
    td,
    th {
      border: 1px solid #d0d5dd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(1),
    tr:nth-child(2) {
      td,
      th {
        border-bottom: none;
      }
    }
    tr {
      display: grid;
      grid-template-columns: 6.75rem 1fr;
      label {
        font-weight: 400;
      }
      .resourceInput {
        width: 100%;
        border: none;
        outline: none;
      }
    }
    tr:first-child th:first-child,
    tr:first-child td:first-child {
      border-top-left-radius: 0.25rem;
    }

    tr:first-child th:last-child,
    tr:first-child td:last-child {
      border-top-right-radius: 0.25rem;
    }

    tr:last-child th:first-child,
    tr:last-child td:first-child {
      border-bottom-left-radius: 0.25rem;
    }

    tr:last-child th:last-child,
    tr:last-child td:last-child {
      border-bottom-right-radius: 0.25rem;
    }
  }
  button {
    border-radius: 0.25rem;
    border: 1px solid #036172;
    background: #06778c;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 0.5rem 1rem;
    margin-top: 0.25rem;
  }
  .resourceList {
    display: grid;
    gap: 0.25rem;
    .resourceItem {
      display: flex;
      justify-content: space-between;
      border-radius: 0.25rem;
      border: 1px solid $slate-10;
      background: $white;
      padding: 0.5rem 0.88rem;
      .editIcon {
        color: #d9d9d9;
        margin-right: 1rem;
        cursor: pointer;
      }
      .deleteIcon {
        color: #d9d9d9;
        cursor: pointer;
      }
      .text {
        display: flex;
        align-items: center;
        .name {
          color: $slate-90;
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.5rem;
          margin-right: 0.5rem;
        }
        .url {
          color: $slate-70;
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem; /* 171.429% */
        }
      }
    }
  }
}
.addButton {
  display: flex;
  padding: 0.5rem 1.0625rem;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid #d0d5dd;
  background: #f3f5f5;
  color: #718286;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 171.429% */
  margin-top: 1rem;
}
