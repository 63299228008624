@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.article-list-item {
  .article-url {
    word-break: break-all;

    .preview-highlight {
      font-weight: $ae-font-weight-bold;
      font-style: normal;
      background-color: $highlight-color;
      padding: 0rem 0.25rem;
    }

    .offset-highlight {
      font-style: normal;
      background-color: $highlight-color;
    }
  }

  .copy-icon {
    line-height: 1;
    border-radius: $border-radius-standard;
    padding: $box-sizing-regular;
    color: var(--gray-600);

    &:hover {
      color: var(--gray-900);
    }

    &:active {
      color: var(--primary);
    }
  }

  .notion-icon {
    width: toRem(15px);
  }
}
