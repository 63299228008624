@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_colors";

.ticket-item {
  border: 1px solid $border-color;
  border-radius: $border-radius-rounded;
  padding: $box-sizing-large;
  padding-top: 0;
  &.clickable {
    h4:hover {
      color: var(--primary);
    }
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    min-width: initial;
    height: max-content;
    padding-top: 1rem;
    margin: 0;

    h4 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      cursor: pointer;
      width: 100%;
      padding: 1px 0;
      color: var(--gray-900);
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-bold;
      overflow: hidden;
      margin-bottom: 0;

      .ticket-icon {
        padding-left: 0;
        padding-top: 2px;
        margin-right: $box-sizing-small;
        margin-left: 0;
      }

      .id {
        margin-right: $box-sizing-large;
      }

      .subject {
        @include textEllipsis(1, 55%);

        .preview-highlight {
          font-style: normal;
          background-color: $highlight-color;
          padding: 0rem 0.25rem;
        }

        .offset-highlight {
          font-style: normal;
          background-color: $highlight-color;
        }
      }
      .customer {
        display: flex;
        align-items: center;
        width: max-content;
        white-space: nowrap;
        margin-inline-start: auto;
        span {
          padding-block-start: 2px;
        }
      }
    }
  }

  .details {
    display: flex;
    font-size: $ae-font-size-sm;
    font-weight: $ae-font-weight-medium;
    margin: $box-sizing-regular 0;
    color: var(--gray-500);

    .customer {
      margin-right: $box-sizing-large;
    }

    .icon {
      margin-right: $box-sizing-small;

      &:first-of-type {
        padding-left: 0;
      }
    }

    span:not(.icon) {
      margin-left: $box-sizing-small;
    }
  }

  .ticket-summary {
    .description {
      color: var(--gray-600);
      font-size: $ae-font-size-sm;
    }

    .markdown-content {
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-regular;
      color: var(--gray-900);
      margin: 20px 0;
      padding: 0;

      .placeholder {
        font-size: $ae-font-size-sm;
      }
    }
  }

  .ticket-info {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    font-size: $ae-font-size-sm;

    .left-side {
      & > * {
        margin-right: $box-sizing-regular;
      }

      .sentiment {
        color: #da8049;
        margin-right: $box-sizing-medium;
      }

      .status {
        font-weight: $ae-font-weight-medium;
        color: var(--primary);
        border-radius: 7px;
        background-color: var(--primary-100);
        padding: 4px 8px;
      }
    }

    .dates {
      color: var(--gray-500);
      font-style: normal;
      font-size: toRem(13px);
      line-height: 130%;
      letter-spacing: 0.01em;
      font-family: $ae-font-family-inter;
      margin-top: toRem(8px);
    }
  }
}
