@import "@aptedge/lib-ui/src/styles/_boxes";

.label-chip-list {
  white-space: nowrap;
  overflow: hidden;

  > * {
    white-space: normal;
    margin-right: $box-sizing-medium;
    margin-top: $box-sizing-small;
  }
}
