@import "../../../../styles/mixins";

.answer-link {
  background: #f0f4ff;
  border-radius: 0.5rem;
  color: #1570ef;
  font-size: small;
  cursor: pointer;
  margin: 4px 0;
  padding: 0.25rem 0.62rem;
  padding-bottom: 0.42rem;
  display: inline-block;

  .icon {
    padding: 0;
    margin-right: 0.62rem;
    svg {
      width: toRem(20px);
      height: toRem(20px);
    }
  }
  .edge-icon {
    font-size: unset;
    svg {
      rect {
        fill: #06778c;
      }
    }
  }
  .img-icon {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.62rem;
  }
}
