.wrapper,
.multiInputContainer {
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.wrapper {
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  width: 100%;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  .errorInput {
    background: var(--Red-Red-00, #fef2f2);
  }
  .leading {
    padding: 8px 12px;
    border: none;
    border-radius: 8px 0px 0px 8px;
    color: var(--Gray-600, #475467);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    outline: none;
  }
  .textWDropdown {
    flex: 1;
    padding: 8px 12px;
    border-radius: 4px;
    border: none;
    outline: none;
    color: var(--Gray-500, #667085);
  }
  .readonly {
    cursor: not-allowed;
  }
  .separator {
    margin-left: 8px;
    border-radius: 0 4px 4px 0;
    border-left: 1px solid var(--Gray-300, #d0d5dd);
  }
}
.error {
  color: var(--Red-Red-60, #dc2626);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.multiInputContainer {
  display: grid;
  .inputContainer {
    margin: 8px 0;
  }
  .input {
    width: 100%;
    border: none;
    outline: none;
    padding-left: 12px;
  }
  .pageUrl {
    display: flex;
    align-items: center;
    padding-left: 12px;
    color: var(--Gray-500, #262626);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: not-allowed;
  }
  .separator {
    margin-left: 8px;
    border-radius: 0 4px 4px 0;
    border-left: 1px solid var(--Gray-300, #d0d5dd);
  }
  .multiInput {
    display: flex;
    align-items: center;

    button {
      border-radius: 4px;
      border: 1px solid #d0d5dd;
      padding: 12px;
      background: #f3f5f5;
      border: none;
      outline: none;
      margin-left: 8px;
      color: #718286;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .disabledBtn {
      cursor: not-allowed;
      border: red;
    }
    .addBtn {
      display: flex;
      align-items: center;
      padding: 6px 16px;

      svg {
        margin-right: 8px;
      }
    }
  }
}
