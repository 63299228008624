@import "@aptedge/lib-support-ui/src/styles/mixins.scss";

.assignee-badge {
  display: inline-block;
  .username {
    font-weight: 400;
    font-size: toRem(12px);
    line-height: toRem(18px);
    padding-left: 0.25rem;
  }
}

.jira-popover {
  h3 {
    font-size: toRem(12px);
  }
  .subtitle {
    font-size: toRem(11px);
    color: var(--gray-300);
  }
}
