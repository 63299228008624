@import "@aptedge/lib-ui/src/styles/_colors";

.invite {
  cursor: pointer;
}

.invite-form button {
  margin-top: 1rem;
  margin-left: auto;
}
