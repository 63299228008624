@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  position: relative;
  width: 25%;
}

.referenceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  gap: 0.5rem;
  border: 1px solid $gray-rd-200;
  border-right: 0;
  border-bottom: 0;
  background: $white;
}

.referenceContainerBottom {
  border-bottom: 1px solid $gray-rd-200;
}

.referenceContainerRight {
  border-right: 1px solid $gray-rd-200;
}

.referenceContainerTopLeft {
  border-top-left-radius: 0.25rem;
}

.referenceContainerTopRight {
  border-top-right-radius: 0.25rem;
}

.referenceContainerBottomLeft {
  border-bottom-left-radius: 0.25rem;
}

.referenceContainerBottomRight {
  border-bottom-right-radius: 0.25rem;
}

.referenceContainerDisabled {
  background: $gray-rd-100;
}

.referenceContainerDisabled:hover {
  background: $gray-rd-100;
}

.referenceLabel {
  margin: 0;
  padding: 0;
  color: $black;
  font-family: $ae-font-family-inter;
  font-size: 1rem;
  font-style: $ae-font-style-normal;
  font-weight: $ae-font-weight-regular;
  line-height: 1.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.referenceLabelDisabled {
  color: $gray-rd-400;
}

.popperContainer {
  width: 100%;
  background: $white;
  z-index: 10;
  border: 1px solid $gray-rd-200;
  border-top: 0px;
  max-height: 32rem;
  overflow: scroll;
}

.option {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  font-size: 1rem;
}

.option:hover {
  background: $gray-rd-200;
}

.optionSelected {
  color: $teal;
}
