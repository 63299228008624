@import "@aptedge/lib-ui/src/styles/_typography";

.table {
  display: grid;
  font-size: 14px;
  color: var(--gray-900);
  background-color: var(--gray-000);

  thead,
  tbody,
  tr {
    display: contents;
  }

  tbody tr:nth-of-type(odd) {
    td {
      background-color: var(--light);
    }
  }

  th,
  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.align-middle {
      display: grid;
      align-items: center;
    }

    &.text-center {
      display: grid;
      justify-content: center;
    }
  }

  th,
  thead th {
    font-weight: $ae-font-weight-semi-bold;
    border-color: var(--gray-200);
  }

  td {
    border-top: none;
    border-bottom: 1px solid var(--gray-200);
    font-weight: $ae-font-weight-medium;
  }
}
