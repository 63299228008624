@import "../../styles/boxes";

.list {
  display: flex;
  flex-direction: column;

  & > :first-child {
    border-top-left-radius: $border-radius-standard;
    border-top-right-radius: $border-radius-standard;
  }

  .list-item + .list-item {
    border-top-width: 0;
  }

  & > :last-child {
    border-bottom-left-radius: $border-radius-standard;
    border-bottom-right-radius: $border-radius-standard;
  }
}
