.homepage__no_recent_searches {
  display: grid;
  justify-items: center;
  align-items: center;
  margin: 2rem;
  .desc {
    text-align: center;
    margin-top: 1.5rem;
  }
}
