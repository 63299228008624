@import "../../styles/boxes";
@import "../../styles/typography";

.ae-tooltip {
  line-height: 1;

  .tooltip-trigger {
    display: inline-block;

    :focus {
      outline: none;
    }
  }
}

.tooltip-popper {
  .tooltip-popper-content {
    opacity: 0;
    animation-name: fade-in;
    animation-duration: 200ms;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    color: var(--gray-000);
    background-color: var(--gray-900);
    border: 1px solid var(--gray-111);
    box-shadow: $box-shadow-hover;
    padding: $box-sizing-regular $box-sizing-medium;
    max-width: 300px;
    font-size: $ae-font-size-xs;
    border-radius: $border-radius-standard;
    cursor: default;
    line-height: normal;
  }
}
