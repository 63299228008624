@import "../../styles/boxes";
@import "../../styles/typography";

.sentiment-score {
  text-align: center;
  font-weight: $ae-font-weight-bold;
  border-radius: $border-radius-circle;
  background-color: var(--gray-000);
  padding: $box-sizing-regular;
  color: var(--white);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
