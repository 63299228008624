.date-range-filter {
  .date-range-picker {
    border: none;
    width: fit-content;

    .label {
      display: none;
    }

    .value {
      display: inline-flex;
      min-width: 100%;
      flex: 1 0 auto;
    }

    .chevron-down-icon {
      display: none;
    }
  }
}
