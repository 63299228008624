@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_colors";

.article-search-result {
  display: flex;
  flex-direction: column;
  user-select: none;
  padding: $box-sizing-large;
  margin: $box-sizing-medium;
  border-radius: $border-radius-rounded;
  transition: all 0.2s;
  color: var(--gray-800);
  border: 1px solid $border-color;

  .article-header {
    display: flex;

    align-items: center;
    .subject {
      @include textEllipsis(1);
      color: var(--gray-900);
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-bold;
      height: 24px;
      padding-top: 2px;

      .preview-highlight {
        font-style: normal;
        background-color: $highlight-color;
        padding: 0rem 0.25rem;
      }

      .offset-highlight {
        font-style: normal;
        background-color: $highlight-color;
      }
    }
    .article-header-action-icons {
      margin-left: auto;
    }
  }

  .article-description {
    margin-top: $box-sizing-large;
    color: var(--gray-600);
    font-size: $ae-font-size-sm;
    overflow-y: auto;
    max-height: 5rem;
    word-break: break-word;
    @include textEllipsis(2);
  }

  &:hover {
    text-decoration: none;
  }

  .notion-icon,
  .sharepoint-icon {
    height: toRem(19px);
    width: toRem(19px);
  }
  .article-time {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
}
