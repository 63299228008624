.team-list-item {
  input {
    font-size: 14px;
  }
  .icons {
    .btn {
      border-radius: 4px;
      box-shadow: none !important;
      transition: visibility 50ms;
      visibility: hidden;
      border: none;
      background-color: white;
      color: hsl(0, 0, 50);

      &:hover {
        background-color: hsl(0, 0, 98);
        color: hsl(0, 0, 40);

        &.delete {
          color: red;
        }
      }

      &:active {
        background-color: hsl(0, 0, 97);
      }
    }
  }

  &:hover {
    .icons .btn {
      visibility: visible;
    }
  }
}
