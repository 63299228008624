@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  margin: 1rem 0rem;
}

.message {
  margin: 0;
  padding: 0;
  color: $red;
  font-family: $ae-font-family-inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: $ae-font-weight-regular;
  line-height: 1.25rem;
}
