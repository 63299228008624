@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.ticket-list-item {
  &.clickable {
    h4:hover {
      color: var(--primary);
    }
  }

  .ticket-header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    white-space: nowrap;
    overflow-x: hidden;
    font-weight: $ae-font-weight-medium;
    margin-bottom: $box-sizing-regular;
    padding-bottom: toRem(8px);
    border-bottom: 1px solid #d5d5d5;
    color: #5b5675;

    &:empty {
      border-bottom: none;
      margin: 0;
      padding: 0;
    }

    .ticket-icon {
      margin-right: $box-sizing-medium;
      padding-left: 0;
    }
    .customer {
      font-weight: 400;
      font-size: toRem(12px);
      line-height: toRem(18px);
      margin-right: auto;
      .customerName {
        padding-left: 0.25rem;
      }
    }
  }

  .ticket-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    .date {
      font-weight: $ae-font-weight-regular;
    }
  }

  .blur-overlay {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: toRem(10px);
    backdrop-filter: blur(2px);
    justify-content: center;
    align-items: center;

    .ae-button {
      border-radius: $border-radius-rounded;
      padding-top: $box-sizing-medium;
      padding-bottom: $box-sizing-medium;
    }
  }
  .ticket-info {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    font-size: $ae-font-size-sm;

    .left-side {
      display: inline-grid;

      .status {
        font-weight: $ae-font-weight-medium;
        color: var(--primary);
        border-radius: toRem(7px);
        background-color: var(--primary-100);
        padding: toRem(4px) toRem(8px);
      }
    }

    .dates {
      font-size: 0.75rem;
      color: var(--gray-500);
      font-style: normal;
      line-height: 130%;
      letter-spacing: 0.01em;
      font-family: $ae-font-family-inter;
      margin-top: toRem(8px);
    }
  }
  .ticket-summary .markdown-content {
    padding: 0;
  }
  &:hover {
    color: var(--gray-600);
    background-color: var(--gray-100);
  }

  &:focus {
    outline: none;
  }
}
