@import "@aptedge/lib-ui/src/styles/_boxes";

.modal.create-edge {
  .input-container {
    margin-top: 0;
  }

  .modal-footer,
  .modal-body {
    padding: 0;
    border: none;
  }

  .modal-footer {
    padding-top: $box-sizing-large;
  }
}
