@import "../../styles/boxes";
@import "../../styles/typography";

.modal.ae-modal {
  .content.modal-content {
    padding: $box-sizing-xl $box-sizing-xxxl;
    color: var(--gray-900);
    border: 1px solid var(--gray-200);
    background-color: var(--gray-000);
    max-width: 100%;
    position: relative;

    .modal-head {
      margin-bottom: $box-sizing-xl;
      padding-bottom: $box-sizing-medium;
      border-bottom: 2px solid var(--gray-200);

      .modal-title {
        display: flex;
        align-items: center;
        line-height: 1rem !important;
        font-size: $ae-font-size-medium;
        color: var(--gray-900);
        font-weight: $ae-font-weight-bold;
      }

      .modal-actions {
        .icon {
          cursor: pointer;
          color: var(--gray-600);
          transition: all 200ms;
          font-size: $ae-font-size-lg;
          padding: $box-sizing-medium;
          border-radius: $border-radius-standard;

          &:hover {
            color: var(--gray-900);
          }

          svg {
            vertical-align: middle;
          }
        }
      }
    }

    .ae-modal-footer {
      margin-top: $box-sizing-xl;
    }
  }

  .modal-dialog.modal-sm {
    width: 450px;
    max-width: 450px !important;
  }

  .modal-dialog.modal-md {
    width: 600px;
    max-width: 600px !important;
  }

  .modal-dialog.modal-lg {
    width: 800px;
    max-width: 800px !important;
  }

  .modal-dialog.modal-xl {
    width: 1024px;
    max-width: 1024px !important;
  }
}

.modal-submit-group {
  display: flex;
  justify-content: flex-end;
  margin-top: $box-sizing-xxl;

  & > :not(:last-child) {
    margin-right: $box-sizing-medium;
  }
}
