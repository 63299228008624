@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.navbar.settings {
  min-height: $box-sizing-secondary-bar;
  background-color: var(--gray-000);
  border-top: 1px solid var(--gray-100);
  border-bottom: 1px solid var(--gray-100);
  padding: $box-sizing-large $box-sizing-xxl;
  box-shadow: $box-shadow-navbar;
  z-index: $z-index-secondary-bar;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .heading {
    margin-bottom: $box-sizing-xl;

    .icon {
      margin-left: $box-sizing-medium;
      font-size: $ae-font-size-lg;
    }
  }

  .nav-item {
    width: fit-content;
    text-align: center;
    min-width: 40px;
    font-size: $ae-font-size-reg;
    font-weight: $ae-font-weight-semi-bold;
    color: var(--gray-500);
    text-decoration: none;

    &.active {
      color: var(--primary);
    }
  }
}
