@import "../../styles/boxes";
@import "../../styles/colors";

.checkbox {
  --size: #{$box-sizing-large};
  display: flex;
  position: relative;
  height: var(--size);
  width: var(--size);
  background-color: #e7eaeb;
  border-radius: $border-radius-standard;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
  }

  .icon {
    opacity: 0;
    font-size: 8px;
    color: var(--white);
    padding: 0.125rem 0.25rem;
  }

  &.checked {
    background-color: var(--primary-500);

    .icon {
      opacity: 1;
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &:not(.disabled) {
    cursor: pointer;
  }
}
