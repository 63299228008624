.week {
  display: flex;

  .day {
    &.today {
      background-color: var(--gray-100);
    }

    &.outside-month {
      color: var(--gray-300);
    }

    &.inside-range {
      background-color: var(--primary-faded);
    }

    &.selected {
      background-color: var(--primary);
      color: var(--white);
    }

    &.disabled {
      color: var(--gray-300);
    }
  }
}
