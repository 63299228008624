@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.ae-toast-container {
  color: var(--gray-700);
  font-weight: $ae-font-weight-regular;

  .toast {
    opacity: 1;
  }

  .Toastify {
    &__toast {
      font-size: $ae-font-size-sm;
      min-height: auto;
      box-shadow: $box-shadow-default;
      border-radius: $border-radius-rounded;
      border: 1px solid var(--gray-100);

      &--error,
      &--warn,
      &--info,
      &--success {
        background-color: var(--gray-000);
      }

      .toast-header {
        border-bottom: none;
      }
    }

    &__close-button {
      color: var(--gray-500);
      font-weight: $ae-font-weight-light;

      &:hover {
        color: var(--gray-900);
      }

      &:focus {
        outline: none;
      }
    }
  }
}
