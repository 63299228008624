@import "@aptedge/lib-ui/src/styles/_colors";

.notification-list {
  min-width: 500px;
  max-width: 520px;
  max-height: 90vh !important;
  height: 80vh;
  overflow-y: scroll !important;
  min-height: 120px;
  padding: 20px;

  h3 {
    font-size: 18px;
    font-weight: 600;
    color: var(--gray-700);
    margin-bottom: 24px;
  }

  .fetch-placeholder {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    font-size: 10px;
    font-weight: 300;
    color: hsl(0, 0, 60);
  }

  .mark-all {
    user-select: none;
    font-size: 12px;
    font-weight: 300;
    cursor: pointer;
    color: $primary;

    &.disabled {
      cursor: default;
      color: hsl(0, 0, 80);
    }
  }

  .all-clear {
    span[role="img"] {
      font-size: 50px;
      opacity: 0.8;
    }
  }
}
