@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_mixins.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";

.title {
  color: #101828;
  font-family: $ae-font-family-inter;
  font-size: toRem(18px);
  font-weight: $ae-font-weight-semi-bold;
  line-height: toRem(28px);
}

.subtitle {
  margin-top: toRem(4px);
  color: $gray-rd-800;
  font-family: $ae-font-family-inter;
  font-size: toRem(14px);
  font-weight: $ae-font-weight-regular;
  line-height: toRem(20px);
}
