@import "../../styles/boxes";

.card {
  width: 100%;
  background-color: var(--gray-000);
  padding: $box-sizing-xxxl;
  border-radius: $border-radius-standard;
  border: 1px solid var(--gray-200);
  box-shadow: $box-shadow-card;

  .card-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $box-sizing-xl;

    h3 {
      flex: 1 1 50%;
      color: var(--gray-800);
      margin-bottom: 0;
    }

    .card-title {
      display: flex;
      align-items: center;
    }
  }
}
