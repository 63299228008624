@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.edge-timestamps {
  display: flex;
  padding-top: $box-sizing-medium;
  margin: $box-sizing-large 0;
  .banned {
    border: toRem(1px) solid var(--danger);
    border-radius: toRem(4px);
    color: $priority-high;
    padding: toRem(4px);
  }
  & &-date {
    width: 50%;
    div {
      color: var(--gray-900);
    }

    label {
      margin: 0;
      font-weight: $ae-font-weight-regular;
    }

    &.updated {
      label {
        color: var(--light-orange);
      }
    }

    &.created {
      label {
        color: var(--gray-400);
      }
    }
  }
}
