@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  display: flex;
  align-items: center;
  gap: toRem(8px);
  padding: toRem(4px) toRem(18px) toRem(4px) toRem(10px);
  border-radius: toRem(4px);
  cursor: pointer;
}

.selectedContainer {
  background: #e2faff;
  border: 1px solid #06758c;
  color: #06758c;
}

.unselectedContainer {
  background: #fff;
  border: 1px solid #ddd;
  color: #666;
}

.label {
  font-family: $ae-font-family-inter;
  font-size: toRem(14px);
  font-weight: $ae-font-weight-regular;
  line-height: toRem(24px);
}
