@import "@aptedge/lib-ui/src/styles/_colors";

.action-buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0 0.5rem;
  position: relative;
  height: 20px;
  .icon {
    cursor: pointer;
    path {
      stroke-width: 2;
      stroke: $icon-default;
    }
    &:hover {
      path {
        stroke: $icon-hover;
      }
    }
  }

  > :last-child .icon {
    padding-right: 0;
  }
}
