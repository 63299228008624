@import "../../styles/boxes";
@import "../../styles/typography";

.date-range-picker {
  width: 220px;
  min-width: fit-content;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  border: 1px solid var(---gray-200);
  line-height: $box-sizing-input;
  color: var(--gray-700);
  background-color: var(--gray-000);
  border-radius: $border-radius-standard;

  &:hover {
    color: var(--gray-800);
    border-color: var(--primary-hover);

    .icon {
      color: var(--gray-500);
    }
  }

  .icon {
    flex: 0 0 auto;
    padding: $box-sizing-regular $box-sizing-medium;
    color: var(--gray-400);
    font-size: $ae-font-size-reg;
  }

  .value {
    flex: 1 1 auto;
    display: flex;
    max-width: calc(100% - 50px);
    margin: $box-sizing-regular;

    span {
      display: block;
      text-overflow: ellipsis;
      /* Required for text-overflow to do anything */
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &.disabled {
    border-color: var(--gray-100);
    color: var(--gray-400);
    cursor: default;

    .icon {
      color: var(--gray-200);
    }
  }

  &.open,
  &:focus,
  &:focus-within {
    border-color: var(--primary);

    label {
      color: var(--primary);
    }
  }
}

.date-range-picker-content {
  background-color: var(--gray-000);
  box-shadow: $box-shadow-default;
  border-radius: $border-radius-standard;
  border: 1px solid var(--gray-100);
  width: fit-content;
  padding: $box-sizing-xl $box-sizing-xxl;
  display: inline-flex;

  .date-range-shortcuts {
    padding-left: 0;
  }

  .calendar-wrapper {
    > header {
      display: flex;
      position: relative;
      user-select: none;
      color: var(--gray-700);
      font-size: $ae-font-size-reg;
      margin-bottom: $box-sizing-regular;
      padding: $box-sizing-regular $box-sizing-medium;
      transition-duration: 200ms;

      .arrow {
        top: 50%;
        position: absolute;
        cursor: pointer;
        color: var(--gray-500);
        transform: translateY(-50%);

        &:hover {
          color: var(--gray-600);
        }

        &:active {
          color: var(--gray-700);
        }

        &.right {
          right: 0;
        }

        &.left {
          left: 0;
        }
      }

      .title {
        flex-grow: 1;
        text-align: center;
      }
    }

    .calendars {
      display: inline-flex;

      .calendar {
        padding: $box-sizing-small;

        .day.outside-month {
          user-select: none;
          pointer-events: none;
          opacity: 0;
        }

        &:first-of-type {
          margin-right: $box-sizing-medium;
        }
      }
    }
  }
}
