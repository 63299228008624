@import "@aptedge/lib-ui/src/styles/_colors";

.external-links {
  text-decoration-line: underline;
  color: #475467 !important;
  display: inline-block;
  word-break: break-all;
  &:hover {
    text-decoration: underline;
  }
}
