@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";
@import "@aptedge/lib-support-ui/src/styles/mixins.scss";

.articleActions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-radius: 0 0 $border-radius-rounded $border-radius-rounded;
  border-top: 1px solid $slate-20;
  position: relative;

  button {
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    border-radius: $border-radius-standard;
    font-weight: $ae-font-weight-regular;
    font-family: $ae-font-family-inter;
    font-size: $ae-font-size-medium;
    font-style: $ae-font-style-normal;
    line-height: $box-sizing-xxl;
    gap: $box-sizing-medium;

    span {
      padding: 0;
    }
  }

  .discardButton {
    color: $slate-60;
    background: $slate-10;

    &:hover {
      filter: brightness(80%);
    }
  }

  .saveArticleButton {
    color: $white;
    background: $viking-70;

    &:hover {
      filter: brightness(80%);
    }
  }

  .discardModal {
    display: contents;
  }
}

.articleActionsReviewPage {
  background: $white;
}
