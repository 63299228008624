@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.question-query {
  margin-top: 1rem;
}
.container {
  min-height: max-content;
  .wrapperStyle {
    border-radius: $primary-border-radius;
    background-color: $slate-00;
  }
  .answerBody {
    line-height: 1.5rem !important;
    & > :first-child {
      padding-top: 0;
    }
    h3 {
      font-size: 1rem;
    }
    p {
      line-height: 1.5rem;
    }
  }
}
.lastAnswer {
  min-height: calc(100dvh - 210px);
}
