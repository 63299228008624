.ussageContainer {
  span {
    color: #475467;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    .underline {
      text-decoration-line: underline;
      padding-left: 4px;
    }
  }
  h5 {
    color: var(--Slate-Slate-60, #7789a4);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}
.progressBarContainer {
  padding-top: 0.5rem;
  .title {
    color: #475467;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-bottom: 0.25rem;
  }
  .warningTitle {
    color: var(--Red-Red-60, #dc2626);
  }
}
.topSpacing {
  margin-top: 1.5em;
}
