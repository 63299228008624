@import "@aptedge/lib-ui/src/styles/_typography";

th {
  .th {
    display: flex;
    align-items: center;
    font-size: $ae-font-size-sm;

    &:not(.sortable) {
      .th-sort {
        width: 0;
        cursor: default;
        pointer-events: none;
        opacity: 0;
      }
    }

    &.sortable {
      .th-sort {
        display: flex;
        flex-direction: column;
        padding-left: 6px;

        .icon {
          padding: 0 4px;
          font-size: $ae-font-size-sm;
          color: var(--gray-200);

          > svg {
            position: relative;
          }

          &:nth-child(1) {
            > svg {
              top: 5px;
            }
          }
          &:nth-child(2) {
            > svg {
              bottom: 3px;
            }
          }

          &.active {
            color: var(--primary-500);
          }

          &:hover:not(.active) {
            color: var(--primary-300);
          }
        }
      }
    }
  }
  &.text-center {
    .th {
      justify-content: center;
    }
  }

  &.text-right {
    .th {
      justify-content: flex-end;
    }
  }
}
