@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.loader {
  padding: $box-sizing-medium 0;
}

.answerHeader {
  display: flex;
  align-items: center;
}

.answerBody {
  font-size: $ae-font-size-medium;
  font-weight: $ae-font-weight-light;
  font-family: $ae-font-family-inter;
  padding-top: 0.5rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #475467;
  }
}

.answerSearchResult {
  display: flex;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #d0d5dd;
  flex-direction: column;
  height: 10rem;
  overflow: hidden;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  transition: background-color 200ms, border 200ms;
  cursor: pointer;

  .title {
    transition: color 200ms;
  }

  &:hover {
    background-color: #e1f8fc;
    border: 1px solid #aad5db;

    .title {
      color: #35a4b0;
    }
  }
}

.answerCardVisible {
  border: 1px solid #d9f5f9;
  background-color: #eefdff;

  .title {
    color: #35a4b0;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: #353d3f;
}
