$blue: #3a5ccb;
$light-blue: #97d6fa;
$cyan: #6a9dff;
$green: #329667;
$teal: #06778c;
$light-teal: #98c5ce;
$light-orange: #f9b73e;
$orange: #f0935b;
$red: #ed6a5a;
$light-gray: #cfcfcf;
$whiteSmoke: #f5f5f5;

$brand-primary: #3a485d;

//  Grayscale (these are bootstrap's defaults)
$light-bg: #fbfcfc; // light mode background

$true-black: #000;
$black: #121212;
$black-1: #101828;
$white: #fff;

$gray-000: $white;
$gray-100: #f3f5f5;
$gray-200: #dee2e3; // inactive state, icons/buttons
$gray-300: #c6cdcf; // instruction text/notes, stroke line
$gray-400: #a9b4b7;
$gray-500: #88979b;
$gray-600: #718286;
$gray-700: #5d6b6f;
$gray-800: #495457;
$gray-900: #353d3f; // dark mode bg, shadows, headings/body text
$gray-111: $black;

$gray-rd-100: #fafafa;
$gray-rd-200: #ddd;
$gray-rd-300: #d0d5dd;
$gray-rd-400: #9c9c9c;
$gray-rd-600: #666;
$gray-rd-700: #344054;
$gray-rd-800: #475467;
$gray-rd-900: #667085;
$gray-blue: #7789a4;
$gray-1: #333;
$gray-2: #4f4f4f;
$gray-3: #999;

$red-rd-100: #fef2f2;

$teal-rd-200: #9bc9d1;
$teal-rd-300: #9ac0c7;
$teal-rd-700: #036172;

$blue-600: #1570ef;

$primary-100: #f2f7f8;
$primary-300: #d6edf2;
$primary-500: #62c3d6;

$royal-blue-100: #edf1ff;
$royal-blue-300: #ced9ff;
$royal-blue-500: #90a6ef;
$royal-blue: #3a5ccb;

$yellow-100: #fff9ee;
$yellow-300: #ffeecf;
$yellow-500: #fedda2;
$yellow: #f9cc7b;
$title-color: #353d3f;
$header-title-color: #35a4b0;
$highlight-color: #d6edf2;
$border-color: #d0d5dd;
$scroll-background-color: $whiteSmoke;
$action-button-color: #07778b;
$button-background-color: #f9f9f9;

// Status color
$status-orange: #c97441;
$status-background: #ffd5bb;
$teal-lighter: #f0f7f9;
$selection: #077789;
$priority-high: #d7828f;

// Bootstrap overrides
$blue: $blue;
$red: $red;
$orange: $orange;
$yellow: $yellow;
$green: $green;
$teal: $teal;
$cyan: $cyan;
$primary: $teal;
$secondary: $gray-200;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$primary-hover: lighten($primary, 5%);
$primary-faded: transparentize($primary, 0.8);
$primary-faded-dark: transparentize($primary, 0.5);
$warning-hover: lighten($warning, 5%);
$danger-hover: lighten($danger, 5%);
$danger-faded: transparentize($danger, 0.8);
$white-faded: transparentize($white, 0.4);
$dark-faded: transparentize(#121212, 0.4);
$shadow-light: transparentize($gray-900, 0.8);
$shadow-dark: transparentize($black, 0.7);
$linkColor: $blue-600;
$icon-default: $gray-700;
$icon-hover: $selection;
$loader-light-color: #b2dde5;
$loader-dark-color: $action-button-color;
$text-color: $gray-2;
// State 00-70
$slate-00: #ffffff;
$slate-10: #f6f7f9;
$slate-20: #eaecf1;
$slate-30: #cbd2dc;
$slate-40: #b0baca;
$slate-50: #94a2b7;
$slate-60: #7789a4;
$slate-70: #5c6e89;
$slate-80: #48566b;
$slate-90: #333d4c;

// viking 00-70
$viking-1: #06758c;
$viking-00: #f4fafb;
$viking-10: #e0f3f5;
$viking-20: #bae4e8;
$viking-30: #94d4db;
$viking-40: #71c7d0;
$viking-50: #4ab7c3;
$viking-70: #2a767e;
$viking-80: #1d5258;
$viking-90: #123236;

// blue 00-90
$blue-40: #818cf8;
$blue-60: #4f46e5;

// orange 00-90
$orange-1: #ff9c54;
$orange-10: #fef3c7;
$orange-40: #fbbf24;
$orange-80: #92400e;

// red 00-90
$red-00: #fef2f2;
$red-20: #fecaca;
$red-50: #ef4444;
$red-60: #dc2626;

// green 00-90
$green-00: #f0fdf4;
$green-20: #bbf7d0;
$green-40: #4ade80;
$green-80-1: #15803d;
$green-80-2: #166534;
$green-80: #15803d;
$green-60: #16a34a;
