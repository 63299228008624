.tab {
  border: none;
  margin: 0 auto;
  padding: 6px 12px;
  width: 100% !important;

  & > a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none !important;
    color: var(--gray-400);
    background-color: inherit;

    > * {
      margin: 0 auto;
    }
  }

  small {
    border-bottom: 2px solid transparent;
    padding-bottom: 5px;
  }

  .icon {
    display: block;
  }

  &:hover:not(.active) > a {
    color: var(--gray-600);
    background-color: inherit;
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }

  &.active {
    > a {
      color: var(--primary);
      background-color: inherit;
      cursor: default;
    }

    small {
      border-color: var(--primary);
    }

    &:hover {
      color: var(--primary);
    }
  }
}
