@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.tickets-widget {
  margin-top: $box-sizing-xl;

  .ticket-search {
    margin-top: $box-sizing-regular;
    margin-bottom: $box-sizing-large;
    font-size: $ae-font-size-sm;

    &:focus,
    &:active {
      box-shadow: none !important;
    }
  }

  .pagination-bar {
    margin-top: $box-sizing-large;
  }

  & &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right,
    .left {
      display: flex;
      align-items: center;
    }

    .left {
      h4 {
        margin-bottom: 0;
      }
    }

    .right {
      justify-content: flex-start;
      padding: 0 $box-sizing-medium;

      &.searching {
        flex: 1 0 50%;

        .ticket-search-container {
          width: 100%;
          cursor: initial;

          .tickets-widget-search .input-container {
            min-width: 80%;
            width: 100%;

            input {
              min-width: 40px;
            }
          }
        }

        .buttons {
          width: 0;
          display: none;
        }
      }

      .ticket-search-container {
        cursor: pointer;

        .tickets-widget-search .input-container {
          min-width: 40px;
          width: 40px;
          border: none;

          input {
            min-width: 0px;
            line-height: 11px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        :hover {
          .icon {
            color: var(--gray-500);
          }
        }
      }

      .buttons {
        display: flex;
        align-items: center;
        margin-left: $box-sizing-medium;

        & > :not(:last-child) {
          margin-right: $box-sizing-medium;
        }
      }
    }
  }

  .ticket-count {
    cursor: default;
    padding: $box-sizing-regular $box-sizing-medium;
    background-color: var(--primary-100);
    color: var(--gray-600);
    font-size: $ae-font-size-xs;
    border-radius: $border-radius-rounded;
    margin-right: $box-sizing-medium;
  }
}
