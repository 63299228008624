@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.edge-page {
  .load-more {
    color: var(--gray-400);
    text-align: center;
    font-size: $ae-font-size-sm;
  }

  .page-content {
    background-color: var(--gray-000);

    .content {
      padding-left: 0;
      padding-right: 0;

      .container {
        @media (min-width: 1800px) {
          max-width: 1440px;
        }
      }
    }
  }

  pre {
    margin-bottom: 0;
    white-space: pre-wrap;
    font-family: $ae-font-family;
    overflow: hidden;
    word-break: break-word;
    font-size: $ae-font-size-sm;
  }

  .title-info {
    margin: $box-sizing-small 0 $box-sizing-medium $box-sizing-medium;
  }

  .info {
    display: flex;

    & > * {
      flex: 1;
    }

    & > :not(:last-child) {
      margin-right: $box-sizing-large;
    }
  }

  .view-previous {
    cursor: pointer;
    color: var(--gray-400);
    font-weight: $ae-font-weight-semi-bold;
    font-size: $ae-font-size-sm;
    margin-bottom: $box-sizing-xxl;
    width: fit-content;
  }

  .comments-widget {
    font-size: $ae-font-size-sm;
    word-break: break-word;

    .list {
      & > :not(:last-child) {
        margin-bottom: $box-sizing-xxl;
      }

      .text-editor {
        padding: 0;
        width: calc(100% - #{$box-sizing-view});
        overflow-x: hidden;

        .rendered {
          background-color: var(--gray-100);
          border: 1px solid var(--gray-100);

          &:hover {
            border-color: var(--gray-200);
          }
        }
      }

      .comment {
        display: flex;

        & > :not(:last-child) {
          margin-right: $box-sizing-large;
        }
        .comment-content {
          width: 100%;

          .comment-info {
            margin-bottom: $box-sizing-regular;
            justify-content: flex-start;

            .comment-username {
              font-weight: $ae-font-weight-semi-bold;
              color: var(--gray-800);
            }

            .comment-date {
              font-weight: $ae-font-weight-medium;
              color: var(--gray-500);
            }

            .comment-edited {
              color: var(--gray-400);
            }
          }

          .body {
            margin-bottom: $box-sizing-medium;
          }

          .markdown-content {
            padding: 0;
          }
        }

        .actions {
          color: var(--gray-500);

          & > :not(:last-child) {
            margin-right: $box-sizing-large;
          }

          & > * {
            cursor: pointer;
            transition: all 200ms;

            &:hover {
              color: var(--primary);
            }
          }
        }
      }

      .new-comment {
        display: flex;

        & > :not(:last-child) {
          margin-right: $box-sizing-large;
        }
      }
    }
  }

  .issues-widget {
    .header {
      .button {
        border: none;
        padding: $box-sizing-regular $box-sizing-medium;

        span {
          font-weight: $ae-font-weight-medium;
          font-size: $ae-font-size-xs;
        }
      }
    }
    .list {
      margin-bottom: $box-sizing-medium;
    }

    .new-issue {
      .text-input {
        margin-bottom: $box-sizing-medium;
      }

      .right {
        & > :not(:last-child) {
          margin-right: $box-sizing-medium;
        }
      }

      .input-container {
        margin-top: 0;
      }

      .unfocused {
        height: 50px;
        transition: all 200ms;
        background-color: var(--gray-100);
        padding: $box-sizing-large $box-sizing-medium;
        border-radius: $border-radius-standard;
        cursor: pointer;
        color: var(--gray-500);
        border: 1px solid var(--gray-100);
        line-height: initial;

        &:hover {
          border-color: var(--gray-200);
        }
      }
    }

    .issue {
      padding: $box-sizing-medium $box-sizing-large;

      &:hover {
        background-color: initial;
      }

      .main {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .details {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;

          .title {
            font-weight: $ae-font-weight-medium;
            color: var(--gray-700);
          }

          .name {
            margin-left: $box-sizing-medium;
            max-width: 220px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .more {
          display: flex;
          align-items: center;
          margin-left: $box-sizing-medium;

          > .status,
          .assignee {
            margin-left: $box-sizing-medium;
          }

          .assignee {
            margin-right: $box-sizing-medium;
          }

          .chip {
            font-size: $ae-font-size-sm;
            margin-right: $box-sizing-regular;
            font-weight: $ae-font-weight-light;
          }
        }
      }
    }
  }
}
