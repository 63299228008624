@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.edge-badges {
  display: flex;
  align-items: center;

  & > * {
    margin-right: $box-sizing-large;
  }
}
