@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_colors";

.settings {
  position: relative;
  font-family: Inter;
  font-weight: 500;

  .ai-setting-icon {
    svg {
      &:hover {
        path {
          stroke: $icon-hover;
        }
      }
    }
  }
  .settingsVisibility {
    svg {
      path {
        stroke: $icon-hover;
      }
    }
  }
  .settingsMenu {
    position: absolute;
    top: toRem(30px);
    right: toRem(-8px);
    background-color: white;
    width: max-content;
    z-index: 9999;
    padding-bottom: 8px;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-200, #eaecf0);
    background: var(--base-white, #fff);
    /* Shadow/lg */
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    &::before {
      content: "";
      position: absolute;
      top: -16px; /* Adjust this value to control the distance between the tooltip and the pointer */
      right: 6%;
      margin-right: -5px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent #eaecf0 transparent;
    }
    .ai-bot-without-circle-icon {
      svg {
        path {
          stroke-width: 0.8;
          fill: #344054;
        }
      }
    }
    .settingsTitle {
      display: flex;
      align-items: center;
      padding: toRem(8px) toRem(12px);
      font-weight: 600;
    }
    .menu-item {
      min-width: max-content;
      width: 100%;
      display: grid;
      padding: 0.6rem 1rem;
      padding-right: 1.5rem;
      align-items: center;
      gap: 0.5rem;
      grid-template-columns: 5% 75% 20%;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: rgba(128, 128, 128, 0.233);
      }
      .selectedMark {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: transparent;
        justify-self: flex-start;
      }
      .activeModalMark {
        background-color: #12b76a;
      }
    }
    .selectedModal {
      background-color: rgba(128, 128, 128, 0.233);
    }
  }

  .settingsMenuV1 {
    position: absolute;
    top: toRem(30px);
    right: toRem(-5px);

    &::before {
      border-color: transparent transparent $teal-lighter transparent;
    }
  }
}
