@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";

.cardHeading {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.articleTitle {
  .preview-highlight {
    font-style: normal;
    font-weight: $ae-font-weight-bold;
    background-color: $highlight-color;
    padding: 0rem 0.25rem;
  }

  .offset-highlight {
    font-style: normal;
    background-color: $highlight-color;
  }
}

.articleBody {
  padding: 0;

  p,
  h1,
  h2,
  h3,
  h4,
  h4,
  h5,
  h6 {
    &:last-child {
      margin: 0;
    }
  }
}

.articleTitle:hover {
  color: var(--teal, #06778c);
  cursor: pointer;
}
