.link.styled {
  color: var(--primary);

  &:hover {
    text-decoration: none;
    color: var(--primary-hover);
  }

  &.disabled {
    pointer-events: none;
  }
}
