@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_colors";

.issue-item {
  border: 1px solid $border-color;
  border-radius: $border-radius-rounded;
  padding: $box-sizing-large;

  &.clickable {
    h4:hover {
      color: var(--primary);
    }
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    min-width: initial;
    height: 24px;

    .issues-header-action-icons {
      margin-left: auto;
    }

    h4 {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 1px 0;
      color: var(--gray-900);
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-bold;
      margin-bottom: 0;
      overflow: hidden;
      height: 24px;

      .issue-icon {
        padding-left: 0;
        padding-top: 2px;
        margin-right: $box-sizing-medium;
        width: 1.2em;
        max-width: 1.2em;
      }

      .subject {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        .preview-highlight {
          font-style: normal;
          background-color: $highlight-color;
          padding: 0rem 0.25rem;
        }

        .offset-highlight {
          font-style: normal;
          background-color: $highlight-color;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      color: var(--gray-400);
      margin-left: $box-sizing-medium;
    }
  }

  .issue-description {
    margin-top: $box-sizing-large;
    color: var(--gray-600);
    overflow-y: auto;
    max-height: 5rem;
    word-break: break-word;
    font-size: $ae-font-size-sm;
    @include textEllipsis(2);
  }

  .issue-summary {
    margin-top: $box-sizing-medium;

    .text-muted {
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-regular;
      color: var(--gray-900);
      padding: 0;

      .placeholder {
        font-size: $ae-font-size-sm;
      }
    }
  }
  .issue_card_time {
    font-style: normal;
    font-size: toRem(13px);
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #88979b;
    font-family: "Inter";
    margin-top: toRem(8px);
  }
  .azureDevops-icon {
    width: toRem(19px);
    height: toRem(19px);
  }
}
