.edge-status-cell {
  &.new {
    --status-color: #c16b38;
    --status-icon-color: #ffd5bb;
  }
  &.review {
    --status-color: #2678a7;
    --status-icon-color: #d7effd;
  }
  &.confirmed {
    --status-color: #2b9550;
    --status-icon-color: #dff6e7;
  }
  &.escalated {
    --status-color: #c23b2a;
    --status-icon-color: #ffc3bc;
  }
  &.resolved {
    --status-color: var(--gray-700);
    --status-icon-color: var(--gray-300);
  }

  color: var(--status-color);

  & &-content {
    white-space: nowrap;
  }

  & &-dot {
    margin: auto 0;
    display: inline-block;
    background-color: var(--status-icon-color);
    content: "";
    min-height: 10px;
    min-width: 10px;
    border-radius: 100%;
  }

  & &-status {
    margin-left: 10px;
  }
}
