@import "@aptedge/lib-ui/src/styles/_boxes";

.edge-content {
  .id-section {
    display: flex;
    margin-bottom: $box-sizing-medium;
    height: 25px;
  }

  section {
    margin-top: $box-sizing-medium;
  }
}
