@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.ae-modal.add-ticket-modal {
  .list-title {
    font-size: $ae-font-size-sm;
    color: var(--gray-500);
    margin: $box-sizing-large $box-sizing-medium;
  }

  & &-modal-content {
    & > :not(:last-child) {
      margin-bottom: $box-sizing-xl;
    }
  }

  .with-loading {
    .fallback {
      top: 50px;
    }
  }

  .add-ticket-modal-content {
    border-top: 1px solid var(--gray-200);
    margin-bottom: $box-sizing-xl;
  }
}
