.dot {
  background-color: var(--gray-300);
  border-radius: 50%;
  display: inline-block;

  &.sm {
    height: 15px;
    width: 15px;
  }

  &.med {
    height: 25px;
    width: 25px;
  }

  &.primary {
    background-color: var(--primary);
  }

  &.danger {
    background-color: var(--danger);
  }
}
