.sectionLayout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 1rem;
  .details {
    .title {
      color: var(--Gray-900, #101828);
      /* Text lg/Semibold */
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
    .description {
      color: var(--Gray-600, #475467);
      /* Text sm/Regular */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      max-width: 80%;
    }
  }
}

.divider {
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  margin: 24px 0;
}
