@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_colors";

.answers-preview-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: calc(100vh - 6.5rem);
  border: 1px solid $border-color;
  border-radius: toRem(10px);
  margin-left: 1rem;
  height: max-content;
  position: sticky;
  position: -webkit-sticky;
  top: toRem(80px);

  ::-webkit-scrollbar {
    width: toRem(4px);
    height: toRem(4px);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--gray-500);
    border-radius: toRem(8px);
  }

  ::-webkit-scrollbar-track {
    background-color: $scroll-background-color;
    border-radius: toRem(8px);
    width: toRem(4px);
    height: toRem(4px);
  }

  .answer-preview-container {
    border-radius: 0.75rem 0.75rem 0rem 0rem;
    padding: 1rem;
    overflow-y: auto;
  }
}
