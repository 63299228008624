@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";
@import "@aptedge/lib-support-ui/src/styles/mixins.scss";

.container {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  display: block;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  outline: 0;
}

.contentContainer {
  position: relative;
  width: 66.25rem;
  max-width: 66.25rem;
  overflow-y: scroll;
  background: #fff;
  border-radius: $border-radius-rounded;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 1.75rem auto;
}

.articleSubHeading {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid $slate-20;
  color: $slate-60;
  font-size: $ae-font-size-medium;
  font-weight: $ae-font-weight-semi-bold;
  font-family: $ae-font-family-inter;

  .articleSubHeadingAction {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    .exportContainer {
      color: $slate-60;
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-regular;
      font-family: $ae-font-family-inter;
      padding: 0 0.25rem;
      position: relative;
      cursor: pointer;

      svg {
        font-size: $ae-font-size-reg;
      }
    }

    .exportDropdown {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 2.75rem;
      background: white;
      z-index: 1;
      border-radius: $border-radius-standard;
      padding: 0.5rem;
      right: 3.5rem;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      .exportItem {
        padding: 0.25rem;
        border-radius: $border-radius-standard;
        cursor: pointer;
        font-size: $ae-font-size-reg;
        font-weight: $ae-font-weight-regular;
        font-style: $ae-font-family-inter;

        &:hover {
          background: $slate-20;
        }
      }
    }
  }

  .clearIcon {
    font-size: 1.25rem;
    cursor: pointer;
    path {
      fill: $icon-default;
    }
  }
}

.articleHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    color: var(--Gray-2, #4f4f4f);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }

  button {
    background: #fff;
    border: none;
    color: #88979b;
  }
}

.articleContent {
  margin-top: 1.5rem;
}

.articleContentV2 {
  margin-top: 0;
  display: flex;
  height: calc(100vh - 11.25rem);
  overflow-y: auto;

  .title {
    color: $gray-2;
    font-family: $ae-font-family-inter;
    font-size: $ae-font-size-xl;
    font-style: $ae-font-style-normal;
    font-weight: $ae-font-weight-bold;
    line-height: 2rem;
    padding-bottom: 0.5rem;
  }
}

.articleContentWithoutActions {
  height: calc(100vh - 6.75rem);
}

.spinnerContainer {
  margin-top: 10rem;
}

.bodyLeftContainer {
  padding: 1rem 1rem 1rem 1.5rem;
  width: 100%;

  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .titleInputContainer {
      width: 100%;

      .textfield {
        resize: none;
        overflow: hidden;
        border: 1px solid $slate-30;
        border-radius: $border-radius-standard;
        color: $gray-2;
        font-family: $ae-font-family-inter;
        font-size: $ae-font-size-xl;
        font-style: $ae-font-style-normal;
        font-weight: $ae-font-weight-bold;
        line-height: 2rem;
        background: $slate-20;
      }
    }
  }

  .bodyTextareaContainer {
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    line-height: 1.5rem;
  }
  .bodyTextareaContainer:focus {
    outline: none;
  }
}

.articleStatusIcon {
  width: 1.125rem;
  height: 1.125rem;
  font-size: 1.125rem;
}

.bodyRightContainer {
  padding: 0rem 1.5rem 1rem 0;
  height: inherit;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.confirmationContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 30rem;
  width: 30rem;
  padding: 1rem;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-rounded;
  height: 10rem;
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .confirmationHeader {
    font-size: $ae-font-size-reg;
    font-weight: $ae-font-weight-semi-bold;
    font-family: $ae-font-family-inter;
    color: $gray-2;
  }

  .confirmationBody {
    font-size: $ae-font-size-reg;
    font-weight: $ae-font-weight-medium;
    font-family: $ae-font-family-inter;
    color: $gray-2;
  }

  .confirmationActions {
    display: flex;
    gap: 0.5rem;
    font-size: $ae-font-size-reg;
    font-weight: $ae-font-weight-medium;

    .confirm {
      background: $viking-1;
      color: $white;
      border-radius: $border-radius-standard;
      padding: 0.25rem 0.5rem;
      cursor: pointer;
    }

    .cancel {
      background: $white;
      border-radius: $border-radius-standard;
      border: 1px solid $gray-rd-200;
      padding: 0.25rem 0.5rem;
      cursor: pointer;
    }
  }
}
