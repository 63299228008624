@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";

.container {
  display: flex;
  height: toRem(44px);
  padding: toRem(10px) toRem(14px);
  align-items: center;
  align-self: stretch;
  gap: toRem(8px);
  border: 1px solid #f0efef;
  border-bottom: 0px;
  background: var(--Base-White, #fff);
  cursor: pointer;
}

.container:hover {
  background: $gray-rd-100;
}

.top {
  border-radius: 4px 4px 0px 0px;
}

.bottom {
  border-radius: 0px 0px 4px 4px;
  border-bottom: 1px solid #f0efef;
}

.filterIcon {
  color: #475467;
}

.filterName {
  color: #282a2d;
  font-family: Inter;
  font-size: toRem(14px);
  font-weight: $ae-font-weight-medium;
  line-height: toRem(24px);
}

.rulesDescription {
  margin-left: auto;
  color: #8895af;
  font-family: Inter;
  font-size: toRem(14px);
  font-weight: $ae-font-weight-regular;
  line-height: toRem(24px);
}
