@import "@aptedge/lib-ui/src/styles/_mixins";

.ticket-list-item {
  display: grid;
  grid-template-columns: 1fr toRem(60px) 2fr toRem(60px) repeat(3, 1fr) toRem(
      40px
    );
  gap: 0 toRem(12px);
  align-items: center;
  justify-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: toRem(8px) toRem(12px);
  margin-bottom: toRem(8px);
  border-radius: toRem(4px);
  cursor: pointer;
  .requested-by {
    text-align: left;
    @include textEllipsis(2);
  }
  .external-id {
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-align: center;
  }
  .title {
    text-align: center;
    @include textEllipsis(2);
  }
  .priority {
    & > * {
      border-radius: toRem(4px);
      width: max-content;
      padding: toRem(4px) toRem(6px);
      text-transform: uppercase;
      font-weight: bold;
      font-size: x-small;
      background-color: #f4f8f9;
      color: #88979d;
      width: 100%;
      word-wrap: break-word;
      overflow-wrap: break-word;
      text-align: center;
    }
    .low {
      background-color: #f4f8f9;
      color: #88979d;
    }
    .normal,
    .medium {
      background-color: #fff7ea;
      color: #ffab00;
    }
    .high {
      color: #d7828f;
      background-color: #ffeaef;
    }
    .urgent {
      color: rgb(245, 93, 31, 0.7);
      background-color: rgb(245, 93, 31, 0.2);
    }
  }
  .frustration {
    padding: toRem(4px) toRem(8px);
    background-color: #d7828f;
    border-radius: toRem(4px);
    color: white;
  }
  .no-frustration {
    background-color: #a5d48e;
  }
  .status {
    & > * {
      border-radius: toRem(4px);
      width: max-content;
      padding: toRem(4px) toRem(6px);
      text-transform: uppercase;
      font-weight: bold;
      font-size: x-small;
      background-color: #f4f8f9;
      color: #88979d;
    }
    .open {
      background-color: #f0fbee;
      color: #a5d48e;
      text-transform: capitalize;
    }
    .pending,
    .hold {
      background-color: #fff7ea;
      color: #ffab00;
    }
    .closed {
      background-color: #f4f8f9;
      color: #88979d;
    }
  }
  .themes {
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    & > * {
      margin: toRem(2px);
    }
  }
  .edit-themes {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
