@import "@aptedge/lib-ui/src/styles/_boxes";

.edge-updated-on-cell {
  > * {
    margin-bottom: $box-sizing-regular;
  }

  & &-time {
    color: var(--gray-600);
  }
}
