@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";

.text-area.reply {
  margin-bottom: $box-sizing-large;

  .text-area-container {
    margin: 0;
    border: none;
    border-radius: $border-radius-rounded;
    padding: $box-sizing-large $box-sizing-xxl;
    background-color: var(--gray-100);
  }

  &.internal {
    .text-area-container {
      background-color: var(--yellow-100);
    }
  }

  &.isDisabled {
    .text-area-container {
      background-color: $gray-100;
    }

    textarea {
      color: $gray-300 !important;
    }
  }
}
