.container {
  margin-left: auto;
  align-self: start;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 12px 16px;
  border-radius: 4px;
  background: #fff;
  gap: 8px;

  p {
    margin: 0;
    color: #4f4f4f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 24px; /* 150% */
  }

  .ticketIcon {
    margin-top: -1px;
  }

  .ticketId {
    font-weight: 400;
  }

  .ticketTitle {
    font-weight: 500;
  }
}
