@import "@aptedge/lib-ui/src/styles/_mixins.scss";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";

.container {
  display: flex;
  padding: 0 toRem(16px);
  gap: toRem(24px);
}

.navigationContainer {
  width: toRem(204px);
  flex-shrink: 0;
}

.contentContainer {
  width: toRem(700px);
  flex-shrink: 0;
}

.ticketFieldSettingsIcon {
  display: flex;
  padding: 0 0.25rem;
}

.divider {
  margin: 0.3rem 0 -0.3rem 0;
  height: 1px;
  width: 12.5rem;
  background: $slate-20;
  margin: 1rem 0;
}

.navigationItemcontainer {
  padding: toRem(8px) toRem(12px);
  width: toRem(200px);
  display: flex;
  align-items: center;
  gap: toRem(8px);
  color: $gray-rd-900;
}

.navigationItemcontainer:hover {
  color: $gray-rd-900;
}

.active {
  color: $viking-1;
  background-color: $teal-lighter;
  border-radius: toRem(6px);
}

.active:hover {
  color: $viking-1;
  background-color: $teal-lighter;
}

.icon {
  font-size: toRem(14px);
  line-height: toRem(20px);
}

.label {
  font-family: $ae-font-family-inter;
  font-size: toRem(14px);
  font-weight: $ae-font-weight-semi-bold;
  line-height: toRem(20px);
}
