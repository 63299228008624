@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";
.container {
  .queryHeader {
    color: $viking-90;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    padding-bottom: 1rem;
  }
  .loaderWrapper {
    margin: 0.87rem 0.5rem;
  }

  hr {
    width: 0;
    margin-top: 0;
  }

  .questionQueryTitle {
    font-size: 1.125rem;
    margin-bottom: 1rem;

    @include textEllipsis(2);
  }
  .aiAnswerContainer > :not(:first-child) {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }
}
