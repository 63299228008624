@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";
@import "@aptedge/lib-support-ui/src/styles/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: $border-radius-rounded $border-radius-rounded 0 0;
}

.containerForAgent {
  border-radius: $border-radius-rounded;
}

.articleSubHeading {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid $slate-20;
  color: $slate-60;
  font-size: $ae-font-size-medium;
  font-weight: $ae-font-weight-semi-bold;
  font-family: $ae-font-family-inter;

  .exportContainer {
    color: $slate-60;
    font-size: $ae-font-size-reg;
    font-weight: $ae-font-weight-regular;
    font-family: $ae-font-family-inter;
    padding: 0 0.25rem;
    position: relative;
    cursor: pointer;

    svg {
      font-size: $ae-font-size-reg;
    }
  }

  .exportDropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 5.25rem;
    background: white;
    z-index: 1;
    border-radius: $border-radius-standard;
    padding: 0.5rem;
    right: 2.25rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .exportItem {
      padding: 0.25rem;
      border-radius: $border-radius-standard;
      cursor: pointer;
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-regular;
      font-style: $ae-font-family-inter;
      &:hover {
        background: $slate-20;
      }
    }
  }
}

.articleCount {
  margin-left: $box-sizing-regular;
  color: $gray-1;
  font-family: $ae-font-family-inter;
  font-size: $ae-font-size-medium;
  font-style: $ae-font-style-normal;
  font-weight: $ae-font-weight-regular;
  line-height: 1.5rem;
  margin-bottom: 0;
  margin: 3rem 0 1rem 0;
}

.articleContainer {
  display: flex;
  padding: 0 1.5rem;
}

.articleCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  gap: $box-sizing-input;
  border-right: 1px solid $slate-20;
  padding: 1rem 1rem 1rem 0;
  width: 100%;
}

.articleStatusButton {
  padding: 0.25rem 0.75rem;
  border-radius: $box-sizing-view;
  background: $slate-20;
  border: 1px solid $slate-60;
  color: $slate-60;
  font-size: $ae-font-size-xs;
  font-weight: $ae-font-weight-medium;
  font-family: $ae-font-family-inter;
  margin-right: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  position: absolute;
  right: 0;
}

.draftArticle {
  border: 1px solid $viking-80;
  color: $viking-80;
  background-color: $viking-40;
}

.inReviewArticle {
  border: 1px solid $orange-80;
  color: $orange-80;
  background-color: $orange-40;
}

.articleContent {
  width: 100%;
  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
      color: $gray-2;
      font-family: $ae-font-family-inter;
      font-size: $ae-font-size-xl;
      font-style: $ae-font-style-normal;
      font-weight: $ae-font-weight-bold;
      line-height: 2rem;
      padding-bottom: 0.5rem;
    }

    .titleInputContainer {
      width: 100%;

      .textfield {
        resize: none;
        overflow: hidden;
        border: 1px solid $slate-30;
        border-radius: $border-radius-standard;
        color: $gray-2;
        font-family: $ae-font-family-inter;
        font-size: $ae-font-size-xl;
        font-style: $ae-font-style-normal;
        font-weight: $ae-font-weight-bold;
        line-height: 2rem;
        background: $slate-20;
      }
    }
  }

  .inputActions {
    display: flex;
    padding-top: 0.5rem;
    font-size: $ae-font-size-reg;
    font-weight: $ae-font-weight-medium;
    font-family: $ae-font-family-inter;

    .saveBtn {
      padding: 0.25rem 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      background: $viking-70;
      color: $white;
      border-radius: $border-radius-standard;
      margin-right: 0.5rem;
      cursor: pointer;

      svg {
        font-size: 1rem;
      }
    }

    .cancelBtn {
      padding: 0.25rem 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      border-radius: $border-radius-standard;
      color: $slate-80;
      background: $slate-20;
      cursor: pointer;

      svg {
        font-size: 1rem;
      }
    }
  }

  .bodyTextareaContainer {
    margin-top: 1rem;
    textarea {
      width: 100%;
      height: 29rem;
      border: none;
      color: $gray-2;
      font-family: $ae-font-family-inter;
      font-size: $ae-font-size-medium;
      font-style: $ae-font-style-normal;
      font-weight: $ae-font-weight-regular;
      line-height: 1.5rem;
      resize: none;
      border: 1px solid $slate-30;
      border-radius: $border-radius-standard;
      background: $slate-20;
    }
    textarea:focus {
      outline: none;
    }
  }

  h1 {
    color: $text-color;
    font-family: $ae-font-family-inter;
    font-size: $ae-font-size-xl;
    font-style: $ae-font-style-normal;
    font-weight: $ae-font-weight-bold;
    line-height: $box-sizing-input;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $text-color;
    font-family: $ae-font-family-inter;
    font-size: $ae-font-size-lg;
    font-style: $ae-font-style-normal;
    font-weight: $ae-font-weight-semi-bold;
    line-height: 1.5rem;
  }

  p,
  ol,
  ul {
    color: $text-color;
    font-family: $ae-font-family-inter;
    font-size: $ae-font-size-medium;
    font-style: $ae-font-style-normal;
    font-weight: $ae-font-weight-regular;
    line-height: 1.5rem;
  }
}
