@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_mixins";

.search-list {
  .back-button {
    width: fit-content;
    font-weight: $ae-font-weight-semi-bold;
    color: var(--gray-500);
    cursor: pointer;

    &:hover {
      color: var(--gray-700);
    }
  }

  .search-results {
    margin-top: 0.5rem;
  }

  .result {
    // `position: relative` is necessary for the "Add to current Edge" overlay to be positioned
    // correctly
    position: relative;
    margin: 0.5rem 0;
    font-family: $ae-font-family-inter;
    user-select: none;
    padding: 0.8rem;
    border-radius: 6px;
    transition: all 0.2s;
    color: var(--gray-800);
    border: 1px solid var(--gray-300);

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: var(--gray-100);
    }

    .info {
      font-size: 0.7rem;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: 0.01em;
      color: var(--gray-500);
      font-family: $ae-font-family-inter;
      margin-top: toRem(8px);
    }
  }
  .result:first-child {
    margin-top: 0;
  }
  .error-container {
    display: grid;
    justify-items: center;
    margin-top: 2rem;
    text-align: center;
    color: rgb(125, 125, 125);
    font-size: 1rem;
    .error-text {
      margin-top: 1rem;
    }
    .error-restart {
      color: #06778c;
      margin-top: 1rem;
      border: none;
      background: none;
      outline: none;
    }
  }
  .no_recent_searches {
    display: grid;
    justify-items: center;
    align-items: center;
    margin: 2rem 1rem;
    .desc {
      text-align: center;
      margin-top: 1.5rem;
    }
  }

  .create-ticket-button {
    margin: 16px auto auto auto;
  }
}
