@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  display: flex;
  margin-bottom: 0.25rem;
}

.label {
  color: $gray-rd-400;
  font-family: $ae-font-family-inter;
  font-weight: $ae-font-weight-regular;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.25rem;
  padding-left: 0.125rem;
  width: 25%;

  &:last-child {
    width: 50%;
  }
}
