@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";

.pagination-analytics {
  font-size: $ae-font-size-sm;
  color: $gray-600;
  font-weight: $ae-font-weight-semi-bold;
  font-family: $ae-font-family-inter;
}
