@import "../../styles/boxes";
@import "../../styles/typography";

.markdown-editor {
  background-color: var(--gray-000);
  border-radius: $border-radius-rounded;
  border: 1px solid var(--gray-300);
  padding: $box-sizing-large;
  padding-top: $box-sizing-medium;

  .markdown-toolbar {
    margin-bottom: $box-sizing-medium;
  }

  .preview {
    cursor: text;
  }

  &:focus,
  &:focus-within {
    border-color: var(--primary);
  }

  .CodeMirror {
    color: var(--gray-900);
    padding: $box-sizing-medium;
    background-color: transparent;
    font-family: inherit !important;
    height: auto;

    .CodeMirror-cursor {
      border-left: 1px solid var(--gray-900);
    }

    .CodeMirror-selected {
      background-color: highlight !important;
    }

    .CodeMirror-scroll {
      min-height: 250px;
      overflow-y: hidden;
      overflow-x: auto;
    }

    .cm-link {
      text-decoration: none !important;
    }

    .cm-url {
      color: var(--primary);
    }

    .cm-header-1 {
      font-size: $ae-font-size-title;
      font-weight: $ae-font-weight-regular;
    }

    .cm-header-2 {
      font-size: $ae-font-size-xl;
      font-weight: $ae-font-weight-semi-bold;
    }

    .cm-header-3 {
      font-size: $ae-font-size-lg;
      font-weight: $ae-font-weight-semi-bold;
    }

    .cm-header-4 {
      font-size: $ae-font-size-medium;
      font-weight: $ae-font-weight-semi-bold;
    }

    .cm-header-5,
    .cm-header-6 {
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-semi-bold;
    }
  }
}
