@import "@aptedge/lib-ui/src/styles/_typography";

.edge-id-cell {
  & &-content {
    flex: 0 0 75px;
    font-weight: $ae-font-weight-bold;
    white-space: nowrap;
    font-size: 14px;
  }

  .auto-link-badge {
    height: 14px;
    width: 14px;

    .bolt-icon {
      font-size: 11px;
      line-height: 1;
    }
  }
}
