@import "../../styles/boxes";
@import "../../styles/typography";
@import "../../styles/_mixins";

.filter-wrapper {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: none;
  align-items: center;
  border: 1px solid var(--gray-300);
  border-radius: $border-radius-rounded;
  color: var(--gray-800);
  background-color: var(--gray-000);
  font-size: $ae-font-size-sm;
  margin-top: $box-sizing-regular;
  margin-bottom: $box-sizing-regular;
  min-height: toRem(42px);

  > * {
    padding-left: $box-sizing-medium;
    padding-right: $box-sizing-medium;
  }

  > .label {
    flex: 1 0 auto;
    align-self: stretch;
    justify-self: flex-start;
    display: flex;
    align-items: center;
    user-select: none;
    border-top-left-radius: $border-radius-rounded;
    border-bottom-left-radius: $border-radius-rounded;
    border-right: 1px solid var(--gray-300);
    padding-right: toRem(12px);
    background-color: var(--gray-100);
    color: var(--gray-800);
    font-weight: $ae-font-weight-medium;
    font-size: $ae-font-size-sm;

    .icon {
      margin-right: 0;
    }
  }

  > .operator {
    flex: 1 0 auto;
    align-self: stretch;
    justify-self: flex-start;
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
    border-right: 1px solid var(--gray-300);
    min-width: toRem(16px);
    background-color: var(--gray-100);
    color: var(--gray-800);
    font-weight: $ae-font-weight-medium;

    > .dropdown > .toggle {
      padding: toRem(4px) toRem(12px);
    }
  }

  .filter {
    flex: 1 1 auto;
    padding-top: 0;
    padding-bottom: 0;

    .chip {
      border: none;
      padding: $box-sizing-small $box-sizing-medium;

      .times-circle-icon {
        font-size: $ae-font-size-xs;
        color: var(--gray-500);

        &:hover {
          color: var(--gray-600);
        }
      }
    }

    .select {
      min-width: 0;
    }

    .input-container {
      min-width: toRem(140px);
      background-color: var(--gray-000);
      margin: 0;
      padding: 0;
      border: none;

      input {
        width: fit-content;
      }
    }
  }

  > .end-cap {
    flex: 1 1 auto;
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-self: flex-start;
    cursor: pointer;
    justify-self: flex-end;
    border-top-right-radius: $border-radius-standard;
    border-bottom-right-radius: $border-radius-standard;
    font-weight: $ae-font-weight-light;
    font-size: toRem(10px);
  }

  &:not(.error) {
    &.active,
    &:focus,
    &:focus-within {
      border-color: var(--primary-hover);

      > .label,
      > .operator {
        border-color: var(--primary-hover);
        background-color: var(--primary-faded);
      }

      > .end-cap {
        color: var(--primary);
        border-color: var(--primary-hover);
      }
    }

    > .end-cap {
      &:hover {
        color: var(--primary);
      }
    }

    &:hover {
      border-color: var(--primary-hover);

      > .label,
      > .end-cap,
      > .operator {
        border-color: var(--primary-hover);
      }
    }
  }

  &.error {
    border-color: var(--danger);

    > * {
      border-color: var(--danger);
    }

    > .label {
      background-color: var(--danger-faded);
    }

    > .end-cap {
      color: var(--danger);

      &:hover {
        background-color: var(--danger-faded);
      }
      &:active {
        background-color: var(--danger-faded);
      }
    }
  }
}

.time-dimension {
  position: relative;
  .time-dimension-modal {
    background-color: white;
    z-index: 9999;
    position: absolute;
    padding: 1rem;
    box-shadow: 0px 0px toRem(40px) rgba(0, 0, 0, 0.15);
    border-radius: toRem(8px);
    margin-top: toRem(8px);
    .createdOn {
      padding-bottom: 1rem;
      border-bottom: 1px solid #f3f5f5;
      margin-bottom: 1rem;
    }

    .createdOn,
    .updatedOn {
      cursor: pointer;

      &:hover {
        color: #06758c;
        svg {
          filter: invert(23%) sepia(73%) saturate(2835%) hue-rotate(171deg)
            brightness(95%) contrast(40%);
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: toRem(-16px);
      left: 70%;
      margin-left: toRem(-5px);
      border-width: toRem(8px);
      border-style: solid;
      border-color: white transparent transparent transparent;
      box-shadow: 0px 0px toRem(40px) rgba(0, 0, 0, 0.15);
      transform: rotate(180deg);
    }
  }
}
