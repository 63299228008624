@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=block");

$ae-font-size-mini: 10px;
$ae-font-size-xs: 12px;
$ae-font-size-sm: 13px;
$ae-font-size-reg: 14px;
$ae-font-size-medium: 16px;
$ae-font-size-lg: 18px;
$ae-font-size-xl: 24px;
$ae-font-size-title: 32px;
$ae-font-size-support-app: 0.85rem;
$ae-font-size-support-app-xs: 0.9rem;

$ae-font-family-inter: "Inter";
$ae-font-family: $ae-font-family-inter;

$ae-font-weight-light: 300;
$ae-font-weight-regular: 400;
$ae-font-weight-medium: 500;
$ae-font-weight-semi-bold: 600;
$ae-font-weight-bold: 700;

$ae-font-line-height-normal: normal;

$ae-font-style-inherit: inherit;
$ae-font-style-normal: normal;
$ae-font-style-italic: italic;

$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;

$primary-font-family: $ae-font-family-inter;
$primary-border-radius: 8px;
$primary-font-size: 1rem;
