:root {
  @each $color, $value in $grays {
    --gray-#{$color}: #{$value};
  }

  --light: #{$light-bg};
  --primary-100: #{$primary-100};
  --primary-300: #{$primary-300};
  --primary-500: #{$primary-500};
  --primary-hover: #{$primary-hover};
  --primary-faded: #{$primary-faded};
  --danger-hover: #{$danger-hover};

  --brand-primary: #{$brand-primary};
  --light-blue: #{$light-blue};
  --light-orange: #{$light-orange};
  --warning: #{$warning};
  --warning-hover: #{$warning-hover};
  --danger-faded: #{$danger-faded};
  --shadow: #{$shadow-light};
  --light-overlay: #{$white-faded};
  --dark-overlay: #{$dark-faded};

  --royal-blue: #{$royal-blue};
  --royal-blue-100: #{$royal-blue-100};
  --royal-blue-300: #{$royal-blue-300};
  --royal-blue-500: #{$royal-blue-500};
  --blue-600: #1570ef;

  --yellow: #{$yellow};
  --yellow-100: #{$yellow-100};
  --yellow-300: #{$yellow-300};
  --yellow-500: #{$yellow-500};
}

:root {
  body.dark-mode {
    --primary-faded: #{$primary-faded-dark};
    --shadow: #{$shadow-dark};
    --light-overlay: #{$dark-faded};
    --dark-overlay: rgba(7, 4, 4, 0.6);

    --light: #{$gray-900};

    // Let's try just inverting the bootstrap grayscale.
    --gray-000: #{$gray-111};
    --gray-100: #{$gray-900};
    --gray-200: #{$gray-800};
    --gray-300: #{$gray-700};
    --gray-400: #{$gray-600};
    --gray-500: #{$gray-500};
    --gray-600: #{$gray-400};
    --gray-700: #{$gray-300};
    --gray-800: #{$gray-200};
    --gray-900: #{$gray-100};
    --gray-111: #{$gray-000};
    --blue-600: #1570ef;
  }
}
