@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.copy-btn-container {
  .aptedge-copy-button {
    padding: 0;
    border: none;
    background-color: transparent;
    &:focus {
      outline: none;
    }

    path {
      stroke: $icon-default;
    }

    &:hover {
      path {
        stroke: $icon-hover;
      }
    }

    .copied-text {
      border: 1px solid var(--teal);
      color: var(--teal);
      font-weight: 500;
      border-radius: toRem(8px) !important;
      font-size: toRem(12px);
      padding: toRem(4px) toRem(8px);
      background-color: white !important;
    }
    .icon {
      background: unset;
    }
  }
}
