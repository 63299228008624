.audit-log-wrapper {
  font-size: 13px;
  margin-bottom: 24px;

  .audit-avatar {
    width: 40px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    padding: 2px;
  }

  .audit-content {
    width: 100%;

    .audit-header {
      .age {
        color: hsl(0, 0, 50);
        font-weight: 500;
        font-size: 12px;
        margin-left: 8px;
      }

      .audit-summary {
        .subject {
          color: hsl(0, 0, 20);
          font-weight: 600;
        }
      }
    }
  }

  .audit-body {
    margin-top: 8px;
    padding: 2px;
    word-break: break-word;

    .markdown-content {
      padding: 0 !important;

      &.deleted {
        text-decoration: line-through;
        text-decoration-color: black;
      }
    }
  }
}
