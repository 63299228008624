@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";
@import "@aptedge/lib-support-ui/src/styles/mixins.scss";

.container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 32rem 1fr 1fr 1fr 6rem;
  padding: 1rem;
  background: $white;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1),
    0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  min-width: toRem(1000px);
}

.containerV2 {
  box-shadow: none;
  grid-template-columns: 28rem 0.75fr 0.75fr 0.75fr 1.25fr 1.25fr;
}

.headerItem {
  color: $slate-50;
  font-family: $ae-font-family-inter;
  font-size: 1rem;
  font-style: $ae-font-style-normal;
  font-weight: $ae-font-weight-medium;
  line-height: 1.5rem; /* 150% */
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: 8rem;
  flex-shrink: 0;
}
