@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.searchContainer {
  padding: 1rem;
  background: $viking-90;
  padding-top: 0;
  .title {
    font-family: "DM Sans";
    font-size: 2rem;
    font-style: normal;
    font-weight: 900;
    line-height: 2.25rem; /* 112.5% */
    letter-spacing: -0.04rem;
    color: $viking-20;
  }
  .searchInputWrapper {
    position: relative;
    .arrowRightIcon {
      position: absolute;
      right: 0.5rem;
      bottom: 1.8rem;
      cursor: pointer;
      transition: color 100ms linear;
      &:hover {
        color: $viking-40;
      }
    }
    .disabled {
      color: $slate-40;
    }
    .textArea {
      padding: 0.75rem;
      border-radius: $primary-border-radius;
      width: 100%;
      outline: none;
      border: none;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      resize: none;
      font-size: $primary-font-size;

      &::placeholder {
        color: $slate-60;

        font-family: $ae-font-family-inter;
        font-size: $primary-font-size;
        font-style: normal;
        font-weight: 600;
        padding-left: 0.13rem;
      }
    }
  }
  .suggestionsContainer {
    p {
      margin-bottom: 0.5rem;
      color: $slate-70;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }
    .suggestions {
      padding-left: 0;

      > * {
        position: relative;

        animation: fade-in 0.5s, settle 0.5s;
        animation-fill-mode: forwards;
        opacity: 0;

        @keyframes fade-in {
          from {
            opacity: 0;
          }
          to {
            opacity: 100;
          }
        }

        @keyframes settle {
          from {
            right: -10px;
          }
          to {
            right: 0;
          }
        }
      }

      li {
        list-style: none;
        color: $slate-10;
        font-family: Inter;
        font-size: $primary-font-size;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        padding: 0.25rem 0.5rem;
        margin-bottom: 0.25rem;
        cursor: pointer;
        display: grid;
        grid-template-columns: 1fr 1rem;
        justify-content: space-between;
        align-items: center;
        .icon {
          display: none;
        }
        &:hover {
          border-radius: 0.5rem;
          background: rgba(0, 0, 0, 0.3);
          :global(.ae-icon) {
            display: block;
          }
          .icon {
            display: block;
          }
        }
        :global(.ae-icon) {
          display: none;
          font-size: 2rem;
        }
      }
    }
  }
}
