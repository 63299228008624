@import "../../styles/boxes";
@import "../../styles/typography";

.select {
  min-width: 100px;
  width: 100%;
  position: relative;

  .text-input {
    line-height: 32px;

    &:read-only {
      cursor: pointer;

      input {
        cursor: pointer;
      }
    }

    .input-container {
      border-color: var(--gray-300);
      border-radius: $border-radius-rounded;
      background-color: var(--gray-000);

      .indicator-container,
      .indicator-container:hover,
      .indicator-container:focus,
      .indicator-container .icon {
        background-color: initial;
        color: var(--primary) !important;
      }
    }
  }

  .popper {
    .select-content {
      min-width: 100%;
      width: fit-content;

      z-index: $z-index-popper;
      font-size: $ae-font-size-reg;
      background-color: var(--gray-000);
      border: 1px solid var(--gray-200);
      border-radius: $border-radius-standard;
      box-shadow: $box-shadow-default;
      padding: 0 $box-sizing-large;
      max-height: 250px;
      overflow-y: auto;

      .option {
        background-color: var(--gray-000);
        padding: $box-sizing-medium $box-sizing-large;
        margin-right: -$box-sizing-large;
        margin-left: -$box-sizing-large;

        &:not(.loading) {
          cursor: pointer;
        }

        &.clear {
          font-style: italic;
        }

        &.selected {
          background-color: var(--primary);
          color: var(--gray-000);
        }

        &.selected.highlighted {
          background-color: var(--primary-hover);
        }

        &:hover {
          background-color: var(--primary-faded);
        }

        &:not(.selected).highlighted {
          background-color: var(--primary-faded);
        }
      }

      .no-match {
        padding: $box-sizing-medium
          calc(#{$box-sizing-large} - #{$box-sizing-medium});
      }
    }
  }
}
