@import "@aptedge/lib-ui/src/styles/_boxes";

.ticket-themes-modal {
  .info {
    color: var(--gray-700);
  }

  .actions {
    .right {
      & > :not(:last-child) {
        margin-right: $box-sizing-medium;
      }
    }
  }

  .multi-select.themes {
    .chip {
      color: var(--gray-000);
      background-color: var(--gray-700);
    }
    .icon {
      color: var(--gray-000);

      &:hover {
        color: var(--gray-500);
      }
    }
  }
}
