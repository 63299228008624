@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.filter-bar-container {
  .edge-tickets.filter-bar {
    margin-bottom: $box-sizing-large;

    .header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 40px;

      .filter-wrapper {
        flex: 1;

        .filter {
          width: 100%;

          .input-container {
            width: 100%;
          }
        }
      }

      & > * {
        margin: $box-sizing-medium;
      }
    }

    .filter-row {
      margin: $box-sizing-medium 0;
    }
  }
}

.auto-secondary-button {
  color: var(--primary);
  text-decoration: underline;
  font-weight: $ae-font-weight-bold;
  font-size: $ae-font-size-xs;
  align-self: center;
  padding: $box-sizing-regular;
  cursor: pointer;

  &:hover {
    color: var(--primary-hover);
  }
}
