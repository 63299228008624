@import "../../styles/boxes";
@import "../../styles/typography";

.pill {
  min-width: 30px;
  width: fit-content;
  display: inline-block;
  font-size: $ae-font-size-reg;
  color: var(--gray-900);
  border: 1px solid var(--gray-300);
  background-color: var(--gray-000);
  border-radius: $border-radius-standard;
  padding: $box-sizing-regular $box-sizing-medium;

  &.hoverable:hover {
    cursor: pointer;
    background-color: var(--gray-100);
  }
}
