@import "../../../styles/mixins.scss";

.filterContainer {
  .filter {
    display: flex;
    align-items: center;
  }
}
.dropdownItemContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .dropdownItem {
    img {
      height: 1.3rem;
      width: 1.3rem;
      margin-right: 0.5rem;
    }
  }
  .dropdownCheck {
    margin-left: 1rem;
  }
}

.activeIcon {
  svg {
    filter: invert(23%) sepia(73%) saturate(2835%) hue-rotate(171deg)
      brightness(95%) contrast(40%);
  }
}
