@import "../../styles/boxes";
@import "../../styles/typography";

.markdown-toolbar {
  padding: $box-sizing-small $box-sizing-regular;

  .dropdown {
    color: var(--gray-900);

    .toggle {
      width: fit-content;
      min-width: 140px;
      margin-right: $box-sizing-regular;
      font-size: $ae-font-size-reg;
      padding: $box-sizing-regular $box-sizing-large;
      border-radius: $border-radius-standard;
      &:hover {
        background-color: var(--gray-100);
      }
    }
    &.open {
      .toggle,
      .toggle:hover {
        background-color: var(--gray-800);
        color: var(--gray-000);
      }
    }
  }

  .btn.ae-button {
    background-color: transparent;
    border: none;
    color: var(--gray-600);

    &:hover {
      background-color: var(--gray-100);
      box-shadow: none;
    }

    &.active:not(.disabled) {
      color: var(--gray-000);
      background-color: var(--gray-800);
    }
  }

  .popper .content {
    max-height: fit-content !important;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0;
      padding: 0;
      color: inherit;
    }
    p {
      padding: $box-sizing-regular;
    }
    .option {
      min-height: 32px;
      &.active {
        background-color: var(--gray-800);
        color: var(--gray-000);
      }
    }
  }
}
