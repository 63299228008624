@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_boxes";

.containerBackground {
  background-color: #f2f9fa;
  min-height: calc(100vh - $box-sizing-card);
  padding: $box-sizing-xxl 0;
}

.containerContent {
  max-width: 66.25rem;
  margin: 0 auto;
}

.containerContentV2 {
  max-width: 72rem;
}

.containerArticles {
  position: relative;
}

.articlePrepareloader {
  min-height: calc(100vh - $box-sizing-card);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.articlePagination {
  margin-top: 1rem;
}
