@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.ae-modal.merge-edge {
  .input-container {
    margin-top: $box-sizing-large;
  }

  h5 {
    color: var(--gray-800);
  }

  .disclaimer {
    color: var(--gray-400);
  }

  .merge-edge-content {
    min-height: 450px;

    .edges-selected,
    .edges-results,
    .spinner {
      margin-top: $box-sizing-xxl;
    }

    .edges-selected,
    .edges-results {
      > h5 {
        text-transform: uppercase;
        margin-left: $box-sizing-regular;
        color: var(--gray-400);
        font-size: $ae-font-size-mini;
      }
    }

    &.loading-screen {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ae-modal-footer {
    color: var(--gray-500);
    font-size: $ae-font-size-sm;
    border-top: 1px solid var(--gray-100);
    padding: $box-sizing-xl $box-sizing-xxxl;
    margin-bottom: -$box-sizing-xl;
    margin-right: -$box-sizing-xxxl;
    margin-left: -$box-sizing-xxxl;
  }
}
