@import "@aptedge/lib-ui/src/styles/_boxes";

.ticket-listing-page.page-content {
  background-color: var(--gray-000);

  > .content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .sort {
    margin-left: $box-sizing-large;
  }
}
