.status-button {
  padding: 20px;
  border: 1px solid hsl(0, 0, 95);

  .icons {
    .retry {
      border-radius: 4px;
      box-shadow: none !important;
      transition: visibility 50ms;
      border: none;
      background-color: white;
      color: var(--warning-hover);
    }
    .btn {
      border-radius: 4px;
      box-shadow: none !important;
      transition: visibility 50ms;
      visibility: hidden;
      border: none;
      background-color: white;
      color: hsl(0, 0, 50);

      &:hover {
        background-color: hsl(0, 0, 98);
        color: hsl(0, 0, 40);

        &.delete {
          color: red;
        }
      }

      &:active {
        background-color: hsl(0, 0, 97);
      }
    }
  }

  &:hover {
    .icons .btn {
      visibility: visible;
    }
  }
}
