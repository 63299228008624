@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "../../styles/mixins.scss";

.global-search {
  .input-container {
    border-color: var(--gray-100);

    .indicator-container {
      .search-icon {
        color: var(--primary);

        :hover {
          color: var(--primary);
        }
      }
    }
  }
  .skeleton-loaders {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .loader {
      margin: 1rem;
      margin-bottom: 0;
      flex-grow: 1;
    }
  }
  .search-tabs {
    display: flex;
    margin-top: $box-sizing-medium;
    position: relative;
    .arrow {
      display: flex;
      align-items: center;
      color: var(--gray-500);
      font-size: large;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: white;
      position: absolute;
      top: 0;
      bottom: 0;
      height: 40px;
      padding: 0 16px;
      border-radius: 50%;
      margin-top: auto;
      margin-bottom: auto;
      &:hover {
        color: var(--primary);
      }
    }
    .left {
      position: absolute;
      left: toRem(4px);
      height: 26px;
      padding: 0 10px;
      border-radius: 50%;
      z-index: 8;
      text-align: center;
      background-color: white;
    }
    .right {
      position: absolute;
      right: toRem(4px);
      height: 26px;
      padding: 0 10px;
      border-radius: 50%;
      background-color: white;
      z-index: 8;
    }
    .scroll-container {
      display: flex;
      justify-content: space-between;
      width: 90%;
      position: relative;
      transition: all 0.5s ease-in;
      transform: translateX(0px);
      z-index: 7;
    }
    .divider-vertical {
      min-width: 2px;
      margin: 0.5rem 4px;
      background-color: var(--gray-300);
      border-radius: 4px;
    }
    .filter {
      display: grid;
      padding: toRem(4px) toRem(8px);
      width: max-content;
      .activeIcon {
        svg {
          filter: invert(23%) sepia(73%) saturate(2835%) hue-rotate(171deg)
            brightness(95%) contrast(40%);
        }
      }
    }
    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }
    .all {
      padding: toRem(4px) toRem(8px);
    }

    .ae-button {
      padding: 0 $box-sizing-regular;
      background-color: inherit;
      color: var(--gray-500);

      &:hover {
        box-shadow: none;
        color: var(--gray-600);
        background-color: inherit;
      }

      &.active {
        color: var(--primary);
        background-color: var(--gray-100);
      }

      &.disabled {
        background-color: initial;
        color: var(--gray-300);

        :hover {
          color: inherit;
        }
      }
    }
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .disable-filter {
    pointer-events: none;
  }
  .dropdown-filter-wrapper {
    margin: auto 0;
  }

  .suggested-answer-container {
    margin-top: 0.5rem;
  }

  .search-results-feedback {
    position: absolute;
    right: 4em;
    padding-top: 2px;
  }

  .align-feedback {
    position: absolute;
    right: 2em;
    padding-top: 2px;
  }

  .action-container {
    position: relative;
    width: 100%;
    margin-top: 0.5rem;
  }
}
