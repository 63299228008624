@import "@aptedge/lib-ui/src/styles/_colors";

.notification {
  display: flex;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  .audit-content {
    .audit-header {
      .audit-summary {
        flex-shrink: 1;
        > span {
          color: hsl(0, 0, 40) !important;
          font-size: 14px !important;
          font-weight: 600 !important;
        }
      }
      .audit-age {
        flex-shrink: 0;
        .age {
          font-size: 12px;
          font-weight: 500;
          color: hsl(0, 0, 60);
        }
      }
    }
    .notification-display-text {
      color: hsl(0, 0, 60);
      margin-top: 6px;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .unread-dot {
    align-self: start;
    margin-left: 8px;
    padding: 4px;
    border-radius: 100%;
    cursor: pointer;
    background-color: hsl(0, 0, 95);

    .dot {
      display: block;
      border-radius: 100%;
      content: "";
      min-width: 10px;
      min-height: 10px;
      background-color: hsl(0, 0, 90);
    }

    &:hover {
      background-color: hsl(0, 0, 90);
      .dot {
        background-color: adjust-color($color: $primary, $lightness: 20%);
      }
    }

    &:active {
      background-color: hsl(0, 0, 85);
    }

    &.unread {
      .dot {
        background-color: $primary;
      }
    }
  }

  &:hover {
    background-color: hsl(0, 0, 98);
    box-shadow: 0 2px 4px 0px hsla(0, 0, 90, 50);
  }
}
