@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.message {
  margin: 0;
  padding: 0;
  color: $gray-rd-400;
  font-family: $ae-font-family-inter;
  font-size: 0.875rem;
  font-style: $ae-font-style-normal;
  font-weight: $ae-font-weight-light;
  line-height: 1rem;
}

.rulesBadgeContainer {
  display: flex;
  padding: 0.125rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 2.5rem;
  background: $teal;
  color: $white;
  font-family: $ae-font-family-inter;
  font-size: 0.875rem;
  font-style: $ae-font-style-normal;
  font-weight: $ae-font-weight-regular;
  line-height: 1.5rem;
}

.hiddenBadgeContainer {
  display: flex;
  padding: 0.125rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 2.5rem;
  background: $gray-blue;
  color: $white;
  font-family: $ae-font-family-inter;
  font-size: 0.875rem;
  font-style: $ae-font-style-normal;
  font-weight: $ae-font-weight-regular;
  line-height: 1.5rem;

  .hiddenIcon {
    font-size: $ae-font-size-reg;
  }
}

.hiddenTextContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $gray-blue;
  font-family: $ae-font-family-inter;
  font-size: 1rem;
  font-style: $ae-font-style-normal;
  font-weight: $ae-font-weight-regular;
  line-height: 1.5rem;
}
