@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.edge-list-item {
  &.linked,
  &.expanded {
    border-color: var(--primary);
  }

  &.archived {
    background-color: var(--gray-100);
    border-color: var(--gray-500);
    color: var(--gray-700);
  }
  &.selectable {
    cursor: pointer;

    .actions {
      display: block;
      color: var(--gray-500);
    }
  }

  .body-row {
    a {
      text-decoration: none;
      word-wrap: break-word;
      color: var(--gray-800);
      font-weight: $ae-font-weight-medium;

      &:hover {
        color: var(--primary);
      }
    }

    .preview-highlight {
      font-weight: $ae-font-weight-bold;
      font-style: normal;
      background-color: $highlight-color;
      padding: 0rem 0.25rem;
    }

    .offset-highlight {
      font-style: normal;
      background-color: $highlight-color;
    }
  }

  .edge-icon {
    margin-right: $box-sizing-medium;
    padding-left: 0;
  }

  .ticket-count {
    font-size: $ae-font-size-xs;
    display: inline-block;
    color: var(--gray-600);
    margin-right: $box-sizing-medium;
  }

  .edge-description-container {
    margin-top: $box-sizing-xl;

    .markdown-content {
      font-size: $ae-font-size-sm;
      padding: $box-sizing-medium 0;
    }

    .placeholder {
      color: var(--gray-400);
      text-align: center;
      font-weight: $ae-font-weight-light;
    }
  }

  .edge-links-container {
    margin: $box-sizing-large;
  }

  .edge-tickets-container {
    margin-bottom: $box-sizing-large;

    .ticket-list-item {
      border: none;
      border-radius: 0;
      margin: 0;
      padding: 0.5rem;

      &:nth-child(odd) {
        background-color: var(--primary-100);

        .ae-badge {
          background-color: var(--gray-000);
        }
      }
    }
  }

  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    .dot {
      display: inline-block;
      border-radius: 50%;
      width: toRem(8px);
      height: toRem(8px);
      background-color: var(--status-background);
    }
    .status-text {
      font-family: $ae-font-family-inter;
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 130%;
      letter-spacing: 0.01em;
      color: var(--status-orange);
      padding-left: toRem(8px);
    }
  }
  &:hover {
    color: var(--gray-600);
    background-color: var(--gray-100);
  }

  &:focus {
    outline: none;
  }
  .edge-info-section {
    padding-bottom: 0.625rem;
    border-bottom: 1px dashed var(--teal);
  }
  .link-edge-icon {
    background: var(--primary-100);
    border-radius: toRem(30px);
    padding: 0 0.5rem;
    align-items: center;
    cursor: pointer;
    .link-edge {
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 130%;
      letter-spacing: 0.01em;
      color: var(--teal);
      font-family: $ae-font-family-inter;
    }

    .icon {
      padding: 0 0.25rem 0 0;
    }

    &:hover {
      background-color: var(--gray-200);
      color: var(--gray-600);
    }

    &.linked {
      background-color: var(--primary-faded);
      color: var(--primary);

      &:hover {
        background-color: var(--gray-200);
        color: var(--gray-600);
      }
    }
  }
}
