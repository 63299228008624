.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  margin-top: 4rem;
}

.headline {
  color: var(--Gray-2, #4f4f4f);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  margin: 0;
}
.subheading {
  color: var(--Gray-2, #4f4f4f);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin: 0;
}
