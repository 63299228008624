@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_mixins";

.container {
  border-radius: $primary-border-radius;
  background: $viking-10;
  height: 100%;
  box-shadow: -4px 0px 36px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  .stickyHeader {
    position: sticky;
    top: 0;
    padding: 1rem;
    color: $viking-20;
    background-color: #123236;
    z-index: 1000;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in 0.1s;
    &.shrunk {
      padding: 0.5rem 1rem;
      .title {
        font-size: 1rem;
      }
    }
    .title {
      font-family: "DM Sans";
      font-size: 2rem;
      font-style: normal;
      font-weight: 900;
      line-height: 2.25rem;
      letter-spacing: -0.04rem;
      transition: all 0.3s ease-in 0.1s;
      @include textEllipsis(2);
    }
  }
  .loaderContainer {
    margin: 1rem 0;
  }
  .contents {
    padding: 1rem;
    display: grid;
    gap: 1rem;
    background: $viking-10;
    position: relative;

    > * {
      position: relative;

      animation: fade-in 0.5s, settle 0.5s;
      animation-fill-mode: forwards;
      opacity: 0;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}n) {
          animation-delay: #{(($i)-1) * 0.15}s;
        }
      }

      @keyframes fade-in {
        from {
          opacity: 0;
        }
        to {
          opacity: 100;
        }
      }

      @keyframes settle {
        from {
          right: -10px;
        }
        to {
          right: 0;
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
      gap: 0 0.5rem;
      margin-bottom: 1rem;
      h6 {
        margin-bottom: 0;
        color: $slate-60;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
      }
    }
    .card {
      border-radius: $primary-border-radius;
      cursor: pointer;
      &:hover {
        background-color: $slate-00;
        .cardItem {
          background-color: $slate-00;
        }
      }
      .resultTitle {
        margin-bottom: 0.25rem;
        color: $slate-90;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
      }
      .title {
        color: $slate-90;
        font-family: $ae-font-family-inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
      }
      .cardItem {
        padding: 0.5rem;
        .title {
          h6 {
            margin-left: 0;
          }
        }
        &:hover {
          background-color: $slate-10;
        }
      }
    }
  }
  .resourceItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    :global(.ae-icon) {
      color: $slate-40;
    }
  }
}
