@import "@aptedge/lib-ui/src/styles/_boxes";

.filter-bar {
  border-color: transparent !important;
}

.conditions-wrapper {
  border: 1px solid var(--primary);
  border-radius: $border-radius-rounded;
  margin-bottom: $box-sizing-xl;
  .create-filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 0;
  }
  h5 {
    margin-bottom: 0;
  }

  .filter-row {
    display: grid;
    grid-template-columns: 80% 20%;
    padding: $box-sizing-large;
    .filter-wrapper {
      grid-column-start: 1;
      .filter {
        width: 100%;
      }
    }
    .filter-select {
      grid-column-start: 2;
    }
  }
}
