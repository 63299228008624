@use "sass:math";

// Makes color utility classes for a given color key (e.g. "primary", "gray-100", etc.)
@mixin make-color-utils($color, $variable-prefix: "") {
  $varname: #{$variable-prefix}#{$color};

  // Basic text colors: text-primary, text-gray-100, etc.
  .text-#{$varname} {
    color: var(--#{$varname}) !important;
  }

  // Basic border colors: border-primary, border-gray-100, etc.
  .border-#{$varname} {
    border-color: var(--#{$varname}) !important;
  }

  // Basic background colors: bg-primary, bg-gray-100, etc.
  .bg-#{$varname} {
    background-color: var(--#{$varname}) !important;
  }

  // .hover:text-primary, .hover:text-secondary, .hover:text-gray-100, etc.
  .hover\:text-#{$varname} {
    &:hover {
      color: var(--#{$varname}) !important;
    }
  }

  // .hover:bg-primary, .hover:bg-secondary, etc.
  .hover\:bg-#{$varname} {
    &:hover {
      background-color: var(--#{$varname}) !important;
    }
  }
}

@function toRem($size) {
  $remSize: math.div($size, 16px);
  @return #{$remSize}rem;
}

@mixin textEllipsis(
  $line: 1,
  $width: 100%,
  $textAlign: left,
  $line-height: 140%,
  $word-break: break-word
) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: $line-height;
  word-break: $word-break;
  width: $width;
  @supports (-webkit-line-clamp: $line) {
    text-align: $textAlign;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    width: $width;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    word-break: $word-break;
  }
}

@mixin onSupportApp {
  @media (max-width: 499px) {
    @content;
  }
}
