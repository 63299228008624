@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.edge-tickets-list {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: $box-sizing-xl;
  }

  .ticket-item {
    .remove-icon {
      padding: $box-sizing-medium;
      border-radius: $border-radius-standard;

      &:hover {
        color: var(--danger);
        background-color: var(--gray-100);
      }
    }
  }
}
