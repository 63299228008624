@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  border-radius: toRem(8px);
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 848px;
  padding: toRem(64px);
  gap: toRem(24px);
  font-family: Inter;
}

.filterIcon {
  width: toRem(64px);
  height: toRem(64px);
  color: #dddddd;
}

.header {
  color: #333;
  text-align: center;
  font-size: toRem(24px);
  font-weight: $ae-font-weight-medium;
  line-height: toRem(32px);
}

.description {
  color: #333;
  font-size: toRem(16px);
  font-weight: $ae-font-weight-regular;
  line-height: toRem(24px);
}

.createFilterButton {
  display: flex;
  padding: toRem(10px) toRem(18px);
  justify-content: center;
  align-items: center;
  gap: toRem(8px);
  border-radius: toRem(8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);
  font-size: toRem(16px);
  font-weight: $ae-font-weight-semi-bold;
  line-height: toRem(24px);
}
