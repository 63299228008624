.group:hover {
  @each $key, $hex in $theme-colors {
    .group-hover-#{$key} {
      color: lighten($hex, 10);
    }
  }

  .group-hover-visible {
    visibility: visible !important;
  }

  // Allow for group-d-none or group-d-block visibility toggle.
  @each $value in $displays {
    .group-d-#{$value} {
      display: $value !important;
    }
  }
}

@each $color, $value in $colors {
  @include make-color-utils($color);
}

@each $color, $value in $theme-colors {
  @include make-color-utils($color);
}

@each $color, $value in $grays {
  @include make-color-utils($color, "gray-");
}

.cursor-pointer {
  cursor: pointer;
}
