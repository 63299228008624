@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_mixins";

.container {
  h1 {
    color: #4f4f4f;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #4f4f4f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }

  p,
  ol,
  ul {
    color: #4f4f4f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  code {
    background-color: $gray-600;
    border-radius: 0.4rem;
    color: $white;
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: $true-black;
    border-radius: 0.5rem;
    color: $white;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;
    white-space: normal;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: toRem(3px) solid $slate-60;
    padding-left: 1rem;
  }
}

.previewLink {
  color: $linkColor;
  cursor: pointer;
}
