.container {
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ticketsTableContainer {
  margin-top: -24px;
}
