@import "../../styles/boxes";
@import "../../styles/typography";

.text-area {
  line-height: $box-sizing-input;
  min-width: 100px;
  width: 100%;
  position: relative;

  .text-area-container {
    display: inline-flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border: 1px solid;
    /* Large top margin to allow for absolutely positioned label -- see below. */
    margin: $box-sizing-xxxl auto 0;
    padding: $box-sizing-medium;
    background-color: var(--gray-000);
    border-color: var(--gray-300);
    border-radius: $border-radius-standard;

    /* Label is inside container so that we can style it when focused. */
    label {
      /* Use absolute positioning to move it up. */
      position: absolute;
      left: 0;
      top: -$box-sizing-xl;
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-medium;
      padding: 0 $box-sizing-regular;
    }

    textarea {
      width: 0;
      border: none;
      outline: none;
      padding: 0;
      min-width: 33px;
      flex-grow: 1;
      background-color: inherit;
      font-size: $ae-font-size-reg;
      line-height: $box-sizing-input;
      min-height: $box-sizing-input;

      &::placeholder {
        color: var(--gray-400);
        font-weight: $ae-font-weight-light;
      }
    }

    &.resizable {
      textarea {
        resize: "vertical";
      }
    }

    &:focus,
    &:focus-within {
      background-color: var(--gray-000);
      border-color: var(--primary);

      .close-icon {
        color: var(--gray-700);
      }

      label {
        color: var(--primary);
      }
    }

    /* Icon for error state */
    .alert-icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
    }
  }

  &.error {
    .text-area-container {
      border-color: var(--danger);

      label {
        color: var(--danger);
      }
    }
  }

  .feedback {
    margin-top: $box-sizing-small;
    margin-left: $box-sizing-regular;
  }
}
