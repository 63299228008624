@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.integrationInfo {
  display: flex;
  align-items: center;
}

.integrationInfo p {
  margin-top: 0;
}

.iconContainer {
  width: 1.5rem;
  height: 1.5rem;

  img {
    width: 1rem;
    height: 1rem;
  }
}

.integrationName {
  margin: 0;
  padding: 0;
  color: $gray-1;
  font-family: $ae-font-family-inter;
  font-size: 1rem;
  font-style: $ae-font-style-normal;
  font-weight: $ae-font-weight-medium;
  line-height: 1.5rem;
}

.controlsContainer {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.chevronIcon {
  color: $gray-rd-400;
  margin: 0;
  padding: 0;
  margin-top: 0.125rem;
}
