.action-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: max-content;
  width: 100%;

  > .left,
  > .right,
  > .center {
    display: flex;
    align-items: center;
    vertical-align: center;

    flex: 1 1 auto;
  }

  > .left {
    margin-right: 40px;
    > * {
      margin-right: 4;
    }
  }

  > .right {
    justify-content: right;

    > * {
      margin-right: 4;
    }
  }

  > .center {
    justify-content: center;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
  }
}
