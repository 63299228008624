@import "../../styles/boxes";

.list-item {
  display: block;
  outline: none;
  width: 100%;
  background-color: var(--gray-000);
  padding: $box-sizing-large;
  border: 1px solid var(--gray-300);
  line-height: $box-sizing-large;
  color: var(--gray-900);

  &:hover {
    text-decoration: none !important;
    background-color: var(--gray-100);
    color: var(--gray-800);
  }
}
