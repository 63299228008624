@import "../../styles/boxes";
@import "../../styles/typography";

.filter-bar {
  .create-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $box-sizing-large $box-sizing-xl;

    .text-input {
      width: 200px;

      .input-container {
        border: none;
        padding-top: 0;
        padding-bottom: 0;
      }

      .feedback {
        position: absolute;
      }
    }
  }

  .filter-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: $box-sizing-regular $box-sizing-medium;

    > :not(:last-child) {
      margin-right: $box-sizing-xxl;
    }

    .filter-select {
      .popper .content {
        max-height: fit-content;
      }
    }

    .ae-button {
      border-radius: $border-radius-rounded;
      background-color: var(--gray-100);
      padding: 6px 12px;
      color: var(--gray-800);
      font-size: $ae-font-size-sm;
      font-weight: $ae-font-weight-medium;
      margin-top: $box-sizing-regular;
      margin-bottom: $box-sizing-regular;

      &:hover {
        box-shadow: none;
      }

      > * {
        > span {
          padding: $box-sizing-regular;
        }
      }
    }
  }

  &.editing {
    border: 1px solid var(--gray-300);
    border-radius: $border-radius-rounded;
  }
}
