@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.link-edge-with-description {
  .markdown-editor {
    .CodeMirror.CodeMirror-wrap {
      padding: 0px;
      .CodeMirror-scroll {
        min-height: 100px;
      }
    }
  }

  .feedback {
    margin-top: $box-sizing-regular;
    font-size: $ae-font-size-mini;
  }
}
