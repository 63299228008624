@import "../../styles/boxes";

.info-button {
  display: inline;
  margin-left: $box-sizing-regular;

  &:hover {
    cursor: pointer;
  }
}
