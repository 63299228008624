.arrow-transition {
  padding: 0 !important;

  .from {
    color: hsl(0, 0, 60);
  }

  .to {
    color: hsl(0, 0, 20);
  }
}
