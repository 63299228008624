@import "../../styles/boxes";
@import "../../styles/typography";

.dropdown {
  .toggle {
    cursor: pointer;
    transition: all 200ms;

    &:focus,
    &:focus-within {
      outline: none;
    }
  }

  .popper {
    z-index: $z-index-popper;

    .content {
      width: 100%;
      font-size: $ae-font-size-reg;
      background-color: var(--gray-000);
      border: 1px solid var(--gray-200);
      border-radius: $border-radius-standard;
      box-shadow: $box-shadow-hover;
      max-height: 250px;
      width: max-content;
      height: fit-content;
      overflow-y: auto;
      overflow-x: visible;
      padding-top: $box-sizing-medium;
      padding-bottom: $box-sizing-medium;

      .option {
        cursor: pointer;
        background-color: transparent;
        padding: $box-sizing-medium $box-sizing-large;
        transition: all 200ms;

        &:hover {
          background-color: var(--gray-200);
        }
      }
    }
  }
}
