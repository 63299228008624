@import "@aptedge/lib-ui/src/styles/_boxes";

.auto-reply-wrapper {
  border: 1px solid var(--primary);
  border-radius: $border-radius-rounded;
  padding: $box-sizing-xl;
  margin-bottom: $box-sizing-xl;
}

.text-area.reply {
  margin-bottom: 0;
}
