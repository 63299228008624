@import "colors";

$box-sizing-small: 2px;
$box-sizing-regular: 4px;
$box-sizing-medium: 8px;
$box-sizing-large: 16px;
$box-sizing-xl: 20px;
$box-sizing-xxl: 24px;
$box-sizing-input: 32px;
$box-sizing-xxxl: 36px;
$box-sizing-view: 40px;
$box-sizing-nav-bar: 60px;
$box-sizing-card: 64px;
$box-sizing-secondary-bar: 76px;

$box-shadow-default: 4px 4px 8px 0 var(--shadow);
$box-shadow-navbar: 0px 2px 4px 0 var(--shadow);
$box-shadow-hover: 4px 4px 8px 0 var(--shadow);
$box-shadow-card: 0px 4px 8px 0 var(--shadow);

$box-shadow-xs: 0 0 4px 1px var(--shadow);
$box-shadow-s: 0px 2px 6px 3px var(--shadow);
$box-shadow-m: 0px 2px 8px 2px rgba(53, 61, 63, 0.1);

$border-radius-standard: 4px;
$border-radius-rounded: 8px;
$border-radius-pill: 40px;
$border-radius-circle: 50%;

$z-index-secondary-bar: 899;
$z-index-navigation-bar: 900;
$z-index-popper: 1000;
$z-index-tooltip: 1051;
$z-index-overlay: 1052;
