.progressBar {
  border-radius: 17px;
  height: 1rem;
  background: var(--Viking-Vikinig-00, #f4fafb);
  border: 1px solid var(--Viking-Viking-70, #2a767e);
  .progressBarFiller {
    height: 100%;

    border-radius: 20px 0px 0px 20px;
    background: var(--Viking-Viking-40, #71c7d0);
  }
  .completedFillter {
    background: var(--Red-Red-60, #dc2626);
    border: none;
    border-radius: 16px;
    height: 1rem;
    width: 100%;
  }
}
.completedProgressBar {
  border: none;
}
