.template-item {
  .add-template-bar,
  .template-status-bar {
    display: flex;
    padding: 20px;
    border-radius: 4px;
    align-items: center;
  }

  .add-template-bar {
    > div {
      padding: 6px;
    }

    cursor: pointer;
    border-radius: 4px;
    background-color: hsl(0, 0, 98);
    color: hsl(0, 0, 40);

    &:hover {
      background-color: hsl(0, 0, 97);
      color: hsl(0, 0, 30);
    }
  }

  .template-status-bar {
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid hsl(0, 0, 95);
  }

  .icons {
    .btn {
      border-radius: 4px;
      box-shadow: none !important;
      transition: visibility 50ms;
      visibility: hidden;
      border: none;
      background-color: white;
      color: hsl(0, 0, 50);

      &:hover {
        background-color: hsl(0, 0, 98);
        color: hsl(0, 0, 40);

        &.delete {
          color: red;
        }
      }

      &:active {
        background-color: hsl(0, 0, 97);
      }
    }
  }

  &:hover {
    .icons .btn {
      visibility: visible;
    }
  }
}
