@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: $slate-60;
  font-size: $ae-font-size-medium;
  font-family: $ae-font-family-inter;
  font-weight: $ae-font-weight-regular;
  position: relative;
  cursor: pointer;
  width: fit-content;
  gap: 0.5rem;
}

.reviewArticleButton {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  gap: 0.25rem;
  border-radius: $border-radius-standard;
  background-color: $gray-100;

  svg {
    path {
      fill: $viking-50;
    }
  }
}

.articleFilterButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
  gap: 0.25rem;
  border-radius: $border-radius-standard;
  background-color: $gray-100;
  svg {
    path {
      stroke: $slate-60;
      stroke-width: 0.016rem;
    }
  }

  .filterAppliedIndicator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: $border-radius-rounded;
    background-color: $red;
    padding: 0.25rem;
    margin-left: 0.25rem;
  }

  &:hover {
    filter: brightness(80%);
  }
}

.filterOptionContainer {
  position: absolute;
  background: $white;
  right: 0;
  border-radius: $border-radius-standard;
  top: 4rem;
  padding: 1rem;
  width: 17rem;
  box-shadow: $box-shadow-default;
  z-index: 1;

  .filterHeader {
    display: flex;
    justify-content: space-between;
    font-size: $ae-font-size-xs;
    font-weight: $ae-font-weight-medium;
    font-family: $ae-font-family-inter;
    padding-bottom: 1rem;

    .filterClear {
      cursor: pointer;

      svg {
        font-size: 1.125rem;
        margin-right: 0.5rem;
        path {
          fill: $slate-60;
        }
      }
    }
  }

  .heading {
    color: $slate-60;
    font-size: $ae-font-size-xs;
    font-weight: $ae-font-weight-medium;
    font-family: $ae-font-family-inter;
  }

  .articleStatusButtonContainer {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.5rem;

    .articleStatusButton {
      padding: 0.25rem 0.75rem;
      border-radius: $box-sizing-view;
      background: $slate-20;
      border: 1px solid $slate-60;
      color: $slate-60;
      font-size: $ae-font-size-xs;
      font-weight: $ae-font-weight-medium;
      font-family: $ae-font-family-inter;
      margin-right: 0.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.25rem;

      &:nth-child(-n + 2) {
        margin-bottom: 0.5rem;
      }
    }

    .articleStatusSelected {
      border: 1px solid $viking-80;
      color: $viking-80;
      background-color: $viking-50;
    }
  }

  .articleStatusIcon {
    width: 1.125rem;
    height: 1.125rem;
    font-size: 1.125rem;
  }

  .userSearchContainer {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
  }
}
