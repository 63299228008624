@import "../../styles/boxes";
@import "../../styles/colors";
@import "../../styles/typography";

.button-group {
  display: flex;
  flex-direction: row;
  height: fit-content;
  min-height: $box-sizing-input;

  & > * {
    font-size: $ae-font-size-reg;
    background-clip: border-box;
    transition: all 200ms;
    background-color: var(--gray-100);
    border-color: var(--gray-200);
    border-radius: 0;
    border-left-width: 0;
    border-right-width: 0;

    svg {
      transition-duration: 0s;
    }
  }

  & > .active:not(.disabled) {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--white);

    &:hover {
      background-color: var(--primary-hover);
      border-color: var(--primary-hover);
    }

    &.right {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    & + .active {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  & > :first-child {
    border-left-width: 1px;
    border-top-left-radius: $border-radius-standard;
    border-bottom-left-radius: $border-radius-standard;
    &:not(.active) {
      border-left-color: var(--gray-200);
    }
  }

  & > :last-child {
    border-right-width: 1px;
    border-top-right-radius: $border-radius-standard;
    border-bottom-right-radius: $border-radius-standard;
    &:not(.active) {
      border-right-color: var(--gray-200);
    }
  }
}
