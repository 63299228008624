@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_mixins";

.search-result-loader {
  margin: $box-sizing-medium 0;
  padding: $box-sizing-large;
  border-radius: $border-radius-rounded;
  border: 1px solid $gray-200;

  &.active {
    background-color: var(--gray-100);
  }

  &:focus {
    outline: none;
  }

  .search-header {
    display: flex;
    flex-direction: row;
    height: toRem(30px);
    margin-bottom: toRem(5px);

    .search-title {
      display: flex;
      width: 80%;

      &.align-title {
        width: 100%;
      }
    }

    &.support-app-header {
      height: toRem(40px);
    }

    .search-action {
      display: flex;
      flex-direction: row;
      justify-content: right;
      width: 20%;
    }
  }

  .skeleton-body {
    margin-left: toRem(5px);
  }
}
