.container {
  position: fixed;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-content: center;
  top: 0;
  left: 0;
}

.contentContainer {
  position: relative;
  top: 10rem;
  width: 100%;
  max-width: 1060px;
  min-height: 560px;
  height: fit-content;
  background: #fff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 18px 18px 0px 18px;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    color: var(--Gray-2, #88979b);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }

  button {
    background: #fff;
    border: none;
    color: #88979b;
  }
}

.articleEditingContainer {
  margin-top: 1.5rem;
}

.spinnerContainer {
  margin-top: 10rem;
}

.titleInputContainer {
  input {
    width: 100%;
    border: none;
    background: #fff;
    color: var(--Gray-2, #4f4f4f);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }

  input:focus {
    outline: none;
  }
}

.bodyTextareaContainer {
  margin-top: 1rem;
  textarea {
    width: 100%;
    height: 400px;
    border: none;
    color: #4f4f4f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  textarea:focus {
    outline: none;
  }
}

.actionsContainer {
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  margin-left: -18px;
  margin-right: -18px;
  margin-top: 2rem;
  padding: 1rem 18px;
  display: flex;
  gap: 8px;
  button {
    display: flex;
    padding: 4px 17px;
    align-items: center;
    border-radius: 4px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    span {
      padding: 0;
    }
  }
  .discardButton {
    margin-right: 8px;
    gap: 9px;
    border: 1px solid #ddd;
    background: #fff;
    color: #969696;
  }
  .saveArticleButton {
    gap: 9px;
    background: #06758c;
    color: #fff;
    border: none;
  }
}
