.progress-bar {
  width: 100%;
  background-color: var(--primary-300);
  border-radius: 6px;

  .bar {
    background-color: var(--primary);
    border-radius: 6px;
  }
}
