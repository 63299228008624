@import "@aptedge/lib-ui/src/styles/mixins";

.container {
  position: relative;
  width: 100%;
  height: 100%;
  .message {
    margin: 0 1.9rem;
    margin-top: 0.25rem;
    height: 73%;
    @include textEllipsis(10, 85%);
  }
  .title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .iconGradient {
      // color: transparent;
      display: inline-block;
    }
    .text {
      color: #060b26;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem; /* 171.429% */
      letter-spacing: -0.0175rem;
      @include textEllipsis(2, 85%);
    }
  }
  .blurGradient {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) -66.67%,
      #fff 83.33%
    );
    height: 25%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.message {
  position: relative;
  min-height: 120px;
}

.transitionItem {
  width: 100%;
  will-change: transform, opacity;
}
