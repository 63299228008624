@import "@aptedge/lib-ui/src/styles/_boxes";

.with-loading {
  position: relative;

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--light-overlay);
    z-index: $z-index-overlay;
    margin: auto;
    vertical-align: middle;

    .fallback {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
