.container {
  margin-top: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.heading {
  color: #4f4f4f;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  margin: 0;
}

.subheading {
  color: #4f4f4f;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin: 0;
}

.articlesLink {
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;

  span {
    padding: 0;
  }
}
