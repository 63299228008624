@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.container input[type="checkbox"] {
  accent-color: $teal;
  width: 1rem;
  height: 1rem;
  border-radius: 0.125rem;
  border: 1px solid $gray-blue;
  background: $white;
  cursor: pointer;
}

.container label {
  color: $gray-rd-800;
  text-align: right;
  font-family: $ae-font-family-inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: $ae-font-weight-light;
  line-height: 1.25rem;
  margin: 0;
  cursor: pointer;
}
