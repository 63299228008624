@import "@aptedge/lib-ui/src/styles/_animations";

tr {
  &.clickable {
    cursor: pointer;

    &:hover {
      td {
        background-color: var(--gray-100) !important;
      }
    }
  }

  &.loading {
    pointer-events: none;

    td {
      * {
        color: transparent !important;
        border-color: transparent !important;
        background-color: transparent !important;
      }

      > * {
        background: linear-gradient(
          to right,
          var(--gray-100) 20%,
          var(--gray-200) 50%,
          var(--gray-100) 80%
        );
        background-size: 500px 100px;
        animation-name: wave;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
      }
    }
  }
}
