@import "../../styles/boxes";
@import "../../styles/typography";

.calendar {
  width: fit-content;
  user-select: none;
  padding: $box-sizing-large;
  background-color: var(--gray-000);

  header {
    display: inline-flex;
    color: var(--gray-700);

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--gray-000);
      font-size: $ae-font-size-sm;
      padding: $box-sizing-regular $box-sizing-medium;
      min-width: 32px;
    }
  }
}
