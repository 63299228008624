@import "../../styles/boxes";
@import "../../styles/typography";

.sentiment-bar {
  margin-top: $box-sizing-medium;

  .bar-container {
    width: 100%;
    padding-top: $box-sizing-medium;
    display: flex;

    .bar {
      height: 6px;
      min-width: 100%;
      position: relative;
      background: linear-gradient(to right, var(--light-orange), var(--danger));

      .sentiment-score {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        box-shadow: $box-shadow-xs;
      }
    }
  }

  .labels {
    .label {
      font-size: $ae-font-size-sm;
      font-weight: $ae-font-weight-light;
      color: var(--gray-400);
    }
  }
}
