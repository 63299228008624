@import "../../styles/boxes";

.spacer {
  --size: 0px;

  &.small {
    --size: #{$box-sizing-small};
  }
  &.regular {
    --size: #{$box-sizing-regular};
  }
  &.medium {
    --size: #{$box-sizing-medium};
  }
  &.large {
    --size: #{$box-sizing-large};
  }
  &.xl {
    --size: #{$box-sizing-xl};
  }
  &.xxl {
    --size: #{$box-sizing-xxl};
  }
  &.xxxl {
    --size: #{$box-sizing-xxxl};
  }

  &.flex {
    display: flex;
    flex-direction: column;

    &.spacer-row {
      flex-direction: row;

      &:not(.surround) {
        & > :not(:last-child) {
          margin-right: var(--size);
        }
      }
    }
  }

  &:not(.spacer-row):not(.surround) {
    & > :not(:last-child) {
      margin-bottom: var(--size);
    }
  }

  &.surround {
    & > * {
      margin: var(--size);
    }
  }
}
