@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.issue-item {
  &.clickable {
    h4:hover {
      color: var(--primary);
    }
  }

  .heading {
    display: flex;
    align-items: baseline;
    min-width: initial;

    .issue-icon {
      padding-left: 0;
      padding-top: 2px;
      margin-right: $box-sizing-medium;
      width: 1.2em;
      max-width: 1.2em;
    }

    .subject {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .issue-summary {
    .text-muted {
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-regular;
      color: var(--gray-900);
      padding: 0;
      padding-top: 1em;

      .placeholder {
        font-size: $ae-font-size-sm;
        word-break: break-all;
      }
    }
  }
  &:hover {
    color: var(--gray-600);
    background-color: var(--gray-100);
  }

  &:focus {
    outline: none;
  }
  .info {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(13px);
    line-height: 130%;
    letter-spacing: 0.01em;
    color: var(--gray-500);
    font-family: $ae-font-family-inter;
    margin-top: toRem(8px);
  }
  .azureDevops-icon {
    height: 1rem;
    width: 1rem;
    margin-bottom: toRem(6px);
  }
  .issues-icon svg {
    height: 1rem;
    width: 1rem;
  }
}
