@import "../../styles/typography";

.feedback {
  font-size: $ae-font-size-xs;
  color: var(--gray-800);

  &.feedback-error {
    color: var(--danger);
  }

  &.feedback-success {
    color: var(--green);
  }
}
