@import "@aptedge/lib-ui/src/styles/_boxes";

.bread-crumb {
  display: inline-flex;
  color: var(--gray-800);

  a {
    text-decoration: none;
    color: var(--primary);

    &:hover {
      color: var(--primary-hover);
    }
  }

  .spacer {
    margin: 0 $box-sizing-medium;
  }
}
