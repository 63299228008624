.label-create-item {
  padding: 1rem;
  padding-bottom: 24px !important;

  &.nested-create {
    background-color: hsl(0, 0, 99);
  }

  h5 {
    margin-top: 12px;
    margin-bottom: 24px;
  }

  input {
    outline: none !important;
    box-shadow: none !important;

    &:focus {
      border-color: var(--primary);
    }
  }
}
