@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_colors";

.navbar.ae-navbar {
  background-color: var(--gray-000);
  height: $box-sizing-nav-bar;
  color: var(--gray-500);
  font-size: $ae-font-size-reg;
  font-weight: $ae-font-weight-regular;
  box-shadow: $box-shadow-navbar;
  user-select: none;
  z-index: $z-index-navigation-bar;
  top: 0;

  &.no-shadow {
    box-shadow: none;
  }

  .navbar-brand {
    span {
      vertical-align: middle;
      font-size: 20px;
      padding-left: $box-sizing-medium;
      text-transform: capitalize;
    }
  }

  .nav-item {
    position: relative;
    display: flex;
    align-items: center;
    height: 100% !important;
    font-size: $ae-font-size-medium;
    transition: color 200ms;

    &:after {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -4px;
      height: toRem(4px);
      border-radius: 0.5rem;
      content: "";
      display: block;
    }

    &.active {
      color: var(--primary);

      &:after {
        background-color: var(--primary);
      }
    }

    &:hover:not(.active) {
      color: var(--primary-hover);
    }

    .global-search {
      font-size: $ae-font-size-xs;

      span {
        text-overflow: ellipsis;
      }
    }
  }
  .search-shortcut {
    margin-left: toRem(4px);
    padding: toRem(5px) toRem(12px);
    background-color: var(--gray-100);
    color: var(--gray-500);
    line-height: toRem(17px);
    border-radius: toRem(2px);
  }

  .app-routes {
    .popper .content a.active > .option {
      background-color: var(--gray-100);
      color: var(--primary);
    }
  }

  .btn.ae-button.small {
    padding: 4px 12px;
  }

  .btn.ae-button .button-content > :not(:first-child) {
    margin-left: 12px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
  .nav-item:hover:not(.active) .search-nav span.icon.search-icon {
    color: var(--primary-hover);
  }
  .nav-item:hover:not(.active) .search-nav span.search-shortcut {
    background-color: $teal-lighter;
    color: var(--primary-hover);
  }
  a.nav-item.ml-4.mr-4.active .search-nav .search-icon {
    color: var(--primary);
  }
  a.nav-item.ml-4.mr-4.active .search-nav .search-shortcut {
    background-color: $teal-lighter;
    color: var(--primary);
  }
  .search-nav {
    margin: 0 auto;
    .search-icon {
      margin-right: 0.125rem;
    }
    .search-icon svg.svg-inline--fa.fa-search.fa-w-16 {
      width: 18px;
      height: auto;
    }
  }

  .pending-article-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: $border-radius-rounded;
    background-color: $red;
    padding: 0.25rem;
    margin-left: 0.25rem;
  }
}
