@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.ae-modal.edge-migrate {
  .input-container {
    margin-top: $box-sizing-large;
  }

  h5 {
    color: var(--gray-800);
  }

  .disclaimer {
    color: var(--gray-400);
  }

  .migrate-tickets-content {
    margin-top: $box-sizing-large;
    min-height: 450px;

    .tickets-results,
    .edges-results,
    .spinner {
      margin-top: $box-sizing-xxl;
    }

    .edges-results {
      > h5 {
        text-transform: uppercase;
        margin-left: $box-sizing-regular;
        color: var(--gray-400);
        font-size: $ae-font-size-mini;
      }
    }

    &.loading-screen {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ticket-search {
    margin-top: $box-sizing-regular;
    margin-bottom: $box-sizing-large;
  }

  .pagination-bar {
    margin-top: $box-sizing-large;
  }

  .ticket-item {
    &.added {
      border: none;
      background-color: var(--gray-100);
      box-shadow: none;

      .added-label {
        margin-right: $box-sizing-medium;
        text-transform: uppercase;
        display: inline-block;
      }
    }

    .add-icon {
      transition: all 200ms;
      color: var(--primary);
      padding: $box-sizing-regular;
      border: 1px solid var(--primary);
      border-radius: $border-radius-standard;
      line-height: 1;

      &:hover {
        color: var(--primary-hover);
        border-color: var(--primary-hover);
      }
    }

    .close-icon {
      transition: all 200ms;
      padding: $box-sizing-medium;
      border-radius: $border-radius-standard;
      line-height: 1;

      &:hover {
        color: var(--danger);
        background-color: var(--gray-200);
      }
    }
  }

  .confirm-section {
    margin-top: $box-sizing-xxl;

    > h5 {
      color: var(--gray-600);
      font-weight: $ae-font-weight-regular;
    }

    & > :not(:last-child) {
      margin-bottom: $box-sizing-large;
    }

    .pill-container {
      & > * {
        margin: $box-sizing-small $box-sizing-regular;
      }
    }

    .pill {
      border: 1px solid var(--gray-100);
      background-color: transparent;
      color: var(--gray-600);
      font-size: $ae-font-size-sm;
      font-weight: $ae-font-weight-regular;
    }
  }

  .ae-modal-footer {
    color: var(--gray-500);
    font-size: $ae-font-size-sm;
    border-top: 1px solid var(--gray-100);
    padding: $box-sizing-xl $box-sizing-xxxl;
    margin-bottom: -$box-sizing-xl;
    margin-right: -$box-sizing-xxxl;
    margin-left: -$box-sizing-xxxl;
  }
}
