@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.pagination-sort {
  .select {
    min-width: 120px;

    .input-container {
      background-color: var(--gray-100);
      border: none;
      border-radius: $border-radius-rounded;

      input {
        font-size: $ae-font-size-sm;
      }
    }
  }
}
