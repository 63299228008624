@import "@aptedge/lib-ui/src/styles/_boxes";

.list-item.issue {
  .remove-icon {
    color: var(--gray-500);
    padding: $box-sizing-medium;
    border-radius: $border-radius-standard;

    &:hover {
      color: var(--danger);
      background-color: var(--gray-100);
    }
  }

  .jira-link {
    border-radius: $border-radius-standard;
    color: var(--gray-500);
    padding: $box-sizing-medium;

    &:hover {
      color: var(--primary);
      background-color: var(--gray-100);
    }
  }
}
