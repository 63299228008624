@import "../../styles/boxes";

.markdown-content {
  height: auto;
  padding: $box-sizing-large;
  word-break: break-word;
  letter-spacing: 0.01em;

  // prevent extra bottom margin on e.g. the last <p> tag
  .markdown-text > *:last-child {
    margin-bottom: 0;
  }

  .placeholder {
    color: var(--gray-500);
    padding-bottom: $box-sizing-regular;
  }

  a {
    word-break: break-all;
  }

  blockquote {
    padding-left: $box-sizing-large;
    border-left: 3px solid var(--gray-300);
  }

  strong {
    color: var(--gray-900);
  }

  img {
    max-width: 100%;
  }

  &.preview {
    background-color: var(--gray-100);
  }
}
