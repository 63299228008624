@import "../../styles/boxes";
@import "../../styles/typography";

.no-data {
  font-size: $ae-font-size-sm;
  color: var(--gray-300);
  padding: $box-sizing-xxl;
  text-align: center;
  margin-top: 1rem;
}
