@import "@aptedge/lib-ui/src/styles/_boxes";

.edge-buttons {
  display: flex;
  margin-top: $box-sizing-medium;
  margin-bottom: $box-sizing-xxl;

  @media (min-width: 992px) {
    justify-content: flex-end;
  }
}
