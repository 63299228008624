@import "../../styles/boxes";

.dropzone {
  height: 100px;
  width: 100%;
  background-color: var(--gray-100);
  border-radius: $border-radius-standard;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-600);

  &:not(.disabled) {
    cursor: pointer;
    &:hover {
      background-color: var(--gray-200);
    }
  }

  &.disabled {
    color: var(--gray-300);
    background-color: var(--gray-100);
  }

  :focus {
    outline: none;
  }
}
