@import "../../styles/boxes";

.text-editor {
  padding: $box-sizing-medium;
  border-radius: $border-radius-rounded;
  word-break: break-word;

  .rendered {
    padding: $box-sizing-medium;
    border: 1px solid var(--gray-100);
    border-radius: $border-radius-rounded;
    background-color: var(--gray-100);

    .placeholder {
      color: var(--gray-600);
    }

    &:not(.disabled):not(:focus):not(:focus-within):not(.read-only) {
      cursor: text;

      &:hover {
        border-color: var(--primary);

        .placeholder {
          color: var(--gray-800);
        }
      }
    }
  }
}
