@import "@aptedge/lib-ui/src/styles/_mixins";

.container {
  margin-top: toRem(32px);
}

.addContainer {
  margin-top: toRem(8px);
}

.emptyStateContainer {
  margin-top: toRem(16px);
}
