@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.site-list-border {
  border: 1px solid var(--primary);
  border-radius: $border-radius-standard;
  padding: 0.5rem 0px;
}

.site-id {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
