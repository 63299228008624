@import "@aptedge/lib-support-ui/src/styles/mixins.scss";
@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";

.searchUser {
  border-radius: $border-radius-standard;
  padding: 0.25rem 0.5rem;
  border: 1px solid $slate-40;
  gap: 0.125rem;
  background-color: $slate-10;

  &:focus-within {
    border: 1px solid $blue-40;
  }

  .userInput {
    font-size: $ae-font-size-xs;
    font-family: $ae-font-family-inter;
    font-weight: $ae-font-weight-medium;
    padding: 0;

    &::placeholder {
      color: $slate-60;
    }
  }

  .searchIcon,
  .clearIcon {
    color: $slate-60;
    font-size: 1.25rem;
  }

  .clearIcon {
    cursor: pointer;
  }
}

.selectContent {
  width: 15rem;
  z-index: 9;
}

.suggestSearchResults {
  margin-top: 0.25rem;
  background: white;
  border: 1px solid $slate-20;
  border-radius: $border-radius-standard;
  width: 100%;
  padding: 0.25rem 0.5rem;
}

.userDetail {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  font-size: $ae-font-size-xs;
  font-family: $ae-font-family-inter;
  font-weight: $ae-font-weight-medium;
  margin-bottom: 0.25rem;

  &:last-child {
    margin-bottom: 0;
  }

  .userInitial {
    border-radius: $border-radius-circle;
    padding: 0.25rem 0.5rem;
    background-color: $orange-10;
    color: $orange-80;
  }

  .userEmail {
    color: $slate-70;
  }
}
