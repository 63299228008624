@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.ticket-item.result {
  .add-icon {
    color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: $border-radius-standard;
    line-height: 1;
    padding: $box-sizing-regular;

    &:hover {
      color: var(--primary-hover);
      border-color: var(--primary-hover);
    }
  }

  .close-icon {
    padding: $box-sizing-medium;
    border-radius: $border-radius-standard;
    line-height: 1;

    &:hover {
      color: var(--danger);
      background-color: var(--gray-200);
    }
  }

  &.added {
    border: none;
    background-color: var(--gray-100);
    box-shadow: none;

    .added-label {
      margin-right: $box-sizing-medium;
      text-transform: uppercase;
      display: inline-block;
    }
  }
}
