@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_colors";

.search-result-card-action-icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 0.5rem;

  .icon {
    path {
      stroke-width: 2;
      stroke: $icon-default;
    }
    &:hover {
      path {
        stroke: $icon-hover;
      }
    }
  }

  .open-link-icon {
    path {
      stroke-width: 0.2;
      fill: $icon-default;
    }
    &:hover {
      path {
        fill: $icon-hover;
      }
    }
  }
}
