@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";
@import "@aptedge/lib-ui/src/styles/_mixins";

.container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 32rem 1fr 1fr 1fr 6rem;
  padding: 1rem;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1),
    0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  min-width: 1000px;
}

.containerEven {
  background: #fff;
}

.containerOdd {
  background: #fafafa;
}

.containerViewed {
  background: $slate-10;
}

.containerV2 {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 28rem 0.75fr 0.75fr 0.75fr 1.25fr 1.25fr;
  cursor: pointer;

  &:hover {
    background-color: $gray-100;
  }
}

.tableCell {
  color: #333;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: 8rem;
  flex-shrink: 0;
}

.articleItem {
  width: 36rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ticketItem {
  color: #06758c;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;

  a {
    @include textEllipsis(1, 100%, left, 140%, break-all);
  }
}

.actionsItem {
  width: 6rem;
  flex-shrink: 0;
}

button {
  border: none;
  background: inherit;
  &:focus {
    outline: none;
  }

  .editIcon {
    svg {
      &:hover {
        path {
          fill: $selection;
        }
      }
    }
  }

  .deleteIcon {
    svg {
      &:hover {
        path {
          fill: $selection;
        }
      }
    }
  }
}

.articleButton {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  border: none;
  background: inherit;
}

.articleButton:hover {
  text-decoration: underline;
}

.articleButton:focus {
  outline: none;
}

.articleButton:disabled {
  text-decoration: none;
  cursor: text;
}

.disable {
  text-decoration-line: none;
  color: $gray-1;
}

.disableLink {
  cursor: not-allowed;
}

.articleStatusIcon {
  svg {
    width: 1.125rem;
    height: 1.125rem;
    font-size: 1.125rem;
  }
}

.articleStatusButton {
  padding: 0 0.75rem;
  border-radius: $box-sizing-view;
  background: $slate-20;
  border: 1px solid $slate-60;
  color: $slate-60;
  font-size: $ae-font-size-xs;
  font-weight: $ae-font-weight-medium;
  font-family: $ae-font-family-inter;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.draftArticle {
  border: 1px solid $viking-80;
  color: $viking-80;
  background-color: $viking-40;
}

.publishedArticle {
  border: 1px solid $green-80-2;
  color: $green-80-2;
  background-color: $green-40;
}

.inReviewArticle {
  border: 1px solid $orange-80;
  color: $orange-80;
  background-color: $orange-40;
}

.deprecatedArticle {
  border: 1px solid $slate-80;
  color: $slate-80;
  background-color: $slate-40;
}

.actionMenuContainer {
  position: relative;
  .moreVertIcon {
    color: $slate-50;
    cursor: pointer;
  }
}

.actionMenuItemContainer {
  position: absolute;
  right: 0.5rem;
  background: white;
  border-radius: $border-radius-standard;
  box-shadow: 0px 2px 4px 0px #0000001a;
  margin-top: 0.25rem;

  .actionMenuItem {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: $ae-font-size-reg;
    font-family: $ae-font-family-inter;
    font-weight: $ae-font-weight-medium;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
      background: $slate-30;
    }
  }
}
