@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";

.auto-preview {
  .link-icon {
    color: var(--primary);
  }

  .filter-row {
    pointer-events: none;
    display: grid;
    grid-template-columns: 80%;
    padding: $box-sizing-large;
  }

  .auto-reply-wrapper {
    margin-top: $box-sizing-xl;
  }

  .auto-reply-wrapper.disabled {
    border-color: $gray-200;
  }

  .auto-reply-text-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h5.disabled {
    color: $gray-200;
  }

  .auto-reply-toggle-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .auto-reply-toggle-label {
    font-size: $ae-font-size-xs;
    margin-right: 0.25rem;
  }

  .conditions-wrapper {
    margin-top: $box-sizing-small;
  }

  .delete-saved {
    font-weight: $ae-font-weight-bold;
    // width: fit-content;
    margin-left: auto;
    margin-right: 2rem;
  }
}
