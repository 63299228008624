@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.suggested-answer-body {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 1.35rem;
  /* or 200% */
  color: #475467;
  position: relative;
  display: grid;
  word-break: break-word !important;
  p {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  hr {
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: 8px;
    color: #475467;
  }

  .blur-overlay {
    display: flex;
    justify-content: center;
    align-items: end;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    padding-bottom: toRem(8px);
    background-image: linear-gradient(
      transparent,
      $teal-lighter 25%,
      $teal-lighter 50%,
      $teal-lighter 75%,
      $teal-lighter
    );

    .show-more {
      border-radius: toRem(50px);
      padding-top: $box-sizing-medium;
      padding-bottom: $box-sizing-medium;
      width: inherit;
      color: $teal;
      border: 1px solid $teal;
      outline: none;
      background-color: $teal-lighter;
      font-size: 13px;
      font-family: inherit;
      font-weight: $ae-font-weight-semi-bold;
    }
  }
}

.answer-content-full {
  height: auto;
}

.answer-content-truncated {
  overflow: hidden;
  height: toRem(155px);
}

.followup-link {
  background: #f0f4ff;
  border-radius: 0.5rem;
  color: #1570ef;
  font-size: small;
  cursor: pointer;
  margin: 4px 0;
  padding: 0.25rem 0.62rem;
  padding-bottom: 0.42rem;
  display: inline-block;

  .icon {
    padding: 0;
    margin-right: 0.62rem;
    svg {
      width: toRem(20px);
      height: toRem(20px);
    }
  }
  .edge-icon {
    font-size: unset;
    svg {
      rect {
        fill: #06778c;
      }
    }
  }
  .img-icon {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.62rem;
  }
}
