@import "@aptedge/lib-ui/src/styles/_colors";

.rulesItemContainer {
  display: flex;
}

.rulesItemContainer:hover button {
  opacity: 1;
}

.rulesItemContainer:hover input {
  background: $gray-rd-100;
}

.rulesItemContainer:hover div {
  background: $gray-rd-100;
}
