@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";
@import "@aptedge/lib-support-ui/src/styles/mixins.scss";

.container {
  display: flex;
  align-items: baseline;
  gap: 12px;
  padding: 0 0.25rem;
}

.selectedTabContainer {
  border-bottom: 2px solid #06758c;
}

.unselectedTabContainer {
  border-bottom: none;
}

.selectedTabLabel {
  color: #06758c;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.unselectedTabLabel {
  color: #667085;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.countBubbleContainer {
  height: 1.1rem;
  width: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: $border-radius-circle;
  background-color: $red;
  margin-left: 0.5rem;
}

.countBubbleLabel {
  color: $white;
  text-align: center;
  font-size: 10px;
  font-style: $ae-font-style-normal;
  font-weight: $ae-font-weight-semi-bold;
  line-height: 1rem;
}
