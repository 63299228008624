@import "../../styles/boxes";
@import "../../styles/typography";

.thumbnail {
  width: 160px;
  height: 100px;
  margin: $box-sizing-regular;
  border-radius: $border-radius-standard;
  position: relative;
  overflow: hidden;
  word-break: break-word;
  box-shadow: $box-shadow-default;

  .thumbnail-overlay {
    position: absolute;
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    padding: $box-sizing-regular;
    border-radius: inherit;

    .trash-icon {
      line-height: 1;
      opacity: 0;
      color: var(--gray-000);
      padding: $box-sizing-regular;
      border-radius: $border-radius-standard;
      align-self: flex-end;

      &:hover {
        background-color: var(--gray-500);
      }
    }

    p {
      color: var(--gray-000);
      opacity: 0;
      font-size: $ae-font-size-mini;
    }

    &:hover {
      background-color: var(--dark-overlay);

      .trash-icon {
        opacity: 1;
      }

      p {
        opacity: 1;
      }
    }
  }

  .thumbnail-content {
    border-radius: inherit;
    width: 160px;
  }
}
