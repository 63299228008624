@import "../../../styles/boxes";
@import "../../../styles/typography";

.day {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-000);
  color: var(--gray-700);
  font-size: $ae-font-size-sm;
  padding: 6px $box-sizing-medium;
  min-width: 32px;
  transition-property: background-color;
  transition-duration: 200ms;

  &.disabled {
    pointer-events: none;
  }

  &:hover {
    background-color: var(--gray-100);
  }

  &:active {
    background-color: var(--gray-200);
  }
}
