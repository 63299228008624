@import "../../styles/boxes";
@import "../../styles/typography";

.btn.ae-button {
  background-color: var(--primary);
  color: var(--gray-000);
  font-weight: $ae-font-weight-medium;
  font-size: $ae-font-size-sm;

  .button-content {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: center;
    position: relative;

    > :not(:first-child) {
      margin-left: $box-sizing-large;
    }
  }

  &.disabled {
    color: var(--gray-500);
    background-color: var(--gray-100);
    cursor: not-allowed;
  }

  &.disabled.primary {
    color: var(--gray-000);
    background-color: var(--gray-300);
    cursor: not-allowed;
  }

  &.disabled.secondary {
    color: var(--gray-500);
    background-color: var(--gray-100);
    cursor: not-allowed;
  }

  &.primary {
    --color: var(--gray-000);
    --background-color: var(--primary);
    --background-color-hover: var(--primary-hover);
  }

  &.secondary {
    --color: var(--gray-600);
    --background-color: var(--gray-100);
    --background-color-hover: var(--gray-200);
  }

  &.danger {
    --color: var(--gray-000);
    --background-color: var(--danger);
    --background-color-hover: var(--danger-hover);
  }

  &.warning {
    --color: var(--gray-000);
    --background-color: var(--warning);
    --background-color-hover: var(--warning-hover);
  }

  &.loading {
    .spinner {
      position: absolute;
      margin: 0;
    }
  }

  &:not(.disabled) {
    color: var(--color);
    background-color: var(--background-color);

    &:hover {
      background-color: var(--background-color-hover);
      box-shadow: $box-shadow-s;
      transition: box-shadow 0.2s, background-color 0.2s; // Apply transitions for hover only.
    }

    &:active {
      transition: box-shadow 0s, background-color 0s; // Explicitly set them back to zero since hover and active can happen at the same time.
    }
  }

  &.small {
    padding: $box-sizing-regular $box-sizing-large;

    .icon {
      font-size: $ae-font-size-xs;
    }

    > :not(:first-child) {
      margin-left: $box-sizing-medium;
    }
  }

  &.large {
    padding: $box-sizing-regular $box-sizing-xl;

    .icon {
      font-size: $ae-font-size-sm;
    }
  }

  &:focus {
    box-shadow: none;
  }

  .icon {
    padding: 0;
  }
}
