@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.addRuleButton {
  all: unset;
  appearance: none;
  margin: 0;
  cursor: pointer;
  display: flex;
  padding: 0.25rem 0.875rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid $gray-rd-300;
  background: $white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: $teal;
  font-family: $ae-font-family-inter;
  font-size: 1rem;
  font-style: $ae-font-style-normal;
  font-weight: $ae-font-weight-medium;
  line-height: 1.5rem;
}

.addRuleButton:disabled {
  color: $light-teal;
}

.addRuleButton:not(:disabled):hover {
  filter: brightness(80%);
}
