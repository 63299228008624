@import "@aptedge/lib-ui/src/styles/_colors";

.container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 30rem 1fr 1fr 1fr 12rem;
  padding: 1rem;
  background: #fff;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1),
    0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  min-width: 1000px;
}

.containerEven {
  background: #fff;
}

.containerOdd {
  background: #fafafa;
}

.headerItem {
  color: #333;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: 8rem;
  flex-shrink: 0;
}

.articleItem {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ticketItem {
  color: #06758c;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
}

.actionsItem {
  gap: 1rem;
  justify-self: end;
}

.completeReviewAction {
  color: #06758c;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
  white-space: nowrap;
}
.deleteIcon {
  svg {
    &:hover {
      path {
        fill: $selection;
      }
    }
  }
}

.disable {
  text-decoration-line: none;
  color: $gray-1;
}

.disableLink {
  cursor: not-allowed;
}
