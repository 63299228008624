@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-family: $primary-font-family;
  font-size: $primary-font-size;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  color: white;
  cursor: pointer;
  .backBtn {
    color: transparentize($slate-00, 0.4);
    cursor: pointer;
    transition: color 100ms ease;

    &:hover {
      color: $viking-10;
    }
  }
}
