@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_mixins";

.container {
  position: relative;
  display: flex;
  width: 50%;
}

.container input {
  display: flex;
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 0 0;
  border: 1px solid $gray-rd-200;
  border-bottom: 0;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.container input:hover {
  background: $gray-rd-100;
}

.inputTop {
  border-top-right-radius: 0.25rem;
}

.inputBottom {
  border-bottom: 1px solid $gray-rd-200;
  border-bottom-right-radius: 0.25rem;
}

.inputError {
  background: $red-rd-100;
}

.inputDisabled {
  background: $gray-rd-100;
}

.container textarea {
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;
  resize: none;
  overflow: auto;
  background: $white;
  position: absolute;
  width: 100%;
  z-index: 1;
  font-size: 1rem;
}

.container button {
  position: absolute;
  right: toRem(1px);
  top: 0.5rem;
  opacity: 0.3;
  background: none;
}
