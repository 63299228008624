.add-edge-link-form {
  label {
    font-size: 11px;
    font-weight: 300;
    margin-bottom: 4px;
  }

  input {
    outline: none;
    box-shadow: none !important;
    font-size: 12px;

    &:focus {
      border: 1px solid var(--primary);
    }
  }
}
