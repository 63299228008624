@import "../../styles/boxes";
@import "../../styles/typography";

@mixin input-mixin {
  width: 100%;
  position: relative;

  label {
    margin-bottom: 0;
    font-size: $ae-font-size-reg;
  }

  &.inline {
    display: inline-block;
    align-items: center;

    label {
      margin-right: 0.5rem;
    }
  }

  &.small {
    .input-container {
      input {
        font-size: $ae-font-size-xs;
      }
    }

    .icon {
      font-size: $ae-font-size-mini;
    }

    .feedback {
      font-size: $ae-font-size-mini;
    }
  }

  .input-container {
    display: inline-flex;
    min-width: 100px;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border: 1px solid;

    color: var(--gray-800);
    border-color: var(--gray-200);
    border-radius: $border-radius-standard;
    background-color: var(--gray-100);

    /* Override top padding because of chips' margin-top */
    padding: $box-sizing-small $box-sizing-medium;

    .chip {
      margin: $box-sizing-regular 0;
      border: none;
      color: var(--gray-800);
      background-color: var(--gray-100);
      font-weight: $ae-font-weight-regular;

      &:not(:last-child) {
        margin-right: $box-sizing-regular;
      }
    }

    input {
      width: 0;
      border: none;
      outline: none;
      padding: 0;
      min-width: 33px;
      flex-grow: 1;
      background-color: inherit;
      color: inherit;
      margin: $box-sizing-regular 0;
      font-size: $ae-font-size-reg;
      line-height: 1.5rem;

      &::placeholder {
        color: var(--gray-400);
      }
    }

    .indicator-container {
      right: 4px;
      background-color: initial;
      display: inline-flex;
      align-items: center;
      color: var(--gray-300);

      font-size: $ae-font-size-reg;

      .close-icon {
        margin: auto $box-sizing-medium;
      }

      > * {
        margin: auto $box-sizing-regular;
        padding: 0;
        align-self: center;

        &:hover {
          color: var(--gray-400);
        }
      }
    }
  }

  &:not(.disabled).error {
    .input-container {
      border-color: var(--danger);
    }
  }

  &.disabled {
    label {
      color: var(--gray-400);
    }

    .input-container {
      input {
        cursor: default;
        color: var(--gray-300);
      }
      border-color: var(--gray-200);
    }

    .indicator-container {
      color: var(--gray-100);

      > * {
        &:hover {
          color: inherit;
        }
      }
    }
  }

  &:not(.error):not(.disabled) {
    &:hover {
      .input-container {
        border-color: var(--primary-hover);
      }
    }

    &:focus,
    &:focus-within {
      .input-container {
        border-color: var(--primary);
      }

      .indicator-container {
        color: var(--gray-500);

        > * {
          &:hover {
            color: var(--gray-700);
          }
        }
      }
    }
  }

  .feedback {
    margin: $box-sizing-small;
  }
}
