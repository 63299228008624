@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_colors";

.header-preview-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  .ai-answer {
    margin-right: toRem(5px);
  }

  .title {
    font-family: "Inter";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    /* Gray/700 */
    color: $header-title-color;
    cursor: default;
  }

  .ai-answer-preview-icon {
    padding-left: 0;
  }
}
