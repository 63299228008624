.analytics {
  background-color: #f3f4ef;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  iframe {
    padding: 1rem;
    border: none;
    flex: 1;
    width: 100%;
    height: 100%;
  }
  .loader {
    flex: 1;
    width: 80%;
    height: 100%;
  }
}
.emptyDashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: Inter;
  color: var(--Gray-600, #475467);
  flex: 1;
}
