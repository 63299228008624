@import "@aptedge/lib-ui/src/styles/_colors";

.headerContainer {
  display: flex;
  align-items: start;
  justify-content: space-between;
  background-color: $viking-90;
  height: max-content;

  .navigations {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
  }
  .closeButtonIcon {
    color: transparentize($slate-00, 0.4);
    cursor: pointer;
    transition: color 100ms linear;

    &:hover {
      color: $viking-10;
    }
  }
}
