@import "@aptedge/lib-ui/src/styles/_mixins";

.container {
  display: flex;
  height: toRem(44px);
  padding: toRem(10px) toRem(14px);
  align-items: center;
  gap: toRem(8px);
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #06758c;
  cursor: pointer;
}

.message {
  font-family: Inter;
  font-size: toRem(14px);
  font-style: normal;
  font-weight: 500;
  line-height: toRem(24px);
}

.plusIcon {
  color: #06758c;
  margin-left: auto;
}
