.delete-icon {
  box-shadow: none !important;
  transition: visibility 50ms;
  visibility: hidden;
  border: none;
  background-color: white;
  color: hsl(0, 0, 50);

  &:hover {
    background-color: hsl(0, 0, 98);
    color: red;
  }

  &:active {
    background-color: hsl(0, 0, 97);
  }
}
