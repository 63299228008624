@import "@aptedge/lib-support-ui/src/styles/mixins.scss";
@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";

.container {
  margin: 40px 0;
}

.articleCount {
  margin: 0;
  margin-left: 4px;
  color: #333;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.articleCard {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}

.articleContent {
  max-width: 61.25rem;
  h1 {
    color: #4f4f4f;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #4f4f4f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }

  p,
  ol,
  ul {
    color: #4f4f4f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}

.articleActions {
  display: flex;
  width: 100%;
  align-items: center;

  button {
    display: flex;
    padding: 4px 17px;
    align-items: center;
    border-radius: 4px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    span {
      padding: 0;
    }
  }

  .editButton {
    margin-right: auto;
    gap: 8px;
    border: 1px solid #ddd;
    background: #fff;
    color: #969696;
  }
  .discardButton {
    margin-right: 8px;
    gap: 9px;
    border: 1px solid #ddd;
    background: #fff;
    color: #969696;
  }

  .discardButtonV2 {
    padding: 0.5rem 1rem;
    color: $slate-60;
    background: $slate-10;
    font-weight: $ae-font-weight-regular;
    font-family: $ae-font-family-inter;
    font-size: $ae-font-size-medium;
  }
  .saveArticleButton {
    gap: 9px;
    background: #06758c;
    color: #fff;
    border: none;
  }

  .saveArticleButtonV2 {
    padding: 0.5rem 1rem;
    gap: $box-sizing-medium;
    color: $white;
    background: $viking-70;
    font-weight: $ae-font-weight-regular;
    font-family: $ae-font-family-inter;
    font-size: $ae-font-size-medium;
  }
}

.articleActionsV2 {
  justify-content: space-between;
}

.spinnerContainer {
  margin-top: 5rem;
}
