.integration {
  position: relative;
  background-color: rgba(0, 0, 0, 0.102);
  border-radius: 0 0 8px 8px;
  .copyButton {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: transparent;
  }
  .jsSnippet {
    color: rgba(0, 0, 0, 0.81);
  }
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  code {
    display: block;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 10px; /* Optional: to add some padding around code block */
  }
}

.snippetBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa;
  border-radius: 8px;
  width: 100%;
  padding: 8px 12px;
}
.expandedSnippetCode {
  border-radius: 8px 8px 0 0;
}
.generateBtn {
  border: none;
  background: #06758c;
  color: #fff;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
}
