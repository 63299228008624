@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.labels-widget {
  .pill-container {
    & > * {
      margin-top: $box-sizing-small;
      margin-bottom: $box-sizing-small;

      &:not(.label-add) {
        margin-right: $box-sizing-medium * 2;
      }
    }

    & > *:first-child {
      margin-left: 0;
    }
  }

  .no-labels {
    font-size: $ae-font-size-sm;
    color: var(--gray-400);
  }

  .label-add {
    display: inline-block;
  }

  .label-search {
    display: inline-block;
    position: relative;

    .input-container {
      border: 1px solid var(--primary);
      margin-top: 0;
      height: 27px;
      width: 200px;

      input {
        font-size: $ae-font-size-mini;
        margin: 0;
        height: 100%;
      }
    }

    .search-results-container {
      width: 100%;
      position: absolute;
      z-index: $z-index-popper;
      margin-top: $box-sizing-regular;
      box-shadow: $box-shadow-xs;
      background-color: var(--gray-000);
      padding: $box-sizing-large $box-sizing-medium;
      border-radius: $border-radius-standard;

      h5 {
        font-size: $ae-font-size-mini;
        font-weight: $ae-font-weight-regular;
        padding-left: $box-sizing-medium;
        color: var(--gray-600);
        text-transform: uppercase;
      }

      .list-item {
        border: none;
        padding: $box-sizing-medium;
        border-radius: 0;
        font-size: $ae-font-size-sm;
        font-weight: $ae-font-weight-medium;
        cursor: pointer;

        .results-parent-name {
          font-size: 10px;
          color: var(--gray-600);
        }

        &.highlighted {
          background-color: var(--primary-hover);
          color: var(--gray-000);

          .results-parent-name {
            color: var(--gray-200);
          }
        }

        .pill {
          display: none;
        }
      }
    }
  }
}
