@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-support-ui/src/styles/mixins.scss";
@import "@aptedge/lib-ui/src/styles/_mixins";

.selected-suggestion {
  background: var(--gray-100);
}

.suggestion-item {
  box-sizing: border-box;
  padding: toRem(10px) toRem(20px);
  align-items: center;
  align-content: center;
  display: flex;
  height: 3rem;
  cursor: pointer;
  .search-icon {
    color: var(--teal);
  }
}

.suggestion-item:hover {
  background: var(--gray-100);
}

.display-hidden {
  display: none;
}

.suggestion-txt {
  margin-left: toRem(10px);
  line-height: toRem(17px);
  font-size: $ae-font-size-reg;
  font-weight: $ae-font-weight-semi-bold;
  color: var(--gray-900);
  @include textEllipsis(1);
}
