@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";

.visibility {
  transform: translateY(20px);
}
.inputContainer {
  transform: translateY(0);
  align-self: end;
  background: $viking-90;
  backdrop-filter: blur(8px);
  padding: 0.75rem 1rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
    visibility 0.3s ease-in-out;

  button {
    display: flex;
    padding: 0.3125rem 1.125rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.1);
    outline: none;

    color: #fff;
    font-family: Inter;
    font-size: $primary-font-size;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 0.13rem;
    text-align: left;
    @include textEllipsis(2, minmax(max-content, 100%), left, 150%);

    &:focus {
      outline: none;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .suggestionContainer {
    max-height: 2rem;
    overflow: hidden;
    opacity: 1;
    transition: max-height 0.4s ease-in-out, opacity 0.5s ease-in-out;
    transition-delay: 0.5s;

    .suggestionPreview {
      button {
        @include textEllipsis(1, minmax(max-content, 100%), left, 150%);
      }
      max-height: 2rem;
      transition: max-height 0.4 ease-in;
      transition-delay: 0.6s;
    }

    .list {
      opacity: 0;
      transform: translateY(10px);
      transition: all 0.5s ease;
      display: block;
      height: auto;
      visibility: hidden;
      transition-delay: 0.6s;
      font-size: $primary-font-size;

      .skaLoader {
        width: 28rem;
      }
    }
  }

  .contactSupportBtn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &:hover {
    .suggestionContainer {
      max-height: 14rem;
    }
    .suggestionPreview {
      max-height: 0;
      visibility: hidden;
    }
    .list {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
}
