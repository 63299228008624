@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.label-chip {
  border: none;
  padding: 4px 6px;
  font-size: 0.75rem;
  font-weight: $ae-font-weight-medium;

  &.product,
  &.feature {
    color: #4c66bd;
    background-color: var(--royal-blue-100);
  }

  &.customer,
  &.theme {
    color: #bb8729;
    background-color: #ffefd2;
  }

  &.disabled {
    color: var(--gray-500);
    background-color: var(--gray-100);
  }
}
