@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";

.slack_card_container {
  a {
    color: unset;
    &:hover {
      text-decoration: unset;
    }
  }
  &:hover {
    background-color: var(--gray-100);
  }
  &.active {
    background-color: var(--gray-100);
  }

  .heading {
    display: flex;
    align-items: baseline;
    min-width: initial;

    .issue-icon {
      padding-left: 0;
      padding-top: 2px;
      margin-right: $box-sizing-medium;
      width: 1.2em;
      max-width: 1.2em;
    }

    .subject {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
