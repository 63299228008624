.expandableContainer {
  .expandBtn {
    border-radius: 0.75rem;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    padding: 0.5rem 0rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 100%;
    border: none;
    outline: none;
    margin-top: 0.25rem;
    color: var(--Viking-Viking-70, #2a767e);
    text-align: center;
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: -0.0175rem;
  }

  .dismissBtn {
    border-radius: 0.75rem;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    padding: 0.5rem 0rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 100%;
    border: none;
    outline: none;
    margin-top: 0.25rem;
    color: var(--Slate-Slate-00, #fff);
    text-align: center;
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: -0.0175rem;
    margin-top: 0.5rem;
  }
}
