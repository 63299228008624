@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.document-list-item {
  .document-url {
    word-break: break-all;

    .preview-highlight {
      background-color: $highlight-color;
      padding: 0rem 0.25rem;
    }

    .offset-highlight {
      font-style: normal;
      background-color: $highlight-color;
    }
  }

  .copy-icon {
    line-height: 1;
    border-radius: $border-radius-standard;
    padding: $box-sizing-regular;
    color: var(--gray-600);

    &:hover {
      color: var(--gray-900);
    }

    &:active {
      color: var(--primary);
    }
  }

  .info {
    font-size: toRem(13px);
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: var(--gray-500);
    font-family: $ae-font-family-inter;
    margin-top: toRem(8px);
  }
  .notion-icon {
    width: toRem(15px);
  }
}

.tooltip-info {
  word-break: "break-all";
}
