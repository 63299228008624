.page-content.settings {
  background-color: var(--gray-000);

  .content {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .container-fluid {
    @media (min-width: 1200px) {
      max-width: 1440px;
    }

    @media (min-width: 1800px) {
      max-width: 1800px;
    }
  }
}
.container {
  height: 100%;
}
