@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  border: 1px solid $gray-rd-200;
  border-bottom: 0px;
  background: $white;
  padding: 1rem;
  cursor: pointer;
}

.containerTop {
  border-radius: 0.25rem 0.25rem 0px 0px;
}

.containerBottom {
  border-radius: 0px 0px 0.25rem 0.25rem;
  border-bottom: 1px solid $gray-rd-200;
}

.container:hover {
  background: $gray-rd-100;
}

.contentContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;
}

.actionsContainer {
  width: 100%;
}
