@import "@aptedge/lib-ui/src";

/* Centers bootstraps modal, remove when we have a custom modal component */
.modal-dialog {
  max-width: max-content !important;
}

body {
  font-family: $ae-font-family, sans-serif;
  font-size: $ae-font-size-reg;
  font-weight: $ae-font-weight-regular;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  // ensure the nav bar elements don't wrap when the viewport is narrow
  min-width: 1130px;
}

h1,
h2,
h3,
h4,
h5,
h6,
small {
  color: var(--gray-900);
}

h1 {
  font-size: $ae-font-size-title;
  font-weight: $ae-font-weight-regular;
}

h2 {
  font-size: $ae-font-size-xl;
  font-weight: $ae-font-weight-semi-bold;
}

h3 {
  font-size: $ae-font-size-lg;
  font-weight: $ae-font-weight-semi-bold;
}

h4 {
  font-size: $ae-font-size-medium;
  font-weight: $ae-font-weight-semi-bold;
}

h5 {
  font-size: $ae-font-size-reg;
  font-weight: $ae-font-weight-semi-bold;
}

small {
  font-size: $ae-font-size-xs;
  font-weight: $ae-font-weight-regular;
}
.fa-icon {
  cursor: pointer;
  font-size: larger;
}
