.edge-file-list {
  .icons {
    .icon-btn {
      visibility: hidden;
      cursor: pointer;
      padding: 8px 4px;
      color: hsl(0, 0, 60);
      transition: color 200ms, background-color 200ms;
      border-radius: 4px;

      a {
        text-decoration: none;
        color: inherit !important;
      }

      &:hover {
        background-color: hsl(0, 0, 95);
        color: var(--primary);

        &.delete {
          color: red;
        }
      }
    }
  }

  .list-group-item:hover {
    .icons .icon-btn {
      visibility: visible;
    }
  }
}
