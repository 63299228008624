.saveConfig {
  margin-top: 1rem;
  button {
    border: none;
    background: #06758c;
    color: #fff;
    font-size: 0.875rem;
    padding: 8px 12px;
    border-radius: 4px;
    width: 40%;
    &:disabled {
      background: #ccc;
      cursor: not-allowed;
    }
  }
  .warningText {
    margin-top: 1rem;
    font-size: small;
    color: #e4a11b;
  }
}
