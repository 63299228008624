@import "@aptedge/lib-ui/src/styles/_colors";

.notifications {
  cursor: pointer;

  > .toggle {
    display: block;
    border-radius: 100%;
    padding: 4px;

    .bell {
      position: relative;

      .count {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -6px;
        right: -4px;
        background-color: $red;
        color: hsl(0, 0, 99);
        font-size: 8px;
        text-overflow: ellipsis;
        border-radius: 100%;
        padding: 2px;
        width: 16px;
        height: 16px;
      }

      .progress-bar-container {
        position: absolute;
        bottom: -8px;
        right: 0;
        left: 0;
      }
    }

    &.open {
      > .toggle {
        background-color: lighten($color: $primary, $amount: 40);
        color: $primary;
      }
    }
  }
}

.popper {
  .notifications-popper {
    width: 100%;
    color: hsl(0, 0, 40);
    overflow-y: hidden;
    width: min-content;
    font-size: 13px;
    background-color: white;
    border: 1px solid hsl(0, 0, 90);
    border-radius: 4px;
    box-shadow: 4px 4px 12px 0 hsla(0, 0, 50, 0.1);
    max-height: 80vh;
    min-height: 400px;

    .content {
      width: max-content;
      height: fit-content;
      overflow-y: auto;
      overflow-x: visible;
    }
  }
}
