@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.edge-title {
  margin-top: $box-sizing-medium;
  margin-bottom: $box-sizing-medium;

  .edge-name {
    cursor: text;
    word-break: break-word;
    padding: $box-sizing-medium 0;
    transition: all 200ms;
    margin-bottom: 0;

    &:hover {
      background-color: var(--gray-100);

      &.disabled {
        background-color: transparent;
      }
    }
  }

  &-buttons {
    display: flex;
    justify-content: flex-end;

    .ae-button {
      margin-left: 4px;
    }
  }

  .text-area {
    .text-area-container {
      margin-top: 0;
      margin-bottom: $box-sizing-medium;

      textarea {
        font-size: $ae-font-size-xl;
        font-weight: $ae-font-weight-semi-bold;
        word-wrap: break-word;
        word-break: break-word;
      }
    }
  }
}
