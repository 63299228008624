@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.filter-select {
  & &-toggle {
    border-radius: $border-radius-rounded;
    background-color: var(--gray-100);
    padding: 6px 12px;
    color: var(--gray-800);
    font-size: $ae-font-size-sm;
    font-weight: $ae-font-weight-medium;
    margin-top: $box-sizing-regular;
    margin-bottom: $box-sizing-regular;

    &:hover {
      background-color: var(--gray-200);
    }

    > * {
      padding: $box-sizing-regular;

      > span {
        padding: $box-sizing-regular;
      }
    }
  }

  > .popper .content {
    max-height: fit-content;
  }

  & &-item {
    color: var(--gray-800);
    font-size: $ae-font-size-xs;
    font-weight: $ae-font-weight-medium;

    .filter-icon {
      margin-right: $box-sizing-medium;

      .icon {
        padding: 0;
      }
    }

    &.disabled {
      color: var(--gray-600);
    }

    &.saved-item {
      display: flex;
      justify-content: space-between;
    }
  }
}
