@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes delay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.025;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.025;
  }
  50% {
    opacity: 0.05;
  }
  100% {
    opacity: 0.025;
  }
}

@keyframes wave {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}
