@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_colors";

.loader-container {
  display: flex;
  margin-bottom: $box-sizing-regular;
}

.loader {
  position: relative;
  border-radius: 50%;
  background-color: $loader-light-color;
  animation: loader 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.loader::before,
.loader::after {
  content: "";
  position: absolute;
  top: 0;
  border-radius: 50%;
  background-color: $loader-light-color;
  animation: loader 1s infinite alternate;
}

@keyframes loader {
  0% {
    background-color: $loader-dark-color;
  }
  50%,
  100% {
    background-color: $loader-light-color;
  }
}
