@import "../../styles/boxes";
@import "../../styles/typography";

.label {
  font-size: $ae-font-size-reg;

  &.pinned {
    top: 0;
    position: absolute;
    background-color: white;
    padding: 0 $box-sizing-regular;
    font-size: 9px;
    color: inherit;
    transform: translate(-6px, -50%);
    transition: all 200ms;
  }
}
