@import "colors";

@import "~bootstrap/scss/functions"; // Required
@import "~bootstrap/scss/variables"; // Required
@import "~bootstrap/scss/mixins"; // Required

//
// OVERRIDE BOOTSTRAP HERE
// see: https://getbootstrap.com/docs/4.6/getting-started/theming/
//

$grays: map-merge(
  $map1: $grays,
  $map2: (
    "000": $white,
    "111": $black
  )
);

// Add an additional breakpoint for large screens.
$grid-breakpoints: map-merge(
  $map1: $grid-breakpoints,
  $map2: (
    xxl: 1440px
  )
);

// Allows us to use .container-xxl in addition to Bootstrap's .container-sm, .container-md, etc.
// See: https://getbootstrap.com/docs/4.6/layout/overview/#containers
$container-max-widths: map-merge(
  $map1: $container-max-widths,
  $map2: (
    xxl: 1440px
  )
);

//
// END OVERRIDES
//

// Import ALL Bootstrap Sass compilers.

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot"; // Required
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group"; // Requires forms
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar"; // Requires nav
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal"; // Requires transitions
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";
