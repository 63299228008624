@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.edge-bulk-reply {
  color: var(--gray-900);

  & &-details {
    color: var(--gray-500);

    > * {
      margin-left: $box-sizing-regular;
    }

    &-created-by {
      color: var(--gray-900);
      font-weight: $ae-font-weight-bold;
      margin-left: $box-sizing-medium;
    }

    &-filter {
      color: var(--gray-900);
      font-weight: $ae-font-weight-bold;
    }
  }

  & &-content {
    background-color: var(--gray-100);
    padding: $box-sizing-large $box-sizing-xxl;
    margin-top: $box-sizing-medium;
    border-radius: $border-radius-rounded;

    &.internal {
      background-color: var(--yellow-100);
    }
  }

  & &-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $box-sizing-medium;
  }

  & &-created-on {
    color: var(--gray-500);
    font-size: $ae-font-size-sm;
  }

  &.pending {
    color: var(--gray-500);

    .edge-bulk-reply-details,
    .edge-bulk-reply-details-created-by,
    .edge-bulk-reply-details-filter {
      color: var(--gray-500);
    }
    .edge-bulk-reply-content,
    .edge-bulk-reply-content.internal {
      background-color: var(--gray-100);
    }
  }
}
