@import "@aptedge/lib-support-ui/src/styles/mixins.scss";

.ticket-list {
  display: grid;
  grid-template-columns: 1fr toRem(60px) 2fr toRem(60px) repeat(3, 1fr) toRem(
      40px
    );
  align-items: center;
  justify-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: toRem(8px) toRem(12px);
  margin-bottom: toRem(8px);
  border-radius: toRem(4px);
  font-size: x-small;
  text-align: center;
  font-weight: bolder;
}
