@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_mixins.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";

.container {
  padding: toRem(8px) toRem(12px);
  width: toRem(200px);
  display: flex;
  align-items: center;
  gap: toRem(8px);
  color: #667085;
}

.container:hover {
  color: #667085;
}

.active {
  color: $viking-1;
  background-color: $teal-lighter;
  border-radius: toRem(6px);
}

.active:hover {
  color: $viking-1;
  background-color: #f0f7f9;
}

.icon {
  font-size: toRem(14px);
  line-height: toRem(20px);
}

.label {
  font-family: $ae-font-family-inter;
  font-size: toRem(14px);
  font-weight: $ae-font-weight-semi-bold;
  line-height: toRem(20px);
}
