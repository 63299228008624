@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";
@import "@aptedge/lib-support-ui/src/styles/mixins.scss";

.articleSection {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  height: inherit;
  padding-bottom: 1rem;

  .articleSectionHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1rem;

    .headingTitle {
      color: $slate-90;
      font-family: $ae-font-family-inter;
      font-size: $ae-font-size-xs;
      font-style: $ae-font-style-normal;
      font-weight: $ae-font-weight-semi-bold;
    }

    .publishDivider {
      height: 1px;
      background: $slate-20;
      width: toRem(167px);
      margin-left: 0.75rem;
    }

    .reviewersDivider {
      height: 1px;
      background: $slate-20;
      width: 10.625rem;
      margin-left: 0.75rem;
    }

    .detailsDivider {
      height: 1px;
      background: $slate-20;
      width: 11.875rem;
      margin-left: 0.75rem;
    }
  }

  .articleSectionHeadingNotForAgent {
    padding-top: 0.5rem;
  }

  .statusContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 0.75rem;
    position: relative;
    margin: 0.5rem 0;
    font-size: $ae-font-size-medium;
    font-weight: $ae-font-weight-medium;
    font-family: $ae-font-family-inter;
    cursor: pointer;
    border-radius: $border-radius-standard;

    .statusInfo {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    .arrowDown {
      font-size: 1rem;
    }

    &:hover {
      filter: brightness(80%);
    }
  }

  .statusContentForAgent {
    margin-bottom: 0;
    cursor: default;
  }

  .draftArticle {
    border: 1px solid $viking-80;
    color: $viking-80;
    background: $viking-40;
  }

  .publishedArticle {
    border: 1px solid $green-80-2;
    color: $green-80-2;
    background: $green-40;
  }

  .inReviewArticle {
    border: 1px solid $orange-80;
    color: $orange-80;
    background: $orange-40;
  }

  .deprecatedArticle {
    border: 1px solid $slate-80;
    color: $slate-80;
    background: $slate-40;
  }

  .statusMenu {
    position: absolute;
    top: 9rem;
    right: 1.565rem;
    z-index: 9;
    border-radius: $border-radius-standard;
    background: $white;
    padding: 0.5rem;
    width: 15.625rem;
    box-shadow: 0px 2px 4px 0px #0000001a;
    border: 0.5px solid $slate-30;
    font-size: $ae-font-size-medium;
    font-weight: $ae-font-weight-medium;
    font-family: $ae-font-family-inter;

    .statusMenuInfo {
      color: $slate-50;
      font-size: $ae-font-size-xs;
      font-weight: $ae-font-weight-medium;
      font-family: $ae-font-family-inter;
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    .articleStatusBtn {
      padding: 0.25rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      border-radius: $border-radius-standard;
      margin-top: 0.25rem;
      color: $slate-80;

      svg {
        font-size: 1.25rem;
      }

      &:hover {
        background: $slate-30;
      }
    }
  }

  .statusMenuOnReviewPage {
    top: 11.5rem;
    right: 1.565rem;
  }

  .infoContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;

    .checkBoxContainer {
      display: flex;
      gap: 0.25rem;
      align-items: center;
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-regular;
      font-family: $ae-font-family-inter;
    }
  }

  .reviewerList {
    .userDetail {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
      font-size: $ae-font-size-xs;
      font-family: $ae-font-family-inter;
      font-weight: $ae-font-weight-medium;
      margin-bottom: 0.25rem;
      background: $slate-10;
      padding: 0.5rem;
      justify-content: space-between;
      border-radius: $border-radius-standard;
      position: relative;

      &:hover {
        background: $slate-20;
      }

      &:first-child {
        margin-top: 0.25rem;
      }

      .userInitial {
        border-radius: $border-radius-circle;
        padding: 0.25rem 0.5rem;
        background-color: $orange-10;
        color: $orange-80;
        margin-right: 0.25rem;

        .userEmail {
          color: $slate-70;
        }
      }

      .approveArticle {
        display: flex;
        font-size: $ae-font-size-xs;
        font-family: $ae-font-family-inter;
        font-weight: $ae-font-weight-medium;
        padding: 0.25rem 0.5rem;
        gap: 0.25rem;
        cursor: pointer;

        .doneAllIcon {
          font-size: 1rem;
        }
      }

      .userAction {
        display: flex;
        align-items: center;

        .reviewStatus {
          color: $slate-50;
        }

        .approvedReviewStatus {
          color: $viking-70;
        }

        .reviewerDelete {
          color: $slate-30;
          cursor: pointer;
        }

        .reviewerDeleteInReview {
          border-left: 1px solid $viking-80;
          padding: 0.25rem;
          cursor: pointer;
        }
      }

      .userActionApprove {
        background: $viking-70;
        color: $white;
        border-radius: $border-radius-standard;
      }
    }

    .currentUserDetail {
      background: none;
      padding: 0.25rem 0 0.25rem 0.5rem;

      &:hover {
        background: none;
      }
    }

    .approvedArticleUserDetail {
      background: $viking-10;
      padding: 0.5rem;

      &:hover {
        background: $viking-20;
      }
    }
  }

  .addReviewer {
    padding: 0.5rem 0;
    display: flex;

    .addReviewerBtn {
      background: $slate-20;
      padding: 0.25rem 0.75rem;
      border-radius: $border-radius-standard;
      display: flex;
      gap: 0.25rem;
      margin-left: 0.25rem;
      align-items: center;
      cursor: pointer;

      .addIcon {
        color: $slate-80;
        font-size: 1rem;
      }

      .addText {
        color: $slate-80;
        font-family: $ae-font-family-inter;
        font-size: $ae-font-size-xs;
        font-style: $ae-font-style-normal;
        font-weight: $ae-font-weight-medium;
      }

      &:hover {
        filter: brightness(80%);
      }
    }

    .disableAddBtn {
      cursor: not-allowed;
    }
  }

  .articleDetails {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;

    .detailsTitle {
      color: $slate-60;
      font-family: $ae-font-family-inter;
      font-size: $ae-font-size-xs;
      font-style: $ae-font-style-normal;
      font-weight: $ae-font-weight-medium;
    }

    .detailsContainer {
      font-family: $ae-font-family-inter;
      font-size: $ae-font-size-reg;
      font-style: $ae-font-style-normal;
      font-weight: $ae-font-weight-medium;
      color: $slate-80;
      cursor: pointer;

      .ticketSourceIcon {
        color: $viking-70;
        font-size: 1rem;
        margin-bottom: 0.25rem;

        &:hover {
          color: $slate-80;
        }
      }

      .ticketSource {
        color: $viking-70;
        padding-left: 0.25rem;
      }

      .disable {
        text-decoration-line: none;
        color: $gray-1;
        cursor: not-allowed;
      }

      .disableLink {
        cursor: not-allowed;
      }
    }

    .detailsContent {
      font-family: $ae-font-family-inter;
      font-size: $ae-font-size-reg;
      font-style: $ae-font-style-normal;
      font-weight: $ae-font-weight-medium;
      color: $slate-80;
    }
  }
}
