@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.archived-badge {
  color: var(--danger);
  background-color: var(--danger-faded);
  font-size: $ae-font-size-sm;
  font-weight: $ae-font-weight-medium;
  padding: $box-sizing-small $box-sizing-medium;
  border-radius: $border-radius-rounded;
}
