@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.dropdown.user {
  .avatar-container {
    position: relative;

    .avatar {
      cursor: pointer;
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      opacity: 0;
      background-color: var(--gray-500);
    }

    &:hover {
      .overlay {
        opacity: 0.3;
      }
    }
  }

  .popper {
    .content {
      font-size: $ae-font-size-sm;
      border: 1px solid var(--gray-100);
      font-weight: $ae-font-weight-regular;
      max-height: fit-content;
      user-select: none;
      min-width: 160px;

      .option {
        color: var(--gray-500);

        &:hover {
          color: var(--primary-hover);
          background-color: var(--gray-100);
        }
      }

      .option-header {
        color: var(--gray-500);
        font-size: $ae-font-size-mini;
        font-weight: $ae-font-weight-medium;
        padding: $box-sizing-medium $box-sizing-large 0;
        text-transform: uppercase;
      }

      .nav-link {
        padding: 0;

        &.active {
          .option {
            background-color: var(--gray-100);
            color: var(--primary);
          }
        }
      }

      .option.current-user {
        cursor: default;

        &:hover {
          background-color: var(--gray-000);
          color: var(--gray-500);
        }
      }
    }
  }
}
