@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_colors";

.codePreview {
  white-space: pre-wrap;
  word-break: break-word;
  overflow: auto;
  background: $whiteSmoke;
  padding: toRem(10px);
  border-radius: toRem(5px);
}
