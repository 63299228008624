.container {
  display: flex;
  height: 100%;
  align-items: start;
}

.containerIcon {
  margin-left: -0.25rem;
}

.containerText {
  margin-left: 0.75rem;
  margin-top: 0.5rem;

  h2 {
    color: #4f4f4f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    // line-height: 24px; /* 150% */
  }
}

.containerTime {
  display: flex;
  align-items: center;
  // margin-left: -2px;

  span {
    padding: 0;
  }

  p {
    margin-left: 0.5rem;
    margin-bottom: 0;
    color: #4f4f4f;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    // line-height: 20px; /* 142.857% */
  }
}
