@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.bulk-status-dropdown {
  display: inline-block;
  margin-left: $box-sizing-medium;
  margin-right: $box-sizing-medium;

  .toggle {
    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: var(--gray-100);
    border-radius: $border-radius-rounded;

    label {
      margin: 0 $box-sizing-large 0 $box-sizing-medium;
    }

    .selection {
      color: var(--gray-500);
    }

    .icon.chevron-down-icon {
      margin-left: $box-sizing-medium;
      color: var(--gray-600);
      font-size: 12px;
    }
  }

  .popper {
    .content {
      padding-top: 4px;
      padding-bottom: 4px;
      min-width: 115px;
      max-height: 20rem;

      .option {
        margin: 0 2px;
        padding: 4px 8px;
        border-radius: $border-radius-rounded;

        &.active {
          background-color: var(--gray-100);
        }
      }
    }
  }
}
