@import "@aptedge/lib-ui/src/styles/_boxes";

.edge-listing {
  > .page-content {
    background-color: var(--gray-000);

    > .content {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }
  }

  .header-bar-container {
    display: flex;
    margin-top: $box-sizing-xxl;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    min-height: 58px;
  }

  a {
    text-decoration: inherit;
    color: inherit;
    display: contents;
  }
}
