@import "@aptedge/lib-ui/src/styles/_mixins";

.auth-page-container {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .auth-card {
    max-width: 50%;
    background-color: var(--gray-000);
    padding: toRem(36px);
    border-radius: toRem(4px);
    border: 1px solid var(--gray-200);
    box-shadow: 0px 4px 8px 0 var(--shadow);
    .title {
      margin-bottom: 1rem;
      font-size: large;
    }
    .back-btn {
      width: max-content;
      background-color: white;
      border-radius: toRem(4px);
      box-sizing: border-box;
      border: 1px solid transparent;
      background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)),
        linear-gradient(rgb(227, 228, 229), rgb(208, 209, 212));
      background-origin: border-box;
      background-clip: padding-box, border-box;
      box-shadow: rgb(51 51 51 / 8%) 0px 1px 4px;
      color: rgb(51, 51, 51);
      max-width: 100%;
      padding: toRem(8px) toRem(10px);
    }
  }
}
