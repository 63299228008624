.heading {
  color: #4f4f4f;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}

.articleFilterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
