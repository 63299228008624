@import "../../styles/_colors";
@import "../../styles/_typography";
@import "../../styles/_boxes";
@import "../../styles/_mixins";

.tiptap-editor {
  position: relative;
  border: 1px solid $border-color;
  border-radius: $box-sizing-regular;
  padding: $box-sizing-large;
  background-color: $slate-20;

  .menu-bar {
    position: sticky;
    left: $box-sizing-large;
    display: flex;
    gap: $box-sizing-medium;
    padding-bottom: $box-sizing-large;
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
  }

  .menu-bar button {
    background-color: $gray-100;
    border: none;
    color: $true-black;
    padding: toRem(5px) toRem(10px);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: $ae-font-size-reg;
    border-radius: $box-sizing-regular;
    cursor: pointer;
  }

  .menu-bar button:hover {
    background-color: $gray-300;
  }

  .heading {
    background-color: $gray-100;
    display: flex;
    align-items: center;
    padding: 0.3125rem 0.625rem;
    display: inline-block;
    border-radius: $border-radius-standard;
    cursor: pointer;
    font-weight: $ae-font-weight-medium;

    span {
      padding-right: 0.5rem;
    }
  }

  .ProseMirror {
    overflow-y: auto;
    overflow-x: hidden;
    color: $gray-2;
    font-family: $ae-font-family-inter;
    font-size: $ae-font-size-medium;
    font-style: $ae-font-style-normal;
    font-weight: $ae-font-weight-regular;

    &:focus {
      outline: none;
    }
  }

  a {
    color: $linkColor;
    cursor: pointer;
  }

  code {
    background-color: $gray-600;
    border-radius: 0.4rem;
    color: $white;
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: $true-black;
    border-radius: 0.5rem;
    color: $white;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: toRem(3px) solid $slate-60;
    padding-left: 1rem;
  }

  .code-icon {
    height: 1.25rem;
  }
}
