@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.container {
  .header {
    margin-bottom: 1rem;
    h5 {
      color: var(--Viking-Viking-90, #123236);
      font-family: Inter;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem; /* 120% */
      margin-bottom: 0.25rem;
    }
    span {
      font-size: 1rem;
    }
  }

  em {
    font-style: normal;
    font-weight: 600;
  }

  .articleCard {
    margin-bottom: 0.5rem;
    color: $slate-70;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    line-height: 1.25rem;
    cursor: pointer;
    &:hover {
      background-color: $slate-10;
    }
  }
  .title {
    margin-bottom: 0;
    color: $slate-90;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    margin-bottom: 0.25rem;
  }
  .results {
    .title {
      word-break: break-word;
    }
    .content {
      font-size: 1rem;
      margin-top: 0.25rem;
      p {
        margin: 0;
      }

      @include textEllipsis(2);
    }
  }
}
