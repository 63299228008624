@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.federated-list-item {
  .federated-url {
    word-break: break-all;
  }

  .info {
    font-size: toRem(13px);
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: var(--gray-500);
    font-family: $ae-font-family-inter;
    margin-top: toRem(8px);
  }
  .img-icon {
    position: relative;
    bottom: 1px;
    height: 14px;
    margin-right: 1rem;
  }
}
