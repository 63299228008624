.container {
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.articlesTableContainer {
  margin-top: -1.5rem;
}
