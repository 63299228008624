@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";

.global-search-page-container {
  margin: 0 5%;

  .global-search-page-main {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;
  }

  .global-search-controls-container {
    display: flex;
    align-items: center;
    gap: toRem(8px);
  }

  .global-search-page-results {
    display: flex;
    flex-direction: row;
  }

  .global-search-results-container {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  .global-search-page-analytics-sort-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .global-search-container {
    display: flex;
    justify-content: space-between;
    margin-right: 1rem;
    align-items: flex-start;

    .sortDropDown {
      background-color: white;
      border: none;
      width: max-content;
      margin: toRem(4px) 0 toRem(12px) 0;
      &:focus {
        outline: none;
      }
    }
    .list-header {
      display: grid;
      justify-items: end;
    }
  }

  // Global search filter css
  .vertical-seprator {
    border: 0.125rem solid var(--gray-300);
    border-radius: 0.5rem;
    height: 1.25rem;
    margin: auto 1rem;
  }

  .global-search-filter-container {
    display: flex;
    width: 60%;

    .txt {
      line-height: 1.0625rem;
      font-size: $ae-font-size-reg;
      color: var(--gray-500);
      padding-left: 0.125rem;
    }

    .txt-seleted {
      color: var(--teal);
    }

    .global-search-filter-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      margin: 0;
      padding: 0 0.5rem;
      cursor: pointer;

      * {
        transition: color 200ms, filter 200ms;
      }

      &:hover:not(.disable) {
        .txt {
          color: var(--teal);
        }

        img {
          color: var(--teal);
          filter: brightness(0) saturate(100%) invert(34%) sepia(76%)
            saturate(520%) hue-rotate(142deg) brightness(97%) contrast(99%);
        }
      }

      &.disable {
        cursor: not-allowed;
      }
    }
  }

  .global-search-filter-wrapper-all {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0px 0.5rem;

    * {
      transition: color 200ms, filter 200ms;
    }

    &:hover {
      .txt {
        color: var(--teal);
      }

      svg {
        color: var(--teal);
        filter: brightness(0) saturate(100%) invert(34%) sepia(76%)
          saturate(520%) hue-rotate(142deg) brightness(97%) contrast(99%);
      }
    }
  }

  .global-search-filter-item {
    img {
      position: relative;
      bottom: 1px;
      margin-right: 0.1rem;
    }
  }

  .global-search-filter-icon {
    color: var(--gray-500);
  }

  .global-search-filter-selected {
    background: var(--primary-100);
    border-radius: 0.25rem;

    .txt {
      color: var(--teal);
    }

    .global-search-filter-selected-icon {
      color: var(--teal);
      filter: brightness(0) saturate(100%) invert(34%) sepia(76%) saturate(520%)
        hue-rotate(142deg) brightness(97%) contrast(99%);
    }
  }

  .skeleton-loaders {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .loader {
      margin: 0 1rem;
      flex-grow: 1;
    }
  }
}

.ai-infotip {
  color: var(--gray-500);
  font-weight: 600;
  background-color: #f9fafb;
  padding: 0.75em;
  border-left: 0.25em solid #077789;
  border-radius: 0.2rem;

  .info-icon {
    margin-left: 0.5em;
    margin-right: 0.75em;
  }
}
