@import "@aptedge/lib-ui/src/styles/_typography";

.edge-name-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-wrap: wrap;
  & &-name {
    display: block;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: $ae-font-weight-bold;
    white-space: nowrap;
    max-width: 85%;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    min-height: 0;
    margin-right: 0 !important;
  }
  & &-chips {
    flex-basis: 100%;
  }
  & &-badges {
    display: flex;
  }
}
