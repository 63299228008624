@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.autoCompleteSuggestionWrapper {
  width: 60%;
  margin-bottom: 0.5rem;

  .autoCompleteSearch {
    box-sizing: border-box;
    background: var(--gray-100);
    border-radius: 0.5rem 0.5rem 0px 0px;
    margin: 1.125rem 0px 0px 0px;
    padding: 0.625rem;
    padding-left: 1rem;
    align-items: center;
    display: flex;
  }

  .suggestSearchWrapper {
    position: relative;
    margin-right: -2px;
  }

  .autoCompleteSearch:hover,
  .autoCompleteSearch:focus-within {
    outline: 1px solid var(--teal);
  }

  .suggestSearchResults {
    z-index: 9;
    position: absolute;
    background: white;
    border: 1px solid teal;
    border-radius: 0px 0px 0.5rem 0.5rem;
    margin-left: -0.063rem;
    width: 100%;
    :last-child {
      border-radius: 0px 0px 0.5rem 0.5rem;
    }
  }

  .searchBottomBorder {
    border-radius: 0.5rem;
  }

  .autoCompleteBorder {
    outline: 1px solid var(--teal);
  }
}

.autoCompleteSuggestedAnswerWrapper {
  padding: 0.75rem 0;
}

.autoCompleteSuggestionWrapperV1 {
  width: 100%;
}
