@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_mixins";

.federated-search-result {
  display: flex;
  flex-direction: column;
  user-select: none;
  padding: $box-sizing-large;
  margin: $box-sizing-medium;
  border-radius: $border-radius-rounded;
  transition: all 0.2s;
  color: var(--gray-800);
  border: 1px solid $border-color;

  .federated-header {
    display: flex;
    align-items: baseline;
    .subject {
      @include textEllipsis(1);
      color: var(--gray-900);
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-bold;
    }
    .federated-header-action-icons {
      margin-left: auto;
    }
  }

  .federated-description {
    margin-top: $box-sizing-large;
    color: var(--gray-600);
    font-size: $ae-font-size-sm;
    margin-bottom: $box-sizing-large;
    overflow-y: auto;
    max-height: 5rem;
    word-break: break-word;
    @include textEllipsis(2);
  }

  &:hover {
    text-decoration: none;
  }
}
