@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  display: flex;
  gap: 0.5rem;
}

.saveButton {
  display: flex;
  width: toRem(132px);
  padding: toRem(10px) toRem(18px);
  justify-content: center;
  align-items: center;
  gap: toRem(8px);
  flex-shrink: 0;
  border-radius: toRem(4px);
  border: 1px solid #036172;
  background: $teal;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: $white;
  font-family: $ae-font-family-inter;
  font-size: toRem(16px);
  font-weight: $ae-font-weight-semi-bold;
  line-height: toRem(24px);
}

.saveButton:hover {
  background: $teal-rd-700;
}

.cancelButton {
  display: flex;
  padding: toRem(10px) toRem(18px);
  justify-content: center;
  align-items: center;
  gap: toRem(8px);
  border-radius: toRem(8px);
  border: 1px solid $gray-rd-300;
  background: $white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: $gray-rd-700;
  font-family: Inter;
  font-size: toRem(16px);
  font-weight: $ae-font-weight-semi-bold;
  line-height: toRem(24px);
}

.cancelButton:hover {
  background: $gray-rd-100;
}
