@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_colors.scss";

.channelSelect {
  display: flex;

  .channelSelectToggle {
    border-radius: $border-radius-rounded;
    color: var(--gray-800);
    font-size: $ae-font-size-reg;
    font-weight: $ae-font-weight-medium;
    margin-top: $box-sizing-regular;
    margin-bottom: $box-sizing-regular;
    margin-right: $box-sizing-regular;
    font-family: $ae-font-family;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    border: toRem(1px) solid $light-gray;
    padding: toRem(6px) toRem(12px);

    > * {
      padding: $box-sizing-regular;

      > span {
        padding: $box-sizing-regular;
      }
    }
  }

  > .popper .content {
    max-height: fit-content;
    width: toRem(750px) !important;
    padding: toRem(8px) 0 !important;

    .option {
      display: flex;
      justify-content: space-between;
      padding: toRem(6px) toRem(16px);
      cursor: default;
    }
  }

  .channelSelectItem {
    color: var(--gray-800);
    font-size: $ae-font-size-reg;
    font-weight: $ae-font-weight-medium;
    font-family: $ae-font-family;
  }

  .checkboxContainer {
    display: flex;
    width: fit-content;
    margin-bottom: $box-sizing-medium;
    cursor: pointer;

    .checkbox {
      margin-right: $box-sizing-regular;
    }
  }

  .toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.delete {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: toRem(10px);
}

.channellistContainer {
  max-height: toRem(135px);
  overflow-y: auto;
}

.btnContainer {
  border-top: toRem(1px) solid $light-gray;
  padding: 0 toRem(16px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .selectAll {
    display: flex;
    margin-top: 0.5rem;

    .selectAllCheckbox {
      margin-right: 0.5rem;
    }
  }

  .updateChannel {
    display: flex;
    margin-top: 0.5rem;
  }
}

.lockIconContainer {
  margin-left: toRem(10px);
}
