@import "../../styles/boxes";
@import "../../styles/typography";

.date-range-shortcuts {
  background-color: var(--gray-000);
  font-size: $ae-font-size-sm;
  color: var(--gray-700);
  padding-right: $box-sizing-medium;
  user-select: none;

  .shortcut {
    cursor: pointer;
    padding: $box-sizing-medium 0;

    &:hover {
      color: var(--primary-hover);
    }

    &.selected {
      font-weight: $ae-font-weight-semi-bold;
      color: var(--primary);
    }

    &.disabled {
      pointer-events: none;
      color: var(--gray-300);
    }
  }
}
