@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.ae-badge {
  background-color: var(--primary-100);
  color: var(--primary);
  font-weight: $ae-font-weight-medium;
  padding: $box-sizing-small $box-sizing-medium;
  border-radius: $border-radius-standard;
  height: fit-content;
}
