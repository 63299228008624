@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";

a {
  color: unset;
  &:hover {
    text-decoration: unset;
  }
}
.teams_card_container {
  font-family: $ae-font-family-inter;
  user-select: none;
  padding: toRem(16px);
  border-radius: $border-radius-rounded;
  border: 1px solid $border-color;
  &:hover {
    background-color: var(--gray-100);
  }
  &.active {
    background-color: var(--gray-100);
  }
  .teams_card_header {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #353d3f;
    margin-bottom: toRem(14px);
    height: 2rem;

    .teams_card_header_channel {
      font-weight: 700;
      padding-top: toRem(4px);
      margin-left: toRem(12px);
    }
    .teams_card_header_icon {
      width: toRem(24px);
    }
    .social-header-action-icons {
      margin-left: auto;
    }
  }
  .teams_card_body {
    font-size: $ae-font-size-sm;
  }
}
