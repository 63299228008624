@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_colors";

.feedbackContent {
  display: flex;
  flex-direction: column;
  height: 0;
  opacity: 0;

  .feedbackContainer {
    background: #f4f4f4;
    padding: 1rem;
    border-radius: $border-radius-standard;
    font-family: $ae-font-family-inter;
    font-size: $ae-font-size-sm;
    font-weight: $ae-font-weight-medium;
    color: #4f4f4f;

    .feedbackLabels {
      display: flex;
      flex-direction: column;

      .feedbackOption {
        outline: none;
        border: 1px solid #c6cdcf;
        padding: 0.25rem 0.5rem 0.35rem 0.5rem;
        border-radius: $border-radius-standard;
        background: $white;
        color: #718286;
        margin-right: toRem(8px);
        margin-top: toRem(10px);
        font-family: $ae-font-family-inter;
        font-size: $ae-font-size-sm;
        font-weight: $ae-font-weight-medium;

        &:last-child {
          margin-right: 0;
        }
      }

      .feedbackOptionSupportApp {
        font-size: $ae-font-size-support-app;
      }

      .feedbackOption:hover,
      .optionSelected {
        border: 1px solid #007777;
        color: #007777;
        font-family: $ae-font-family-inter;
        font-weight: $ae-font-weight-medium;
        background: #acefef;
      }
      .optionSelected:hover {
        background: #86d8d8;
      }
    }

    .submitFeedback {
      display: flex;
      flex-direction: column;
      align-items: start;

      .inputFeedback {
        border: 1px solid #c4c4c4;
        background-color: $white;
        padding: toRem(10px);
        border-radius: $border-radius-standard;
        width: 80%;
        max-width: toRem(700px);
        outline: none;
        font-size: $ae-font-size-sm;
        font-weight: $ae-font-weight-medium;
        margin-top: toRem(10px);
        resize: none;

        ::placeholder {
          color: #c4c4c4;
        }
      }

      .inputFeedbackSupportApp {
        font-size: $ae-font-size-support-app;
      }

      .submitButton {
        margin-top: toRem(10px);
      }

      .submitButtonSupportApp {
        font-size: $ae-font-size-support-app;
      }
    }

    .negativeFeedbackButton,
    .positiveFeedbackButton {
      outline: none;
      border: none;
      background-color: transparent;
    }

    .positiveFeedbackButton {
      margin-left: 1rem;
    }

    .thumbsUpIcon,
    .thumbsDownIcon {
      path {
        stroke: $icon-default;
      }
      &:hover {
        path {
          stroke: $icon-hover;
        }
      }
    }
  }

  .feedbackContainerSearchResult {
    border-radius: 0 0 0.5rem 0.5rem;
  }

  .feedbackContainerSupportApp {
    border-radius: $border-radius-standard;
    padding: 0.5rem 0.8rem;
  }
}

.showFeedback {
  opacity: 1;
  height: auto;
}

.feedbackAlwaysVisible {
  position: sticky;
  bottom: toRem(-5px);
}
