@import "@aptedge/lib-ui/src/styles/_mixins";

.dropdownItemContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  .dropdownItem {
    img {
      height: toRem(18px);
      width: toRem(18px);
      margin-right: 0.5rem;
    }
  }
  .dropdownCheck {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }
}
