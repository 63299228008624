@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
.global-search-page-preview {
  font-family: "Inter";
  max-height: calc(100vh - 6.5rem);
  word-wrap: break-word;
  position: sticky;
  position: -webkit-sticky;
  top: toRem(80px);
  border: 1px solid $border-color;
  border-radius: $border-radius-rounded;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 1rem;
  height: max-content;

  // See DEV-5770. I think the actual width is greater than 20%, but just *setting* the width
  // fixes the issue and allows the search preview to take up the remainder of the space (as
  // intended).
  width: 20%;

  .preview-content {
    padding: 1rem;
    overflow-y: auto;

    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 18px;
    }
    h3 {
      font-size: 16px;
    }
    h4 {
      font-size: 14px;
    }
    h5 {
      font-size: 13px;
    }
    h6 {
      font-size: 12px;
    }

    &::-webkit-scrollbar {
      width: toRem(4px);
      height: toRem(4px);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--gray-500);
      border-radius: toRem(8px);
    }

    &::-webkit-scrollbar-track {
      background-color: $scroll-background-color;
      border-radius: toRem(8px);
      width: toRem(4px);
      height: toRem(4px);
    }
  }
}

iframe {
  width: 100%;
  height: 75vh;
  border: 1px solid $gray-400;
}

.preview-link {
  color: #1570ef;
  font-size: small;
  cursor: pointer;
}

.search-result-preview-text {
  overflow-x: auto;
  white-space: normal;
  a {
    color: #1570ef;
  }

  table {
    margin: 1rem 0;
    overflow-x: scroll;
    margin-right: 1rem;
  }

  table,
  th,
  td {
    border: 1px solid black;
  }

  th,
  td {
    padding: toRem(4px) toRem(8px);
  }

  .preview-highlight {
    font-style: normal;
    background-color: $highlight-color;
    padding: 0rem 0.25rem;
  }

  .offset-highlight {
    font-style: normal;
    background-color: #d6edf2;
  }
}

.header_container {
  font-size: toRem(12px);
  color: var(--gray-500);
  cursor: default;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .details {
      font-size: toRem(12px);
      color: var(--gray-500);
      width: 90%;

      .header-icon {
        .edge-icon {
          svg {
            rect {
              fill: #06778c;
            }
          }
        }
        svg {
          width: toRem(18px);
          height: toRem(18px);
        }
      }

      .img-icon {
        position: relative;
        bottom: 1px;
        height: 14px;
        margin: 4px;
      }

      .header-type {
        text-transform: capitalize;
      }

      .updated-date {
        white-space: nowrap;
      }
    }

    .header-action-icons {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .icon {
        path {
          stroke-width: 2;
          stroke: $icon-default;
        }
        &:hover {
          path {
            stroke: $icon-hover;
          }
        }
      }

      .linkIcon {
        path {
          stroke-width: 0.2;
          fill: $icon-default;
        }
        &:hover {
          path {
            fill: $icon-hover;
          }
        }
      }
    }

    .close-icon-preview-header {
      outline-color: transparent;
      background-color: white;
      border: none;
      cursor: pointer;
      padding: 0;

      svg {
        path {
          stroke: $gray-500;
        }
      }
    }
  }
}

.preview-title {
  margin-top: toRem(30px);

  h5 .search-result-preview-text {
    font-style: normal;
    font-weight: $ae-font-weight-bold;
    font-size: toRem(24px);
    line-height: toRem(28px);
    color: var(--gray-900);
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(12px);
    line-height: toRem(18px);
    color: var(--blue-600);
    margin: toRem(16px) 0;
    cursor: pointer;
  }

  .chip-list {
    margin-bottom: 1rem;

    .label-chip-list {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.loader-body {
  margin-top: -6%;
}

.ticket-header {
  font-size: $ae-font-size-xs;
  font-weight: $ae-font-weight-semi-bold;
}

.comment-author {
  font-weight: $ae-font-weight-semi-bold;
  padding-right: $box-sizing-medium;
  color: var(--gray-700);
}

.comment-age {
  color: var(--gray-500);
}

.social-body {
  font-size: $ae-font-size-reg;

  .offset-highlight {
    font-style: normal;
    background-color: #d6edf2;
  }
}

.comment-container {
  margin-top: toRem(10px);
  display: flex;
  flex-direction: column;
}

.social-thread {
  margin-bottom: 0px;
  font-size: $ae-font-size-reg;
  margin-top: toRem(5px);
}

.rendered-img {
  img {
    width: 100%;
  }
}
.text-editor {
  padding: 0;

  .rendered {
    overflow-y: auto;
    max-height: toRem(300px);
    background-color: var(--gray-100);
    border: 1px solid var(--gray-100);
    border-radius: $border-radius-standard;

    &:hover {
      border-color: var(--gray-200);
    }

    &::-webkit-scrollbar {
      width: toRem(4px);
      height: toRem(4px);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--gray-500);
      border-radius: toRem(8px);
    }

    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
      border-radius: toRem(8px);
      width: toRem(4px);
      height: toRem(4px);
    }

    .markdown-content {
      padding: 8px;
      font-size: $ae-font-size-xs;
    }
  }
}

.main-section {
  border-radius: $border-radius-standard;
  margin-top: $box-sizing-large;

  .description-content {
    overflow-y: auto;
    max-height: toRem(300px);

    &::-webkit-scrollbar {
      width: toRem(4px);
      height: toRem(4px);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--gray-500);
      border-radius: toRem(8px);
    }

    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
      border-radius: toRem(8px);
      width: toRem(4px);
      height: toRem(4px);
    }
  }

  .ticket-thread {
    padding-right: toRem(16px);

    .comment {
      display: flex;

      & > :last-child {
        width: 100%;
      }

      .comment-header {
        padding-bottom: $box-sizing-regular;

        .name {
          font-weight: $ae-font-weight-semi-bold;
          padding-left: $box-sizing-medium;
          color: var(--gray-700);
        }

        .comment-age {
          color: var(--gray-500);
        }

        & > :last-child {
          font-size: $ae-font-size-xs;
          color: var(--gray-500);
        }

        .internal {
          margin-left: $box-sizing-regular;
          font-size: $ae-font-size-mini;
          color: var(--gray-500);
        }
      }

      .comment-body {
        padding: $box-sizing-medium;
        border-radius: $border-radius-standard;
        margin-bottom: $box-sizing-large;

        &.internal {
          background-color: var(--yellow);
          margin-left: toRem(8px);
        }
      }
    }
  }

  pre {
    margin-bottom: 0;
    white-space: pre-wrap;
    font-family: $ae-font-family;
    font-size: $ae-font-size-xs;
    overflow: hidden;
  }
}

.no-data {
  font-family: $ae-font-family;
  font-size: $ae-font-size-xs;
  text-align: start;
  background-color: var(--gray-100);
  color: var(--gray-600);
  padding: 8px;
  border-radius: 4px;
}

.global-search-preview-container {
  position: relative;
  max-width: 100%;
}
