@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_mixins.scss";

.edge-link-widget {
  .favicon {
    vertical-align: middle !important;
  }

  svg.favicon {
    padding: toRem(1px);
    margin: toRem(2px);
    width: toRem(16px);
    height: toRem(16px);
  }

  .icons {
    .icon-btn {
      visibility: hidden;
      cursor: pointer;
      padding: toRem(4px);
      color: hsl(0, 0, 60);
      transition: color 400ms, background-color 400ms ease-in-out;
      border-radius: toRem(4px);

      a {
        text-decoration: none;
        color: inherit !important;
      }

      &:hover {
        background-color: hsl(0, 0, 95);
        color: var(--primary);

        &.delete {
          color: var(--danger);
          margin-top: toRem(4px);
        }
      }
    }
  }

  .list-group-item {
    word-break: break-all;
    padding-right: 0;
    .icons {
      padding: toRem(4px);
    }
    &:hover {
      cursor: pointer;
      .icons .icon-btn {
        visibility: visible;
      }
    }
  }
}
