@import "@aptedge/lib-ui/src/styles/_colors.scss";
@import "@aptedge/lib-ui/src/styles/_typography.scss";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";
@import "@aptedge/lib-support-ui/src/styles/mixins.scss";

.discardContainer {
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  max-width: toRem(370px);
  width: toRem(370px);
  position: absolute;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-rounded;
  height: toRem(130px);
  z-index: 1;

  .tooltipArrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white; /* Make the arrow point down */
    bottom: -10px; /* Position it below the tooltip */
    left: 50%;
    transform: translateX(-50%);
  }

  .tooltipArrowReviewPage {
    left: 70%;
  }

  .discardLeftContainer {
    .infoIcon {
      font-size: 1.25rem;
      cursor: pointer;
      path {
        fill: $orange-1;
      }
    }
  }

  .discardRightContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .discardHeader {
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-semi-bold;
      font-family: $ae-font-family-inter;
      color: $gray-2;
    }

    .discardBody {
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-medium;
      font-family: $ae-font-family-inter;
      color: $gray-2;
    }

    .discardActions {
      display: flex;
      gap: 0.5rem;
      font-size: $ae-font-size-reg;
      font-weight: $ae-font-weight-medium;

      .confirm {
        background: $viking-1;
        color: $white;
        border-radius: $border-radius-standard;
        padding: 0.25rem 0.5rem;
        cursor: pointer;
      }

      .cancel {
        background: $white;
        border-radius: $border-radius-standard;
        border: 1px solid $gray-rd-200;
        padding: 0.25rem 0.5rem;
        cursor: pointer;
      }
    }
  }
}

.discardContainerArticlePopupOrReview {
  bottom: 4.5rem;
  right: 1rem;
  box-shadow: 10px 10px 10px 10px #00000014;
}
