@import "@aptedge/lib-ui/src/styles/_colors";

.support-app-settings {
  input.form-control {
    font-size: 13px;
    box-shadow: none !important;
    border-color: hsl(0, 0, 95);
    border-right-color: transparent;

    &.disabled,
    &:read-only {
      background-color: hsl(0, 0, 98);
    }
  }

  .btn {
    font-size: 13px;
    box-shadow: none !important;

    .icon {
      margin-top: 1px;
    }

    &.clippy {
      background-color: white;
      border-color: hsl(0, 0, 90);
      color: hsl(0, 0, 40);

      &:hover {
        background-color: hsl(0, 0, 90);
        color: hsl(0, 0, 20);
      }
      &:active {
        background-color: hsl(0, 0, 80);
      }
    }
    &.generate {
      border-color: $primary;
      background-color: $primary;
      color: white;

      &:hover {
        background-color: lighten($color: $primary, $amount: 2);
      }
      &:active {
        background-color: lighten($color: $primary, $amount: 5);
      }
    }
  }

  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
