@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_mixins";

.title {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  margin-bottom: 1rem;
  h6 {
    margin-bottom: 0;
    color: $slate-60;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
  }
}
.card {
  border-radius: $primary-border-radius;
  background-color: $viking-00;
  &:hover {
    background-color: $slate-00;
    .cardItem {
      background-color: $slate-00;
    }
  }
  em {
    font-style: normal;
    font-weight: 600;
  }
  .resultTitle {
    margin-bottom: 0.25rem;
    color: $slate-90;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    word-break: break-word;
  }
  .title {
    color: $slate-90;
    font-family: $ae-font-family-inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
  }
  .cardItem {
    border-radius: $primary-border-radius;

    cursor: pointer;
    padding: 0.5rem;
    .title {
      h6 {
        margin-left: 0;
      }
    }
    .content {
      font-size: 1rem;
      color: $slate-70;
      margin-top: 0.25rem;
      p {
        margin: 0;
      }
      @include textEllipsis(2);
    }
    &:hover {
      background-color: $slate-10;
    }
  }
  .resourceItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    :global(.ae-icon) {
      color: $slate-40;
    }
  }
}
