@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.searchResultContainer {
  height: 100%;
  .relatedArticlesBtn {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    border-radius: $primary-border-radius;
    padding: 0.75rem;
    cursor: pointer;
    background-color: $viking-00;
    &:hover {
      background-color: $slate-00;
    }
    .leftSection {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 0.4rem;
      }
      .label {
        margin-left: 0.25rem;
        color: $slate-80;

        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1rem;
      }
    }
    .rightSection {
      display: flex;
      align-items: center;
      .label {
        display: flex;
        padding: 0.125rem 0.5625rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        border-radius: $primary-border-radius;
        background: $slate-70;
        color: #fff;

        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1rem; /* 133.333% */
      }
      :global(.ae-icon) {
        font-weight: 700;
        font-size: 3rem;
      }
    }
  }

  .workaroundLoaderContainer {
    margin: -20px 0 -20px 0;
  }
  .workaroundLoader {
    background-color: rgba(67, 24, 255, 0.11);
    height: 60px;
    border-radius: 6px;
  }

  .responseReason {
    display: grid;
    gap: 0.5rem 0;
    margin: 2.56rem 0.8rem;
    h5 {
      color: #2b3674;
      font-family: "DM Sans";
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.02rem;
      margin-bottom: 0;
    }
    .reasonCard {
      border-radius: 0.5rem;
      border: 0.5px solid #4318ff;
      background: #f6f4fe;
      .title {
        padding: 1rem;
        background-color: #8d79de;
        color: var(--Slate-Slate-00, #fff);
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        line-height: 1rem; /* 114.286% */
        letter-spacing: -0.0175rem;
        display: flex;
        align-items: center;
        span {
          margin-left: 0.75rem;
        }
      }
      .description {
        padding: 1rem;
        color: #2b3674;
        font-family: "DM Sans";
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 142.857% */
        letter-spacing: -0.0175rem;
      }
    }
  }
  .stepsTitle {
    color: #2b3674;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.02rem;
    margin: 0.5rem 0.8rem;
  }
  .steps {
    border-radius: 0.5rem;
    border: 0.5px solid #4318ff;
    background: #f6f4fe;
    margin-top: 0.5rem;
    color: #2b3674;
    font-family: "DM Sans";
    padding: 1.5rem;
    margin: 0 0.8rem;
  }
  .aiTourBtn {
    margin: 1rem 0.8rem 0;
    animation-delay: 0s;

    &:hover {
      // prevent animating in the loading spinner when the button is clicked
      animation-duration: 0s;
    }

    border-radius: 0.5rem;
    border-color: transparent;
    background: linear-gradient(180deg, #4318ff 0%, #280f99 100%);
    width: 96%;
    color: var(--Slate-Slate-00, #fff);
    text-align: center;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 171.429% */
    letter-spacing: -0.0175rem;
    padding: 0.7rem 0;
    transition: all 0.2s;
    &:hover,
    &:active {
      filter: drop-shadow(0px 2px 4px #aaa);
      &:disabled {
        filter: none;
      }
    }
    &:disabled {
      background: #f6f4fe;
    }

    span {
      position: relative;
      top: 1px;
    }
  }
  .feedbackBtn {
    margin-top: 1rem;
    animation-delay: 0s;
    color: #949fdd;
    text-align: center;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
    letter-spacing: -0.0175rem;
    background-color: transparent;
    border: none;
    width: 100%;
    outline: none;
    transition: all 0.2s;
    &:hover {
      background-color: #fff;
      color: #4318ff;
    }
  }
  .loaderContainer {
    .loader {
      width: 15px;
      aspect-ratio: 1;
      border-radius: 50%;
      margin: 2px auto;
      animation: l5 1s infinite linear alternate;
    }
    @keyframes l5 {
      0% {
        box-shadow: 20px 0 #4318ff, -20px 0 #ae9cff;
        background: #4318ff;
      }
      33% {
        box-shadow: 20px 0 #4318ff, -20px 0 #ae9cff;
        background: #ae9cff;
      }
      66% {
        box-shadow: 20px 0 #ae9cff, -20px 0 #4318ff;
        background: #ae9cff;
      }
      100% {
        box-shadow: 20px 0 #ae9cff, -20px 0 #4318ff;
        background: #4318ff;
      }
    }
  }
}

.answer {
  color: #2b3674;
  font-family: "DM Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: -0.0175rem;
}
.feedbackTitle {
  font-size: 1rem;
  display: flex;
  align-items: center;
  .chatIcon {
    margin-right: 0.5rem;
  }
}
