@import "../../styles/boxes";
@import "../../styles/typography";

.chip {
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: $ae-font-size-sm;
  border: 1px solid;
  border-color: var(--gray-300);
  border-radius: $border-radius-standard;
  background-color: var(--gray-000);
  color: var(--gray-600);
  padding: $box-sizing-regular $box-sizing-medium;

  .label,
  .icon {
    font-size: inherit;
    flex: 1 1 auto;
    outline: none;
  }

  .delete {
    position: absolute;
    transition: all 200ms;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    color: var(--gray-300);
    visibility: hidden;
  }

  &.deleteable:hover {
    .delete {
      visibility: visible;
    }
  }

  &:not(.disabled) {
    .icon:hover {
      cursor: pointer;
      color: var(--gray-400);
    }
  }

  &.disabled {
    color: var(--gray-100);

    .icon {
      color: inherit;
      cursor: default;
    }
  }
}
