@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.bulk-status-change {
  font-size: $ae-font-size-sm;

  .checkbox-container {
    display: flex;
    width: fit-content;
    margin-bottom: $box-sizing-medium;
    cursor: pointer;

    .checkbox {
      margin-right: $box-sizing-regular;
    }
  }

  .bold {
    color: var(--gray-900);
    font-weight: $ae-font-weight-bold;
  }
}
