.edge-bulk-reply-status {
  font-size: 10px;
  line-height: 140%;
  color: var(--gray-500);

  .progress-container {
    display: flex;
    align-items: center;

    .progress-bar {
      width: 80px;
      margin-right: 12px;
    }
  }

  .icon {
    border-radius: 100%;
    padding: 3px 2px 2px;
    font-size: 10px;
    margin-right: 12px;

    &.check-icon {
      background-color: var(--primary-300);
      color: var(--primary-500);
    }
    &.exclamation-icon {
      background-color: var(--yellow-300);
      padding-right: 5px;
      padding-left: 5px;
      color: #f0935b;
    }
  }
}
