@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.description {
  color: $gray-rd-700;
  font-family: $ae-font-family-inter;
  font-size: 1rem;
  font-style: $ae-font-style-normal;
  font-weight: $ae-font-weight-regular;
  line-height: 1.25rem;
  margin: 0;
  padding: 0;
}
