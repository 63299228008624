@import "@aptedge/lib-ui/src/styles/_animations";
@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.ticket-content {
  word-break: break-word;
  color: var(--gray-700);

  .avatar {
    margin-right: $box-sizing-medium;
  }

  .ticket-info {
    display: flex;
    justify-content: space-between;
  }

  .pill {
    margin: $box-sizing-small;
    background-color: var(--gray-000);
    color: var(--gray-900);
    border: 1px solid var(--gray-100);
  }

  .subject {
    color: var(--gray-800);
    margin-bottom: $box-sizing-large;
  }

  .created-on {
    font-size: $ae-font-size-xs;
    color: var(--gray-500);
    margin-bottom: $box-sizing-xxl;
    padding-bottom: $box-sizing-large;
    border-bottom: 1px solid var(--gray-200);
  }

  .highlighted {
    padding-top: $box-sizing-regular;
    border-bottom: 1px solid var(--danger);
    cursor: pointer;
    transition: all 200ms;

    &:hover {
      background-color: var(--danger-hover);
    }
  }

  .edge-link {
    padding: $box-sizing-regular $box-sizing-medium;
    background-color: var(--gray-100);
    color: var(--gray-700);
    transition: all 200ms;
    border-radius: $border-radius-standard;
    margin: $box-sizing-small;
    width: fit-content;

    &:not(.disabled) {
      cursor: pointer;

      &:hover {
        background-color: var(--gray-200);
      }
    }

    &.disabled {
      color: var(--gray-500);
      background-color: var(--gray-100);
    }
  }

  h4 {
    font-size: $ae-font-size-reg;
    color: var(--gray-800);
  }

  main {
    width: calc(100% - 200px);
    margin-right: $box-sizing-xxxl;

    & > :not(:last-child) {
      margin-bottom: $box-sizing-xl;
    }

    h3 {
      color: var(--gray-800);
    }

    .main-section {
      border-radius: $border-radius-standard;
      margin-top: $box-sizing-large;

      &.agent-description {
        .text-editor {
          padding: 0;

          .rendered {
            background-color: var(--gray-100);
            border: 1px solid var(--gray-100);
            border-radius: $border-radius-standard;

            &:hover {
              border-color: var(--gray-200);
            }
          }
        }
      }

      .description-content {
        padding: $box-sizing-large;
      }

      .ticket-thread {
        padding: $box-sizing-large;
        .comment {
          display: flex;

          & > :last-child {
            width: 100%;
          }

          .comment-header {
            padding-bottom: $box-sizing-regular;

            .name {
              font-weight: $ae-font-weight-semi-bold;
              padding-left: $box-sizing-medium;
              color: var(--gray-700);
            }

            .comment-age {
              color: var(--gray-500);
            }

            & > :last-child {
              font-size: $ae-font-size-xs;
              color: var(--gray-500);
            }

            .internal {
              margin-left: $box-sizing-regular;
              font-size: $ae-font-size-mini;
              color: var(--gray-500);
            }
          }

          .comment-body {
            padding: $box-sizing-medium;
            border-radius: $border-radius-standard;
            margin-bottom: $box-sizing-large;

            &.internal {
              background-color: var(--yellow);
            }
          }
        }

        & > :not(:last-child) {
          margin-bottom: $box-sizing-xl;
        }
      }

      pre {
        margin-bottom: 0;
        white-space: pre-wrap;
        font-family: $ae-font-family;
        font-size: $ae-font-size-reg;
        overflow: hidden;
      }
    }
  }

  aside {
    width: 262px;
    max-width: 262px;
    padding: $box-sizing-large;

    .user {
      font-size: $ae-font-size-sm;
      font-weight: $ae-font-weight-medium;
    }

    & > :not(:last-child) {
      margin-bottom: $box-sizing-xxl;
    }

    .ticket-metadata {
      font-size: 11px;
      color: var(--gray-600);

      a {
        text-decoration: none;
        color: var(--primary);
      }
    }

    .settings-icon {
      color: var(--gray-500);
      transition: 200ms;

      &:hover {
        color: var(--gray-700);
      }
    }

    .no-data {
      color: var(--gray-400);
    }
  }

  &.compact {
    main {
      > .thread {
        max-height: 780px;
        overflow-y: auto;
      }
    }
  }
  .banned {
    border: toRem(1px) solid var(--danger);
    border-radius: toRem(4px);
    color: $priority-high;
    padding: toRem(4px);
  }
}
