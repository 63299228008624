@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_typography";

.edge-resources {
  .toggle {
    button {
      border: none;
      padding: $box-sizing-regular $box-sizing-medium;
      border-radius: $border-radius-standard;

      span {
        font-weight: $ae-font-weight-medium;
        font-size: $ae-font-size-xs;
        line-height: 18px;
      }
    }

    &.disabled {
      cursor: default;
    }
  }
}
