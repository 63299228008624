.add-button {
  padding: 20px;
  cursor: pointer;
  background-color: hsl(0, 0, 98);
  color: hsl(0, 0, 40);

  &:hover {
    background-color: hsl(0, 0, 97);
    color: hsl(0, 0, 30);
  }

  &.disabled {
    cursor: default;
    background-color: white;
    color: hsl(0, 0, 60);
  }
}
