.container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 30rem 1fr 1fr 1fr 12rem;
  padding: 1rem;
  background: #fff;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1),
    0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  min-width: 1000px;
}

.containerV2 {
  box-shadow: none;
}

.headerItem {
  color: #828282;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: 8rem;
  flex-shrink: 0;
}

.actionsItem {
  display: flex;
  justify-content: end;
}
